import styled from "styled-components/macro";

export default styled.div`
  /* max-width: 240px; */

  display: inline-flex;
  flex-wrap: wrap;

  flex-direction: column;
  align-items: flex-start;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;
