import React from "react";
import { observer } from "mobx-react";
import { Rect, Slide, Text } from "../../slides-engine/smartElements.jsx";

import {
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  CONTENT_LEVEL4_FONT_SIZE,
  GUTTER_SIZE,
} from "../layoutConfig.js";
import {
  COLOR_DEFAULT_DARK1,
  COLOR_DEFAULT_ACCENT1,
} from "../models/colorScheme";
import ImageWrapper from "../components/ImageWrapper.jsx";

export default observer(
  ({
    masterName,
    slideId,
    title,

    dividerLocation = 0.55,

    containerX = INNER_X,
    containerY = INNER_Y,
    containerW = INNER_W,
    containerH = INNER_H,

    content,
    contentX = containerX,
    contentY = containerY,
    contentW = `calc(${
      100 * (1 - dividerLocation)
    }vw - ${contentX} - ${GUTTER_SIZE})`,
    contentH = containerH,
    contentColor = COLOR_DEFAULT_DARK1,
    contentFontSize = CONTENT_LEVEL4_FONT_SIZE,
    contentAlign = "left",
    contentValign = "middle",

    image,
    imageX = `calc(${100 * dividerLocation}vw)`,
    imageY = "0",
    imageW = `calc(100vw - ${imageX})`,
    imageH = "100vh",
    imageFit = "cover",
    imageAlign = "left",
    imageValign = "center",

    overlayColor = COLOR_DEFAULT_ACCENT1,
    overlayOpacity = 0.6,
  }) => {
    const imageEl = image ? (
      <ImageWrapper
        key="image"
        x={imageX}
        y={imageY}
        w={imageW}
        h={imageH}
        objectFit={imageFit}
        objectAlign={imageAlign}
        objectVAlign={imageValign}
        {...image}
      />
    ) : null;

    const overlayEl = (
      <Rect
        key="overlay"
        x={imageX}
        y={imageY}
        w={imageW}
        h={imageH}
        fill={overlayColor}
        opacity={overlayOpacity}
      />
    );

    const contentEl = content ? (
      <Text
        key="content"
        type="body"
        align={contentAlign}
        valign={contentValign}
        color={contentColor}
        fontSize={contentFontSize}
        x={contentX}
        y={contentY}
        h={contentH}
        w={contentW}
      >
        {content}
      </Text>
    ) : null;

    return (
      <Slide slideId={slideId} master={masterName}>
        <Text placeholder="title">{title}</Text>

        {contentEl}

        {imageEl}
        {overlayEl}
      </Slide>
    );
  }
);
