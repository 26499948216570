export const growthChartData = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  values: [0, 3, 4, 5, 8, 11, 12, 17, 20, 23, 39, 54]
};

export const priorities = [
  [
    {
      text: "Decoupling",
      description:
        "Split the monolith into independent modules, assign dedicated teams"
    },
    {
      text: "Portability",
      description:
        "Assess investment for porting the modules onto target platforms"
    }
  ],
  [
    {
      text: "Prioritisaion",
      description:
        "Order target platforms based on technical and business inputs"
    },
    {
      text: "Resource Allocation",
      description:
        "Sign-off the budget and work order for subcontractors, include intermediate deliveries"
    },
    {
      text: "Outreach",
      description:
        "Business development to start reaching early adopters for pilot project for the upcoming products"
    }
  ],
  [
    {
      text: "Deprecation Noice",
      description:
        "Inform the current clientbase about deprecation of the current product offering and maitenance details"
    },
    {
      text: "General Availability",
      description:
        "Public announcement and availablity for order of the new generation of the products"
    },
    {
      text: "Feedback",
      description:
        "Collect and catagolise feedback from the early adopters, to be included into the roadmap"
    }
  ]
];

export const agendaItems = [
  "Welcome and Introduction",
  "Portfolio",
  "Top Line",
  "Roadmap",
  "Product Lineup",
  "Services",
  "Meet The Team",
  "Wrap Up"
];
