import React from "react";

import { Button } from "./components/Button.jsx";

import { useApp, usePresentation } from "./state/context";

import { FormTitle } from "./components/titles.jsx";
import appIcon from "./images/bulletpoints-studio-logo.svg";
import { observer } from "mobx-react";
import styled from "styled-components/macro";

import { mailToSupportUrl } from "./backendServices";

import Modal from "./components/Modal.jsx";
import ConfigSidebar from "./components/ConfigSidebar.jsx";
import SlidesPreview from "./components/SlidesPreview.jsx";
import DownloadOrBuy from "./components/DownloadOrBuy.jsx";
import { ResetDialog } from "./components/ResetButton.jsx";
import AccessPurchase, {
  AccessPurchaseSidebar,
} from "./components/AccessPurchase.jsx";

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #eee;
  padding: 0 30px;
`;

const AppIcon = styled.img`
  height: 48px;
  margin-left: -8px;
`;

const ContactSupport = styled.a`
  margin-left: auto;
  margin-right: 20px;
  color: #444;
  text-decoration: none;

  :hover {
    color: #325d81;
  }
`;

const ToolbarButton = styled(Button)`
  align-self: stretch;
  border-radius: 0;
`;

const MainApp = styled.div`
  display: grid;
  padding: 30px;

  grid-template-columns: minmax(240px, 17%) auto;
  grid-gap: 30px;

  @media screen and (max-width: 719px) {
    grid-template-columns: auto;
    justify-items: center;
  }
`;

const App = observer(() => {
  const appState = useApp();
  const presentationState = usePresentation();

  return (
    <>
      <Toolbar>
        <AppIcon src={appIcon} />
        <ContactSupport
          href={mailToSupportUrl()}
          rel="noopener noreferrer"
          target="_blank"
        >
          Contact Support
        </ContactSupport>
        <ToolbarButton
          solid
          color="primary"
          width="160px"
          onClick={() => {
            appState.isPurchased
              ? appState.download()
              : appState.askForCheckout();
          }}
        >
          DOWNLOAD
        </ToolbarButton>
      </Toolbar>
      <MainApp>
        {appState.isPurchased ? <AccessPurchaseSidebar /> : <ConfigSidebar />}
        <SlidesPreview state={presentationState} />

        <Modal
          isOpen={appState.modalIsOpen}
          onRequestClose={appState.modalInfo ? null : appState.closeModal}
          contentLabel="Checkout"
        >
          {appState.showPurchaseInfo && <AccessPurchase />}
          {appState.showCheckoutInfo && <DownloadOrBuy />}
          {appState.showResetDialog && <ResetDialog />}
          {appState.modalInfo && <FormTitle>{appState.modalInfo}</FormTitle>}
        </Modal>
      </MainApp>
    </>
  );
});

export default App;
