import React, { useContext } from "react";
import { buildSVGText } from "../svg-helpers";
import { useSlidePlaceholder } from "./Slide";
import PPTXText from "../elements/Text";

const cleanAutoTxt = (txt) => {
  if (typeof txt === "string") return txt;
  if (txt.length) return txt[0].text;
};

export const extractTxtProps = (children, props) => {
  const elem = new PPTXText(props);
  React.Children.forEach(children, (txt) => {
    if (typeof txt === "string") elem.appendChild(txt);
  });

  return elem.render();
};

export const TextOverridesContext = React.createContext({});
export const TextOverrides = React.memo(({ children, ...props }) => {
  return (
    <TextOverridesContext.Provider value={props}>
      {children}
    </TextOverridesContext.Provider>
  );
});

export default React.memo(({ children, ...props }) => {
  const overrides = useContext(TextOverridesContext);

  if (props.autoFill === "slidenum") {
    return buildSVGText("#", props);
  }

  const { text: sourceText, options: sourceOptions } = extractTxtProps(
    children,
    props
  );

  const placeholder = useSlidePlaceholder(sourceOptions.placeholder);

  const {
    text: placeholderText = "",
    options: placeholderOptions = {},
  } = placeholder;

  let text = cleanAutoTxt(sourceText) || cleanAutoTxt(placeholderText);

  const options = {
    ...placeholderOptions,
    ...sourceOptions,
    ...overrides,
  };

  return buildSVGText(text, options);
});
