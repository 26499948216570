import React from "react";

import {
  Master,
  Text,
  Slide,
  Placeholder,
} from "../../slides-engine/smartElements.jsx";

import HeaderPlaceholder, {
  TITLE_PLACEHOLDER_NAME,
} from "../components/HeaderPlaceholder";
import FooterPlaceholder from "../components/FooterPlaceholder";

import {
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  CONTENT_SLIDE_BODY_FONT_SIZE,
  LIST_INDENT,
  BULLET_TYPE_DEFAULT,
  BULLET_CODE_DEFUALT,
} from "../layoutConfig.js";

export const MASTER_NAME = "Title and Content";
export const MASTER_PLACEHOLDERS = {
  title: TITLE_PLACEHOLDER_NAME,
  content: "content",
};

export default ({
  name = MASTER_NAME,
  backgroundColor = "#FFFFFF",

  contentX = INNER_X,
  contentY = INNER_Y,
  contentW = INNER_W,
  contentH = INNER_H,

  titleColor = "#000000",

  contentColor = "#000000",
  contentFontSize = CONTENT_SLIDE_BODY_FONT_SIZE,

  accent1Color = titleColor,

  bulletType = BULLET_TYPE_DEFAULT,
  bulletCode = BULLET_CODE_DEFUALT,
  bulletIndent = LIST_INDENT,
  bulletColor = titleColor,
}) => {
  return (
    <Master title={name} bkgd={backgroundColor}>
      <HeaderPlaceholder titleColor={titleColor} />

      <Placeholder
        name={MASTER_PLACEHOLDERS.content}
        type="body"
        align="left"
        valign="top"
        color={contentColor}
        fontSize={contentFontSize}
        x={contentX}
        y={contentY}
        h={contentH}
        w={contentW}
        bullet={{
          type: bulletType,
          code: bulletCode,
          indent: bulletIndent,
          color: bulletColor,
        }}
      >
        Click to add text
      </Placeholder>

      <FooterPlaceholder accent1Color={accent1Color} />
    </Master>
  );
};

export const Example = ({ slideId, title, content }) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    <Text placeholder={MASTER_PLACEHOLDERS.title}>{title}</Text>

    {/* We need to specify the bullet in the slide example as well */}
    <Text placeholder={MASTER_PLACEHOLDERS.content}>{content}</Text>
  </Slide>
);
