import React from "react";
import { asHex, position, asLineCap, ptToPx } from "../svg-helpers";
import { TextOverrides } from "./Text.js";

const Oval = React.memo(({ children, ...props }) => {
  const { x, y, dx, dy } = position(props);
  const strokeLinecap = asLineCap(props.borderCap);
  // NOTE: the default value is picked empirically
  const strokeDasharray = strokeLinecap !== "none" ? "1 4" : "none";
  const strokeOpacity = props.borderTransparency
    ? 1 - props.borderTransparency / 100
    : 1;

  // We place the inner text withing the ellpsis
  // https://math.stackexchange.com/a/1025714 if you wand to understand
  // the formula
  const wCorr = `calc(${dx} / ${Math.sqrt(2)})`;
  const hCorr = `calc(${dy} / ${Math.sqrt(2)})`;

  return (
    <TextOverrides
      x={`calc(${x} + (${dx} - ${wCorr}) / 2)`}
      y={`calc(${y} + (${dy} - ${hCorr}) / 2)`}
      w={wCorr}
      h={hCorr}
    >
      <ellipse
        fill={asHex(props.fill)}
        stroke={asHex(props.borderColor)}
        strokeOpacity={strokeOpacity}
        strokeWidth={ptToPx(props.borderWidth || 0)}
        strokeDasharray={strokeDasharray}
        strokeLinecap={strokeLinecap}
        cx={x + dx / 2}
        cy={y + dy / 2}
        rx={dx / 2}
        ry={dy / 2}
      />
      {children}
    </TextOverrides>
  );
});

const Chevron = React.memo(({ children, ...props }) => {
  const { x, y, width, height } = position(props);
  const radius = 1 - parseFloat(props.radius || "0");
  const x1 = x;
  const y1 = y;

  const x2 = x1 + width * radius;
  const y2 = y1;

  const x3 = x1 + width;
  const y3 = y1 + height / 2;

  const x4 = x2;
  const y4 = y1 + height;

  const x5 = x1;
  const y5 = y1 + height;

  const x6 = x1 + width * (1 - radius);
  const y6 = y3;

  const pathCommands = [
    `M${x1},${y1}`,
    `L${x2},${y2}`,
    `L${x3},${y3}`,
    `L${x4},${y4}`,
    `L${x5},${y5}`,
    `L${x6},${y6}`,
    `Z`,
  ];

  return (
    <>
      <path fill={asHex(props.fill)} d={pathCommands.join("")} />
      <TextOverrides x={props.x} y={props.y} w={props.w} h={props.h}>
        {children}
      </TextOverrides>
    </>
  );
});

const Rectangle = React.memo(({ children, ...props }) => {
  const { opacity, radius } = props;
  const rx = radius ? 100 * parseFloat(radius, 10) : 0;
  return (
    <>
      <rect
        fill={asHex(props.fill)}
        opacity={opacity}
        {...position(props)}
        rx={rx}
      />
      <TextOverrides x={props.x} y={props.y} w={props.w} h={props.h}>
        {children}
      </TextOverrides>
    </>
  );
});

const Triangle = React.memo(({ children, ...props }) => {
  const { x, y, width, height } = position(props);
  const { opacity, fill } = props;
  const points = [
    `${x},${y + height}`,
    `${x + width},${y + height}`,
    `${x + width / 2},${y}`,
  ];
  return (
    <>
      <polygon points={points.join(" ")} fill={asHex(fill)} opacity={opacity} />
      <TextOverrides x={props.x} y={props.y} w={props.w} h={props.h}>
        {children}
      </TextOverrides>
    </>
  );
});

const UpArrow = React.memo(({ children, ...props }) => {
  const { x, y, width, height } = position(props);
  const { opacity, fill } = props;
  // FIXME: actually parse the values from avLst
  const adj1 = 0.25 * height;
  const adj2 = width / 4;
  const points = [
    `${x + width / 2},${y}`,
    `${x + width},${y + adj1}`,
    `${x + width - adj2},${y + adj1}`,
    `${x + width - adj2},${y + height}`,
    `${x + adj2},${y + height}`,
    `${x + adj2},${y + adj1}`,
    `${x},${y + adj1}`,
  ];
  return (
    <>
      <polygon points={points.join(" ")} fill={asHex(fill)} opacity={opacity} />
      <TextOverrides x={props.x} y={props.y} w={props.w} h={props.h}>
        {children}
      </TextOverrides>
    </>
  );
});

const RightTriangle = React.memo(({ children, ...props }) => {
  const { x, y, width, height } = position(props);
  const { opacity, fill, flipH = false, flipV = false } = props;

  let p1, p2, p3;
  if (!flipH && !flipV) {
    p1 = `${x},${y}`;
    p2 = `${x},${y + height}`;
    p3 = `${x + width},${y + height}`;
  } else if (flipH && !flipV) {
    p1 = `${x + width},${y}`;
    p2 = `${x},${y + height}`;
    p3 = `${x + width},${y + height}`;
  } else if (!flipH && flipV) {
    p1 = `${x},${y}`;
    p2 = `${x + width},${y}`;
    p3 = `${x},${y + height}`;
  } else if (flipH && flipV) {
    p1 = `${x},${y}`;
    p2 = `${x + width},${y}`;
    p3 = `${x + width},${y + height}`;
  }

  const points = [p1, p2, p3];
  return (
    <>
      <polygon points={points.join(" ")} fill={asHex(fill)} opacity={opacity} />
      <TextOverrides x={props.x} y={props.y} w={props.w} h={props.h}>
        {children}
      </TextOverrides>
    </>
  );
});

export default React.memo(({ shape: inputShape, ...props }) => {
  const shape = inputShape.toLowerCase();

  if (shape === "oval") {
    return <Oval {...props} />;
  } else if (shape === "chevron") {
    return <Chevron {...props} />;
  } else if (shape === "rectangle" || shape === "rounded_rectangle") {
    return <Rectangle {...props} />;
  } else if (shape === "isosceles_triangle") {
    return <Triangle {...props} />;
  } else if (shape === "up_arrow") {
    return <UpArrow {...props} />;
  } else if (shape === "right_triangle") {
    return <RightTriangle {...props} />;
  }
});
