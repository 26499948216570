export default class LineChart {
  constructor(props = {}) {
    this.tag = "line-chart";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    console.warn(`children in charts ignored`);
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    const {
      data,
      x,
      y,
      w,
      h,

      chartColors,
      chartColorsOpacity,
      border,
      fill,
      invertedColors,

      showLegend,
      legendPos,
      legendFontFace,
      legendColor,
      legendFontSize,

      showLabel,

      showPercent,

      shadow,

      showTitle,
      title,
      titleAlign,
      titleColor,
      titleFontFace,
      titleFontSize,
      titlePos,
      titleRotate,

      valAxisMaxVal,

      dataBorder,

      dataLabelColor,
      dataLabelFormatCode,
      dataLabelFontBold,
      dataLabelFontFace,
      dataLabelFontSize,
      dataLabelPosition,

      axisLineColor,
      catAxisBaseTimeUnit,
      catAxisHidden,
      catAxisLabelColor,
      catAxisLabelFontBold,
      catAxisLabelFontFace,
      catAxisLabelFontSize,
      catAxisLabelFrequency,
      catAxisLabelPos,
      catAxisLabelRotate,
      catAxisLineShow,
      catAxisMajorTimeUnit,
      catAxisMaxVal,
      catAxisMinVal,
      catAxisMinorTimeUnit,
      catAxisMajorUnit,
      catAxisMinorUnit,
      catAxisOrientation,
      catAxisTitle,
      catAxisTitleColor,
      catAxisTitleFontFace,
      catAxisTitleFontSize,
      catAxisTitleRotate,
      catGridLine,
      showCatAxisTitle,
      showValAxisTitle,
      valAxisHidden,
      valAxisLabelColor,
      valAxisLabelFontBold,
      valAxisLabelFontFace,
      valAxisLabelFontSize,
      valAxisLabelFormatCode,
      valAxisLineShow,
      valAxisMajorUnit,
      valAxisMinVal,
      valAxisOrientation,
      valAxisTitle,
      valAxisTitleColor,
      valAxisTitleFontFace,
      valAxisTitleFontSize,
      valAxisTitleRotate,
      valGridLin,

      displayBlanksAs,

      lineSize,
      lineSmooth,
      lineDataSymbol,
      lineDataSymbolSize,
      lineDataSymbolLineSize,
      lineDataSymbolLineColor,
    } = this.props;

    return [
      data,
      {
        x,
        y,
        w,
        h,

        chartColors,
        chartColorsOpacity,
        border,
        fill,
        invertedColors,

        showLegend,
        legendPos,
        legendFontFace,
        legendColor,
        legendFontSize,

        showLabel,

        showPercent,

        shadow,

        showTitle,
        title,
        titleAlign,
        titleColor,
        titleFontFace,
        titleFontSize,
        titlePos,
        titleRotate,

        valAxisMaxVal,

        dataBorder,

        dataLabelColor,
        dataLabelFormatCode,
        dataLabelFontBold,
        dataLabelFontFace,
        dataLabelFontSize,
        dataLabelPosition,

        axisLineColor,
        catAxisBaseTimeUnit,
        catAxisHidden,
        catAxisLabelColor,
        catAxisLabelFontBold,
        catAxisLabelFontFace,
        catAxisLabelFontSize,
        catAxisLabelFrequency,
        catAxisLabelPos,
        catAxisLabelRotate,
        catAxisLineShow,
        catAxisMajorTimeUnit,
        catAxisMaxVal,
        catAxisMinVal,
        catAxisMinorTimeUnit,
        catAxisMajorUnit,
        catAxisMinorUnit,
        catAxisOrientation,
        catAxisTitle,
        catAxisTitleColor,
        catAxisTitleFontFace,
        catAxisTitleFontSize,
        catAxisTitleRotate,
        catGridLine,
        showCatAxisTitle,
        showValAxisTitle,
        valAxisHidden,
        valAxisLabelColor,
        valAxisLabelFontBold,
        valAxisLabelFontFace,
        valAxisLabelFontSize,
        valAxisLabelFormatCode,
        valAxisLineShow,
        valAxisMajorUnit,
        valAxisMinVal,
        valAxisOrientation,
        valAxisTitle,
        valAxisTitleColor,
        valAxisTitleFontFace,
        valAxisTitleFontSize,
        valAxisTitleRotate,
        valGridLin,

        displayBlanksAs,

        lineSize,
        lineSmooth,
        lineDataSymbol,
        lineDataSymbolSize,
        lineDataSymbolLineSize,
        lineDataSymbolLineColor,
      },
    ];
  }
}
