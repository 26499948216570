import React from "react";

import { Master, Text, Slide } from "../../slides-engine/smartElements.jsx";

import HeaderPlaceholder, {
  TITLE_PLACEHOLDER_NAME,
} from "../components/HeaderPlaceholder";
import FooterPlaceholder from "../components/FooterPlaceholder";

export const MASTER_NAME = "Title Only";
export const MASTER_PLACEHOLDERS = {
  title: TITLE_PLACEHOLDER_NAME,
};

export default ({
  name = MASTER_NAME,
  backgroundColor = "#FFFFFF",
  titleColor = "#000000",
  accent1Color = titleColor,
}) => {
  return (
    <Master title={name} bkgd={backgroundColor}>
      <HeaderPlaceholder titleColor={titleColor} />
      <FooterPlaceholder accent1Color={accent1Color} />
    </Master>
  );
};

export const Example = ({ title, slideId }) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    <Text placeholder={MASTER_PLACEHOLDERS.title}>{title}</Text>
  </Slide>
);
