import React from "react";
import styled from "styled-components/macro";
import { observer } from "mobx-react";

import { useApp, usePresentation } from "../state/context";

import ColorScheme from "./ColorScheme.jsx";
import LogoDropzone from "./LogoDropzone.jsx";
import FontSelect from "./FontSelect.jsx";
import Sidebar from "./Sidebar.jsx";
import ResetButton from "./ResetButton.jsx";
import NoAccessOverlay, { OverlayMessage } from "./NoAccessOverlay.jsx";

const ConfigControls = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 100%;
  margin-bottom: 3%;
  overflow: auto;
  width: 100%;
  ${(props) => (props.isPurchased ? "filter: grayscale(1);" : null)}

  & > :not(:first-child) {
    margin-top: 5%;
  }
`;

export default observer(() => {
  const appState = useApp();
  const presentationState = usePresentation();

  return (
    <Sidebar>
      <ConfigControls isPurchased={appState.isPurchased}>
        <LogoDropzone
          onPalette={(palette) => {
            presentationState.palette.import(palette);
            presentationState.deck.colorScheme.fromPalette(palette);
          }}
          onImgSrc={presentationState.deck.logo.fromData}
          imgSrc={presentationState.deck.logo.data}
        />

        <ColorScheme
          colorScheme={presentationState.deck.colorScheme}
          palette={presentationState.palette}
        />

        <div css="width: 100%; display: flex; flex-direction: column;">
          <FontSelect
            title="Title Font"
            fontFamily={presentationState.deck.titleFontFamily}
            customFonts={presentationState.deck.customFonts}
            onChange={presentationState.deck.setTitleFontFamily}
            onNewFont={presentationState.deck.addNewFont}
          />
          <FontSelect
            title="Body Font"
            fontFamily={presentationState.deck.fontFamily}
            customFonts={presentationState.deck.customFonts}
            onChange={presentationState.deck.setFontFamily}
            onNewFont={presentationState.deck.addNewFont}
          />
        </div>

        {appState.isPurchased ? (
          <NoAccessOverlay>
            <OverlayMessage>
              <p>You can only download the current presentation</p>
              <br />
              <p>Click RESET to start creating a new presentation</p>
            </OverlayMessage>
          </NoAccessOverlay>
        ) : null}
      </ConfigControls>
      <ResetButton />
    </Sidebar>
  );
});
