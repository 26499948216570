import React from "react";
import ImageWrapper from "../components/ImageWrapper.jsx";
import { observer } from "mobx-react";

import { Text, Slide, Rect } from "../../slides-engine/smartElements.jsx";

import {
  TITLE_SLIDE_TITLE_FONT_SIZE,
  CONTENT_LEVEL1_FONT_SIZE,
  CONTENT_LEVEL3_FONT_SIZE,
} from "../layoutConfig.js";

export default observer(
  ({
    slideId,
    masterName = "Blank",

    backgroundColorLeft = "000000",
    backgroundColorRight = "000000",
    textColor = "FFFFFF",

    titleFontSize = TITLE_SLIDE_TITLE_FONT_SIZE,
    subtitleFontSize = CONTENT_LEVEL1_FONT_SIZE,
    descriptionFontSize = CONTENT_LEVEL3_FONT_SIZE,

    dividerVertical = "50%",
    titleSubtitleOffset = "0vh",
    subtitleDescriptionOffset = "1vh",

    imageLeft,
    titleLeft = "LEFT",
    subtitleLeft = "SUBTITLE",
    descriptionLeft = "DESCRIPTION",

    imageRight,
    titleRight = "RIGHT",
    subtitleRight = "SUBTITLE",
    descriptionRight = "DESCRIPTION",
  }) => {
    const textOffsetLeft = "5%",
      textOffsetRight = "5%";

    const textLeftX = textOffsetLeft;
    const textLeftW = `calc(${dividerVertical} - ${textOffsetLeft} - ${textOffsetRight})`;

    const textRightX = `calc(${dividerVertical} + ${textOffsetLeft})`;
    const textRightW = `calc(100% - ${dividerVertical} - ${textOffsetLeft} - ${textOffsetRight})`;

    const titleH = "25%";
    const titleY = `calc(50% - ${titleH}/2)`; // center vertically
    const imageTitleOffset = "5%";
    const imageH = "15%";
    const imageY = `calc(${titleY} - ${imageTitleOffset} - ${imageH})`;

    const subtitleY = `calc(${titleY} + ${titleH} + ${titleSubtitleOffset})`;
    const subtitleH = "6%";
    const descriptionY = `calc(${subtitleY} + ${subtitleH} + ${subtitleDescriptionOffset})`;
    const descriptionH = "20%";

    return (
      <Slide slideId={slideId} master={masterName}>
        <Rect
          fill={backgroundColorLeft}
          x="0%"
          y="0%"
          w={dividerVertical}
          h="100%"
        />
        <Rect
          fill={backgroundColorRight}
          x={dividerVertical}
          y="0%"
          w={`calc(100% - ${dividerVertical})`}
          h="100%"
        />

        <ImageWrapper
          {...(imageLeft || {})}
          colorBlend={{ darkColor: "#ffffff", lightColor: "#ffffff" }}
          x={textLeftX}
          y={imageY}
          w={textLeftW}
          h={imageH}
        />
        <ImageWrapper
          {...(imageRight || {})}
          colorBlend={{ darkColor: "#ffffff", lightColor: "#ffffff" }}
          x={textRightX}
          y={imageY}
          w={textRightW}
          h={imageH}
        />

        <Text
          type="title"
          color={textColor}
          align="center"
          valign="center"
          x={textLeftX}
          y={titleY}
          w={textLeftW}
          h={titleH}
          fontSize={titleFontSize}
        >
          {titleLeft}
        </Text>
        <Text
          type="title"
          color={textColor}
          align="center"
          valign="center"
          x={textRightX}
          y={titleY}
          w={textRightW}
          h={titleH}
          fontSize={titleFontSize}
        >
          {titleRight}
        </Text>

        <Text
          color={textColor}
          align="center"
          valign="center"
          x={textLeftX}
          y={subtitleY}
          w={textLeftW}
          h={subtitleH}
          fontSize={subtitleFontSize}
        >
          {subtitleLeft}
        </Text>
        <Text
          color={textColor}
          align="center"
          valign="center"
          x={textRightX}
          y={subtitleY}
          w={textRightW}
          h={subtitleH}
          fontSize={subtitleFontSize}
        >
          {subtitleRight}
        </Text>

        <Text
          color={textColor}
          align="center"
          valign="top"
          x={textLeftX}
          y={descriptionY}
          w={textLeftW}
          h={descriptionH}
          fontSize={descriptionFontSize}
        >
          {descriptionLeft}
        </Text>
        <Text
          color={textColor}
          align="center"
          valign="top"
          x={textRightX}
          y={descriptionY}
          w={textRightW}
          h={descriptionH}
          fontSize={descriptionFontSize}
        >
          {descriptionRight}{" "}
        </Text>
      </Slide>
    );
  }
);
