import React from "react";

import { Master, Slide } from "../../slides-engine/smartElements.jsx";

import FooterPlaceholder from "../components/FooterPlaceholder";

export const MASTER_NAME = "Blank";

export default ({ name = MASTER_NAME, backgroundColor = "FFFFFF" }) => (
  <Master title={name} bkgd={backgroundColor}>
    <FooterPlaceholder />
  </Master>
);

export const Example = ({ slideId }) => (
  <Slide slideId={slideId} master={MASTER_NAME} />
);
