import { onSnapshot } from "mobx-state-tree";

import { STORAGE_KEY } from "../constants";
import { presentationInitialState } from "./presentation";
import { AppState } from "./app";
import { stripeCheckoutInfo } from "../backendServices";

export const initState = ({ persistInLocalStorage = true } = {}) => {
  const { purchased, setFromUrl } = stripeCheckoutInfo(window);

  const stateSnapshot = {
    isPurchased: purchased,
    showPurchaseInfo: purchased && setFromUrl,
  };

  try {
    const rawDeck = localStorage.getItem(STORAGE_KEY);
    const deck =
      persistInLocalStorage && rawDeck
        ? JSON.parse(rawDeck)
        : presentationInitialState.deck;
    stateSnapshot.presentation = { ...presentationInitialState, deck };
  } catch (e) {
    stateSnapshot.presentation = {
      ...presentationInitialState,
      deck: { ...presentationInitialState.deck },
    };
  }

  const state = AppState.create(stateSnapshot);

  if (persistInLocalStorage) {
    onSnapshot(state.presentation.deck, (snapshot) => {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(snapshot));
    });
  }

  return state;
};
