import React from "react";

import {
  Master,
  Text,
  Slide,
  Placeholder,
  Rect,
} from "../../slides-engine/smartElements.jsx";

import HeaderPlaceholder, {
  TITLE_PLACEHOLDER_NAME,
} from "../components/HeaderPlaceholder";
import FooterPlaceholder from "../components/FooterPlaceholder";

import {
  OFFSET_LEFT,
  OFFSET_RIGHT,
  INNER_Y,
  INNER_H,
  CONTENT_LEVEL1_FONT_SIZE,
} from "../layoutConfig.js";

export const MASTER_NAME = "Agenda";
export const MASTER_PLACEHOLDERS = {
  title: TITLE_PLACEHOLDER_NAME,
  content: "content",
};

export default ({
  name = MASTER_NAME,
  backgroundColor = "#FFFFFF",
  titleColor = "#4472C4",
  accent1Color = titleColor,

  leftBarX = "0",
  leftBarY = "0",
  leftBarW = `calc(${OFFSET_LEFT} - 0.15vw)`,
  leftBarH = "100vh",
  leftBarFill = titleColor,

  contentX = "0.35vw",
  contentY = INNER_Y,
  contentW = `calc(100vw - ${OFFSET_RIGHT} - ${contentX})`,
  contentH = INNER_H,
  contentColor = titleColor,
  contentFontSize = CONTENT_LEVEL1_FONT_SIZE,
  contentAlign = "left",
  contentValign = "top",
  contentBulletType = "number",
  contentBulletStyle = "arabicPlain",
  contentBulletIndent = "3.5vw",
  contentBulletColor = backgroundColor,
}) => {
  const leftBarEl = (
    <Rect
      key="left-bar"
      fill={leftBarFill}
      x={leftBarX}
      y={leftBarY}
      w={leftBarW}
      h={leftBarH}
    />
  );

  const bulletConf = {
    type: contentBulletType,
    style: contentBulletStyle,
    indent: contentBulletIndent,
    color: contentBulletColor,
  };
  const contentPlaceholderEl = (
    <Placeholder
      key="content-placeholder"
      name={MASTER_PLACEHOLDERS.content}
      type="body"
      align={contentAlign}
      valign={contentValign}
      fontSize={contentFontSize}
      color={contentColor}
      bullet={bulletConf}
      x={contentX}
      y={contentY}
      w={contentW}
      h={contentH}
    >
      Click to add text
    </Placeholder>
  );

  return (
    <Master title={name} bkgd={backgroundColor}>
      <HeaderPlaceholder titleColor={titleColor} />

      {leftBarEl}
      {contentPlaceholderEl}

      <FooterPlaceholder accent1Color={accent1Color} />
    </Master>
  );
};

export const Example = ({
  slideId,
  backgroundColor = "#FFFFFF",
  accentColor = "#000000",

  title = "AGENDA",
  items = [],
}) => (
  <Slide
    slideId={slideId}
    master={MASTER_NAME}
    backgroundColor={backgroundColor}
    accentColor={accentColor}
  >
    <Text placeholder={MASTER_PLACEHOLDERS.title}>{title}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.content}>{items.join("\n")}</Text>
  </Slide>
);
