import { types, flow } from "mobx-state-tree";
import { readAsImage, imageWrapWithSize } from "../../utils";

const Image = types
  .model("Image", {
    width: types.maybe(types.number),
    height: types.maybe(types.number),
    loaded: types.optional(types.boolean, false),
    data: types.maybe(types.string),
  })
  .views((self) => {
    return {
      defaults(val) {
        if (self.loaded) return self;
        return val;
      },
    };
  })
  .actions((self) => {
    const setValues = ({ height, width, data }) => {
      self.loaded = true;
      self.height = height;
      self.width = width;
      self.data = data;
    };

    const clear = () => {
      self.loaded = false;
      self.data = undefined;
      self.height = undefined;
      self.width = undefined;
    };

    const fromData = flow(function* (data) {
      const config = yield imageWrapWithSize(data);
      setValues(config);
    });

    const load = flow(function* (path) {
      const config = yield fetch(path)
        .then((response) => response.blob())
        .then(readAsImage)
        .then(imageWrapWithSize);

      setValues(config);
    });

    const loadFile = flow(function* (file) {
      const config = yield readAsImage(file).then(imageWrapWithSize);
      setValues(config);
    });

    return { fromData, load, loadFile, clear };
  });

export default Image;
export const OptionalImage = types.optional(Image, {});
