import React from "react";

import {
  Group,
  Line,
  Text,
  Shape,
} from "../../slides-engine/smartElements.jsx";

import {
  DEFAULT_VERTICAL_SPACE,
  GUTTER_SIZE,
  CONTENT_LEVEL6_FONT_SIZE,
  CONTENT_LEVEL8_FONT_SIZE,
} from "../layoutConfig.js";

export default ({
  x = 0,
  y = 0,
  w = "100vw",
  h = "100vh",

  columnHeaderW = null,
  columnHeaders = [],
  columnHeaderFontSize = CONTENT_LEVEL6_FONT_SIZE,
  columnHeaderFontBold = false,
  columnHeaderColor = "#000000",
  columnHeaderAlign = "center",
  columnHeaderValign = "bottom",

  columnSeparatorWidth = 1,
  columnSeparatorColor = "#000000",
  columnSeparatorIncludeFirst = true,

  rowHeaderH = "7vh",
  rowHeaders = [],
  rowHeaderColor = "#000000",
  rowHeaderFontSize = CONTENT_LEVEL6_FONT_SIZE,
  rowHeaderFontBold = false,
  rowHeaderAlign = "left",
  rowHeaderValign = "center",

  rowSeparatorWidth = 1,
  rowSeparatorColor = "#000000",
  rowSeparatorIncludeFirst = true,

  rowColors = [],

  cellTextColor = "#000000",
  cellFontSize = CONTENT_LEVEL8_FONT_SIZE,
  cellFontBold = false,
  cellTextAutoFit = true,
  cellRectRadius = 0.05,
  cellAlign = "center",
  cellValign = "center",

  cellPaddingTop = `calc(0.5 * ${DEFAULT_VERTICAL_SPACE})`,
  cellPaddingLeft = `calc(0.5 * ${GUTTER_SIZE})`,
  cellPaddingBottom = cellPaddingTop,
  cellPaddingRight = cellPaddingLeft,

  upArrowW = GUTTER_SIZE,
  upArrowPaddingTop = `calc(0.5 * ${cellPaddingTop})`,
  upArrowPaddingBottom = upArrowPaddingTop,
  upArrowColor = null,

  content = [],
  ...props
}) => {
  const colCount = columnHeaders.length || 1;
  const rowCount = rowHeaders.length || 1;

  const col1W = columnHeaderW ? columnHeaderW : `calc(${w} / 5)`;

  // COLUMNS
  const colW = `calc((${w} - ${col1W}) / ${colCount})`;
  const colXs = columnHeaders.map(
    (_, idx) => `calc(${x} + ${col1W} + (${idx} * ${colW}))`
  );

  const colSeparatorEls =
    columnSeparatorWidth && columnSeparatorColor
      ? colXs
          .slice(columnSeparatorIncludeFirst ? 0 : 1)
          .map((colX, idx) => (
            <Line
              key={`column-separator-${idx}`}
              x={colX}
              y={y}
              w={0}
              h={h}
              color={columnSeparatorColor}
              width={columnSeparatorWidth}
            />
          ))
      : [];

  const colHeaderEls = columnHeaders.map((el, idx) => (
    <Text
      key={`column-header-${idx}`}
      x={`calc(${colXs[idx]} + ${cellPaddingLeft})`}
      y={y}
      w={`calc(${colW} - ${cellPaddingLeft} - ${cellPaddingRight})`}
      h={rowHeaderH}
      color={columnHeaderColor}
      fontSize={columnHeaderFontSize}
      bold={columnHeaderFontBold}
      align={columnHeaderAlign}
      valign={columnHeaderValign}
    >
      {el}
    </Text>
  ));

  // ROWS
  const rowH = `calc((${h} - ${rowHeaderH}) / ${rowCount})`;
  const rowYs = rowHeaders.map(
    (_, idx) => `calc(${y} + ${rowHeaderH} + (${idx} * ${rowH}))`
  );

  const rowSeparatorEls =
    rowSeparatorWidth && rowSeparatorColor
      ? rowYs
          .slice(rowSeparatorIncludeFirst ? 0 : 1)
          .map((rowY, idx) => (
            <Line
              key={`row-separator-${idx}`}
              x={x}
              y={rowY}
              w={w}
              h={0}
              color={rowSeparatorColor}
              width={columnSeparatorWidth}
            />
          ))
      : [];

  const rowHeaderEls = rowHeaders.map((rowHeader, idx) => (
    <Text
      key={`row-header-${idx}`}
      x={x}
      y={`calc(${rowYs[idx]} + ${cellPaddingTop})`}
      w={col1W}
      h={`calc(${rowH} - ${cellPaddingTop} - ${cellPaddingBottom})`}
      color={rowHeaderColor}
      fontSize={rowHeaderFontSize}
      bold={rowHeaderFontBold}
      align={rowHeaderAlign}
      valign={rowHeaderValign}
    >
      {rowHeader}
    </Text>
  ));

  // CELLS
  const cellEls = content
    .map((row, rowIdx) => {
      return row
        .map((cell, colIdx) => {
          const els = Array.isArray(cell) ? cell : [cell];
          const l = els.length;
          const cellH = `calc(${rowH} - ${cellPaddingTop} - ${cellPaddingBottom})`;
          const elSpacerH = `calc(0.5 * ${cellPaddingTop})`; // FIXME: -> params
          const elH = `calc((${cellH} - (${l - 1} * ${elSpacerH})) / ${l})`;
          return els.map((el1, elIdx) => {
            const el = typeof el1 === "string" ? { text: el1 } : el1;
            const {
              type = "text",
              colspan = 1,
              text,
              fontSize = cellFontSize,
              fontBold = cellFontBold,
              fill = rowColors[rowIdx] || "#FFFFFF",
              color = cellTextColor,
              radius = cellRectRadius,
              align = cellAlign,
              valign = cellValign,
              autoFit = cellTextAutoFit,
              overrideH = type === "rect" && l === 1,
            } = el;
            const x = `calc(${colXs[colIdx]} + ${cellPaddingLeft})`;
            const w = `calc((${colW} * ${colspan}) -  ${cellPaddingLeft} - ${cellPaddingRight})`;
            // TODO: improve layout with els.length > 2
            const h = overrideH ? `calc(0.5 * ${elH})` : elH;
            const y = overrideH
              ? `calc(${rowYs[rowIdx]} + 0.5 * (${rowH} - ${h}))`
              : `calc(${rowYs[rowIdx]} + ${cellPaddingTop} + (${elIdx} * (${elH} + ${elSpacerH})))`;

            const cellEl =
              type === "text" ? (
                <Text
                  key={`cell-${rowIdx}-${colIdx}-${elIdx}`}
                  x={x}
                  y={y}
                  w={w}
                  h={h}
                  color={color}
                  align={align}
                  valign={valign}
                  fontSize={fontSize}
                  bold={fontBold}
                  autoFit={autoFit}
                >
                  {text}
                </Text>
              ) : (
                <Shape
                  key={`cell-${rowIdx}-${colIdx}-${elIdx}`}
                  shape="rounded_rectangle"
                  fill={fill}
                  radius={radius}
                  x={x}
                  y={y}
                  w={w}
                  h={h}
                  align={align}
                  valign={valign}
                >
                  <Text
                    color={color}
                    align={align}
                    valign={valign}
                    fontSize={fontSize}
                    bold={fontBold}
                    autoFit={autoFit}
                  >
                    {text}
                  </Text>
                </Shape>
              );
            return cellEl;
          });
        })
        .flat();
    })
    .flat();

  // UP_ARROW
  const upArrowEls = upArrowColor
    ? content
        .slice(0, -1)
        .map((row, rowIdx) => {
          return row
            .map((cell, colIdx) => {
              const { arrowColor = upArrowColor } = cell;
              return (
                <Shape
                  key={`center-arrow-${rowIdx}-${colIdx}`}
                  shape="up_arrow"
                  x={`calc(${colXs[colIdx]} + (0.5 * ${colW}) - (0.5 * ${upArrowW}))`}
                  y={`calc(${rowYs[rowIdx]} + ${rowH} - ${cellPaddingBottom} + ${upArrowPaddingTop})`}
                  w={upArrowW}
                  h={`calc(${cellPaddingTop} + ${cellPaddingBottom} - ${upArrowPaddingTop} - ${upArrowPaddingBottom})`}
                  fill={arrowColor}
                />
              );
            })
            .flat();
        })
        .flat()
    : null;

  return (
    <Group>
      {colSeparatorEls}
      {rowSeparatorEls}

      {colHeaderEls}
      {rowHeaderEls}

      {cellEls}
      {upArrowEls}
    </Group>
  );
};
