import React from "react";
import {
  Text,
  Slide,
  Group,
  Rect,
} from "../../slides-engine/smartElements.jsx";

import {
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  GUTTER_SIZE,
  CONTENT_LEVEL3_FONT_SIZE,
  CONTENT_LEVEL6_FONT_SIZE,
  LIST_INDENT,
} from "../layoutConfig.js";

export default ({
  masterName,
  slideId,
  title,

  containerX = INNER_X,
  containerY = INNER_Y,
  containerW = INNER_W,
  containerH = INNER_H,

  headerH = "8vh", // inspired by from master/Comparison

  headerColor = "#000000",
  headerFontSize = CONTENT_LEVEL3_FONT_SIZE,
  headerFontBold = true,

  bodyTextColor = "#000000",
  bodyFontSize = CONTENT_LEVEL6_FONT_SIZE,
  bodyFontBold = false,

  contentColumnsOffsetW = GUTTER_SIZE,

  accent1Color = "#4472C4", // default color scheme
  listIndent = LIST_INDENT,

  content = [{}, {}, {}], // 0:left, 1:center, 2:right
}) => {
  const colsCount = content && content.length ? content.length : 1; // avoid div0
  const colsInters = colsCount > 0 ? colsCount - 1 : 0;
  const columnW = `calc((${containerW} - (${colsInters} * ${contentColumnsOffsetW})) / ${colsCount})`;
  const columnXs = content.map(
    (_, idx) =>
      `calc(${containerX} + (${idx} * (${columnW} + ${contentColumnsOffsetW})))`
  );
  const bulletConf = {
    type: "number",
    style: "arabicPeriod",
    indent: listIndent,
    color: accent1Color,
  };
  const bodyY = `calc(${containerY} + ${headerH})`;
  const bodyH = `calc(${containerH} - ${headerH})`;
  const colEls = content.map((col = {}, idx) => {
    const {
      header = "",
      text = "",
      background,
      align = "left",
      valign = "top",
      bullet = true,
      lineSpacing = 1,
    } = col;
    const headerEl = (
      <Text
        type="body"
        x={columnXs[idx]}
        y={containerY}
        w={columnW}
        h={headerH}
        align={align}
        valign={valign}
        color={headerColor}
        fontSize={headerFontSize}
        bold={headerFontBold}
      >
        {header}
      </Text>
    );
    const bodyEl = (
      <Text
        type="body"
        x={columnXs[idx]}
        y={bodyY}
        w={columnW}
        h={bodyH}
        align={align}
        valign={valign}
        color={bodyTextColor}
        fontSize={bodyFontSize}
        bold={bodyFontBold}
        bullet={bullet ? bulletConf : false}
        lineSpacing={lineSpacing}
      >
        {bullet ? text : text.split("\n").join("\n\n")}
      </Text>
    );
    const backgroundEl = background ? (
      <Rect
        x={columnXs[idx]}
        y={containerY}
        w={columnW}
        h={containerH}
        fill={background}
      />
    ) : null;
    return (
      <Group key={idx.toString()}>
        {backgroundEl}
        {headerEl}
        {bodyEl}
      </Group>
    );
  });

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>

      {colEls}
    </Slide>
  );
};
