import React from "react";
import NumberAnnotation from "../components/NumberAnnotation.jsx";
import { Text, Slide, Rect } from "../../slides-engine/smartElements.jsx";

import {
  CONTENT_LEVEL7_FONT_SIZE,
  CONTENT_LEVEL6_FONT_SIZE,
  CONTENT_LEVEL5_FONT_SIZE,
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
} from "../layoutConfig.js";

export default ({
  masterName,
  slideId,

  row2BackgroundColor = "#FFFFFF",

  itemNumberBackgroundColor = "#000000",
  itemNumberColor = "#FFFFFF",
  itemTextColor = "#000000",

  circleFontSize = CONTENT_LEVEL7_FONT_SIZE,
  descriptionFontSize = CONTENT_LEVEL6_FONT_SIZE,
  textFontSize = CONTENT_LEVEL5_FONT_SIZE,

  containerX = INNER_X,
  containerY = INNER_Y,
  containerW = INNER_W,
  containerH = INNER_H,

  items = [[], [], []],

  title,
}) => {
  const itemOffsetH = "2vh";
  const itemH = `calc(${containerH} / 3)`;

  const itemOffsetW = "1vw";
  const itemW = `calc(${containerW} / 3)`;

  const row1Y = `calc(${containerY})`;
  const row2Y = `calc(${row1Y} + ${itemH})`;
  const row3Y = `calc(${row2Y} + ${itemH})`;
  const col1X = `${containerX}`;
  const col2X = `calc(${col1X} + ${itemW})`;
  const col3X = `calc(${col2X} + ${itemW})`;

  const circleWH = `4vh`;

  const slots = [
    { r: 1, c: 1, x: col1X, y: row1Y },
    { r: 1, c: 2, x: col2X, y: row1Y },
    { r: 1, c: 3, x: col3X, y: row1Y },

    { r: 2, c: 1, x: col1X, y: row2Y },
    { r: 2, c: 2, x: col2X, y: row2Y },
    { r: 2, c: 3, x: col3X, y: row2Y },

    { r: 3, c: 1, x: col1X, y: row3Y },
    { r: 3, c: 2, x: col2X, y: row3Y },
    { r: 3, c: 3, x: col3X, y: row3Y },
  ];
  let slotCounter = 1;

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>

      <Rect x="0%" y={row2Y} w="100%" h={itemH} fill={row2BackgroundColor} />
      {slots.map((slot, idx) => {
        const x = `calc(${slot.x} + ${itemOffsetW})`;
        const y = `calc(${slot.y} + ${itemOffsetH})`;
        const w = `calc(${itemW} - (2 * ${itemOffsetW}))`;
        const h = `calc(${itemH} - (2 * ${itemOffsetH}))`;

        const r = slot.r - 1;
        const c = slot.c - 1;

        const text = items[r] && items[r][c] ? items[r][c].text : null;
        const description =
          items[r] && items[r][c] ? items[r][c].description : null;

        if (text) {
          // NB: MUST BE a Strings, otherwise not rednered
          const number = String(slotCounter);
          slotCounter = slotCounter + 1;

          return (
            <NumberAnnotation
              key={number}
              x={x}
              y={y}
              w={w}
              h={h}
              circleWH={circleWH}
              circleColor={itemNumberBackgroundColor}
              circleTextColor={itemNumberColor}
              circleFontSize={circleFontSize}
              textColor={itemTextColor}
              textFontSize={textFontSize}
              descriptionFontSize={descriptionFontSize}
              number={number}
              text={text}
              description={description}
            />
          );
        } else {
          return null;
        }
      })}
    </Slide>
  );
};
