import React from "react";
import ReactDOM from "react-dom";
import * as Mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import SnackbarProvider from "react-simple-snackbar";

import { MIXPANEL_TOKEN, SENTRY_DSN } from "./constants";

import App from "./App";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";

import { initState } from "./state/init";
import { StateContext } from "./state/context";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: "bulletpointsstudio@" + process.env.npm_package_version,
});

Mixpanel.init(MIXPANEL_TOKEN);

const state = initState();

ReactDOM.render(
  <>
    <GlobalStyles />

    <SnackbarProvider>
      <StateContext.Provider value={state}>
        <App />
      </StateContext.Provider>
    </SnackbarProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
