import React from "react";
import styled from "styled-components/macro";
import { FormTitle } from "./titles.jsx";

import { FontDetector } from "../utils";

const fontAvailable = new FontDetector();

const FontSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// inspired by
// https://presentitude.com/44-safe-powerpoint-fonts/
export const FONTS_DEFAULT = [
  "Arial",
  "Cambria",
  "Candara",
  "Calibri",
  "Calibri-Light",
  "Corbel",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
  "Helvetica",
  "Helvetica Neue",
  "Helvetica Neue-Thin",
  "Helvetica Neue-Light",
  "Helvetica Neue-Bold",
];

const fnt2lbl = (fnt) => String(fnt).split("-").join(" ");

const OPTION_VALUE_ADD_NEW = "add-new";
const OPTION_VALUE_SEPARATOR = "separator";

export default React.memo(
  ({
    title,
    fontFamily,
    customFonts = [],
    onChange: onChangeInput,
    onNewFont: onNewFontInput,
  }) => {
    if (!fontAvailable.detect(fontFamily)) {
      console.warn("font not available", fontFamily);
    }

    const onChange = (e) => {
      const value = e.target.value;
      if (value === OPTION_VALUE_ADD_NEW) {
        const newFont = prompt(
          'Please enter font name, for ex.: "Times New Roman" or "Helvetica Neue-Thin"'
        );
        if (newFont) {
          onNewFontInput(newFont);
          onChangeInput(newFont);
        }
      } else {
        onChangeInput(value);
      }
    };

    const fonts = [...FONTS_DEFAULT, ...customFonts];

    const selectEl = (
      <select value={fontFamily} onChange={onChange}>
        {fonts.map((font, idx) => (
          <option key={idx} value={font}>{`${idx + 1}. ${fnt2lbl(
            font
          )}`}</option>
        ))}

        <option
          key={OPTION_VALUE_SEPARATOR}
          value={OPTION_VALUE_SEPARATOR}
          disabled
        >
          ___
        </option>
        <option key={OPTION_VALUE_ADD_NEW} value={OPTION_VALUE_ADD_NEW}>
          Add your font...
        </option>
      </select>
    );
    return (
      <FontSelectWrapper>
        <FormTitle>{title}</FormTitle>
        {selectEl}
      </FontSelectWrapper>
    );
  }
);
