import React from "react";
import {
  Group,
  Text,
  Slide,
  Line,
  Shape,
  Rect,
} from "../../slides-engine/smartElements.jsx";

import {
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  GUTTER_SIZE,
  CONTENT_LEVEL3_FONT_SIZE,
  CONTENT_LEVEL4_FONT_SIZE,
} from "../layoutConfig.js";
import {
  COLOR_DEFAULT_DARK1,
  COLOR_DEFAULT_LIGHT1,
  COLOR_DEFAULT_LIGHT2,
  COLOR_DEFAULT_ACCENT1,
} from "../models/colorScheme";

export default ({
  masterName,
  slideId,
  title,

  containerX = INNER_X,
  containerY = INNER_Y,
  containerW = INNER_W,
  containerH = INNER_H,

  backgroundColor = COLOR_DEFAULT_LIGHT1,
  accentColor = COLOR_DEFAULT_ACCENT1,
  lightColor = COLOR_DEFAULT_LIGHT2,
  textColor = COLOR_DEFAULT_DARK1,

  funnelHeaderColor = textColor, // dark1
  funnelHeaderFontSize = CONTENT_LEVEL3_FONT_SIZE,
  funnelHeaderFontBold = false,

  funnelH = `calc(${INNER_H} / 10)`,
  funnelFill = lightColor, // light2
  funnelTextColor = funnelHeaderColor,
  funnelTextFontSize = funnelHeaderFontSize,
  funnelTextFontBold = funnelHeaderFontBold,
  funnelChevronRightW = "8vw",
  funnelChevronRightRadius = 0.2,
  funnelOffsetLeft = `calc(2 * ${GUTTER_SIZE})`,
  funnelOffsetRight = funnelOffsetLeft,
  funnelItemsSpacerW = `calc(3 * ${funnelOffsetLeft})`,
  funnelArrowsColor = funnelTextColor,
  funnelArrowsWidth = 1, // FIXME: extract -> layoutConfig
  funnelArrowsHead = null,
  funnelArrowsTail = "triangle",
  funnelArrowsOffsetLeft = GUTTER_SIZE,
  funnelArrowsOffsetRight = funnelArrowsOffsetLeft,

  funnelFooterColor = accentColor, // accent1
  funnelFooterFontSize = CONTENT_LEVEL4_FONT_SIZE,
  funnelFooterFontBold = true,

  bodyColor = funnelHeaderColor,
  bodyFontSize = funnelHeaderFontSize,
  bodyFontBold = funnelHeaderFontBold,
  bodyAlign = "center",
  bodyValign = "center",

  funnel = [],
  body,
}) => {
  const funnelShapeX = containerX;
  const funnelShapeW = containerW;
  const funnelShapeH = funnelH;
  const funnelShapeY = `calc(${containerY} + (${containerH} / 2) - (2 * ${funnelShapeH}))`;
  const funnelShapeEl = (
    <Group key="funnel-group">
      <Shape
        key="funnel-chevron-right"
        shape="chevron"
        fill={funnelFill}
        x={`calc(${funnelShapeX} + ${funnelShapeW} - (${
          1 - funnelChevronRightRadius
        } * ${funnelChevronRightW}))`}
        y={funnelShapeY}
        w={funnelChevronRightW}
        h={funnelShapeH}
        radius={funnelChevronRightRadius}
      />
      <Rect
        key="funnel-rect"
        x={funnelShapeX}
        y={funnelShapeY}
        w={funnelShapeW}
        h={funnelShapeH}
        fill={funnelFill}
      />
    </Group>
  );

  const funnelTextSpacersW = `calc(${
    funnel.length ? funnel.length - 1 : 0
  } * ${funnelItemsSpacerW})`;
  const funnelHeaderW = `calc((${funnelShapeW} - ${funnelOffsetLeft} - ${funnelOffsetRight} - ${funnelTextSpacersW})/ ${funnel.length})`;
  const funnelHeaderH = funnelH; //`calc(2 * ${funnelH})`;
  const funnelHeaderY = `calc(${funnelShapeY} - ${funnelHeaderH})`;
  const funnelHeaderX1 = `calc(${funnelShapeX} + ${funnelOffsetLeft})`;
  const funnelHeaderXs = funnel.map(
    (_, idx) =>
      `calc(${funnelHeaderX1} + (${idx} * (${funnelHeaderW} + ${funnelItemsSpacerW})))`
  );
  const funnelHeaderEls = funnel.map((el, idx) => (
    <Text
      key={`funnel-header-${idx}`}
      type="body"
      x={funnelHeaderXs[idx]}
      y={funnelHeaderY}
      w={funnelHeaderW}
      h={funnelHeaderH}
      color={funnelHeaderColor}
      fontSize={funnelHeaderFontSize}
      bold={funnelHeaderFontBold}
      align="center"
      valign="bottom"
    >
      {el.header}
    </Text>
  ));

  const funnelTextW = funnelHeaderW;
  const funnelTextH = funnelShapeH;
  const funnelTextY = funnelShapeY;
  const funnelTextX1 = funnelHeaderX1;
  const funnelTextXs = funnel.map(
    (_, idx) =>
      `calc(${funnelTextX1} + (${idx} * (${funnelTextW} + ${funnelItemsSpacerW})))`
  );
  const funnelTextEls = funnel.map((el, idx) => (
    <Text
      key={`funnel-text-${idx}`}
      type="body"
      x={funnelTextXs[idx]}
      y={funnelTextY}
      w={funnelTextW}
      h={funnelTextH}
      color={funnelTextColor}
      fontSize={funnelTextFontSize}
      bold={funnelTextFontBold}
      align="center"
      valign="center"
      autoSize
    >
      {el.text}
    </Text>
  ));

  const funnelFooterW = funnelItemsSpacerW;
  const funnelFooterH = funnelHeaderH;
  const funnelFooterY = `calc(${funnelShapeY} + ${funnelShapeH})`;
  const funnelFooterX1 = `calc(${funnelTextX1} + ${funnelTextW})`;
  const funnelFooterXs = funnel
    .slice(0, funnel.length - 1)
    .map(
      (_, idx) =>
        `calc(${funnelFooterX1} + (${idx} * (${funnelTextW} + ${funnelFooterW})))`
    );
  const funnelFooterEls = funnel.slice(0, funnel.length - 1).map((el, idx) => (
    <Text
      key={`funnel-footer-${idx}`}
      type="body"
      x={funnelFooterXs[idx]}
      y={funnelFooterY}
      w={funnelFooterW}
      h={funnelFooterH}
      color={funnelFooterColor}
      fontSize={funnelFooterFontSize}
      bold={funnelFooterFontBold}
      align="center"
      valign="top"
      autoSize
    >
      {el.footer}
    </Text>
  ));
  const funnelArrowY = `calc(${funnelShapeY} + (${funnelShapeH} / 2))`;
  const funnelArrowEls = funnel
    .slice(0, funnel.length - 1)
    .map((_, idx) => (
      <Line
        key={`funnel-arrow-k${idx}`}
        x={`calc(${funnelFooterXs[idx]} + ${funnelArrowsOffsetLeft})`}
        y={funnelArrowY}
        w={`calc(${funnelFooterW} - ${funnelArrowsOffsetLeft} - ${funnelArrowsOffsetRight})`}
        h={0}
        width={funnelArrowsWidth}
        color={funnelArrowsColor}
        head={funnelArrowsHead}
        tail={funnelArrowsTail}
      />
    ));

  const funnelEl = (
    <Group>
      {funnelShapeEl}
      {funnelHeaderEls}
      {funnelTextEls}
      {funnelArrowEls}
      {funnelFooterEls}
    </Group>
  );

  const bodyEl = (
    <Text
      key="body"
      type="body"
      x={containerX}
      y={`calc(${containerY} + (${containerH} / 2))`}
      w={containerW}
      h={`calc(${containerH} / 2)`}
      color={bodyColor}
      fontSize={bodyFontSize}
      bold={bodyFontBold}
      align={bodyAlign}
      valign={bodyValign}
    >
      {body}
    </Text>
  );

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>

      {funnelEl}

      {bodyEl}
    </Slide>
  );
};
