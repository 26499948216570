import React from "react";
import { observer } from "mobx-react";
import { getType } from "mobx-state-tree";
import { lighten, darken } from "polished";
import { useLogo } from "./deckContext";

import { Deck } from "../slides-engine/smartElements.jsx";

import TitleSlideMaster, {
  Example as TitleSlideSlide,
} from "./masters/TitleSlide.jsx";
import TitleAndContentMaster, {
  Example as TitleAndContentSlide,
} from "./masters/TitleAndContent.jsx";
import SectionHeaderMaster, {
  Example as SectionHeaderSlide,
} from "./masters/SectionHeader.jsx";
import TwoContentMaster, {
  Example as TwoContentSlide,
} from "./masters/TwoContent.jsx";
import ComparisonMaster, {
  Example as ComparisonSlide,
} from "./masters/Comparison.jsx";
import TitleOnlyMaster, {
  MASTER_NAME as TitleOnlyMasterName,
  Example as TitleOnlySlide,
} from "./masters/TitleOnly.jsx";
import BlankMaster, {
  MASTER_NAME as BlankMasterName,
  Example as BlankSlide,
} from "./masters/Blank.jsx";
import ContentWithCaptionMaster, {
  MASTER_NAME_PICTURE as PictureWithCaptionMasterName,
  Example as ContentWithCaptionSlide,
} from "./masters/ContentWithCaption.jsx";
import AgendaMaster, { Example as AgendaSlide } from "./masters/Agenda.jsx";
import FullScreenImageMaster, {
  Example as FullScreenImageSlide,
} from "./masters/FullScreenImage.jsx";
import InspirationQuoteMater, {
  Example as InspirationQuoteSlide,
} from "./masters/InspirationQuote.jsx";
import BigStatementMaster, {
  Example as BigStatementSlide,
} from "./masters/BigStatement.jsx";

import SideBySideComparisonSlide from "./examples/SideBySideComparison.jsx";
import TimelineSlide from "./examples/Timeline.jsx";
import ThreeNumbersSlide from "./examples/ThreeNumbers.jsx";
import GrowthSlide from "./examples/Growth.jsx";
import ProcessSlide from "./examples/Process.jsx";
import PrioritiesSlide from "./examples/Priorities.jsx";
import RoadmapSlide from "./examples/Roadmap.jsx";
import PyramidSlide from "./examples/Pyramid.jsx";
import FunnelSlide from "./examples/Funnel.jsx";
import ThreeContentSlide from "./examples/ThreeContent.jsx";
import QuadContentSlide from "./examples/QuadContent.jsx";
import StrategySlide from "./examples/Strategy.jsx";
import ImageGridSlide from "./examples/ImageGrid.jsx";
import ContentAndImage from "./examples/ContentAndImage.jsx";
import ThreeSixtySlide from "./examples/ThreeSixty.jsx";

import { DeckContext } from "./deckContext";
import { priorities, growthChartData, agendaItems } from "./fixtures";

const TitleSlideMasterGenerator = observer(({ colorScheme }) => {
  const logo = useLogo();
  return <TitleSlideMaster logo={logo} />;
});

const TitleSlideSlideGenerator = observer(({ slide }) => (
  <TitleSlideSlide
    slideId={slide.id}
    title={"ACME inc.\nStrategy and Execution"}
    subtitle={"Presentation to the Board of Directors\n\nJune 9th, 2020"}
  />
));

const TitleAndContentMasterGenerator = observer(({ colorScheme }) => (
  <TitleAndContentMaster
    titleColor={colorScheme.accent1}
    accent1Color={colorScheme.accent1}
  />
));
const TitleAndContentSlideGenerator = observer(({ slide, images }) => (
  <>
    <TitleAndContentSlide
      slideId={slide.id}
      title="Basic content Slide"
      content={`First point\nSecond point\nThird point`}
    />
  </>
));

const SectionHeaderMasterGenerator = observer(
  ({ slide, colorScheme, images }) => {
    return (
      <SectionHeaderMaster
        backgroundImage={slide.background.defaults(images.photoBusinessMeeting)}
        overlayColor={lighten(0.2, colorScheme.accent1)}
        textColor={slide.textColor.defaults(colorScheme.light1)}
      />
    );
  }
);
const SectionHeaderSlideGenerator = ({ slide }) => {
  return (
    <SectionHeaderSlide
      slideId={slide.id}
      title={"Section Break".toUpperCase()}
      subtitle="subtitle"
    />
  );
};
const TwoContentMasterGenerator = observer(({ colorScheme }) => (
  <TwoContentMaster
    titleColor={colorScheme.accent1}
    accent1Color={colorScheme.accent1}
  />
));
const TwoContentSlideGenerator = ({ slide }) => (
  <TwoContentSlide
    slideId={slide.id}
    title="Two Content Slide"
    contentLeft={`First point\nSecond point`}
    contentRight={`Third point\nFourth point`}
  />
);

const ComparisonMasterGenerator = observer(({ colorScheme }) => (
  <ComparisonMaster
    titleColor={colorScheme.accent1}
    accent1Color={colorScheme.accent1}
  />
));
const ComparisonSlideGenerator = ({ slide }) => (
  <ComparisonSlide
    slideId={slide.id}
    title="Basic Comparison Slide"
    headerLeft="Left"
    contentLeft={`First point\nSecond point`}
    headerRight="Right"
    contentRight={`Third point\nFourh point`}
  />
);

const TitleOnlyMasterGenerator = observer(({ colorScheme }) => (
  <TitleOnlyMaster
    titleColor={colorScheme.accent1}
    accent1Color={colorScheme.accent1}
  />
));
const TitleOnlySlideGenerator = ({ slide }) => (
  <TitleOnlySlide slideId={slide.id} title="Title Only" />
);

const BlankMasterGenerator = () => <BlankMaster />;
const BlankSlideGenerator = ({ slide }) => <BlankSlide slideId={slide.id} />;

const ContentWithCaptionMasterGenerator = observer(({ colorScheme }) => (
  <ContentWithCaptionMaster
    titleColor={colorScheme.accent1}
    accent1Color={colorScheme.accent1}
  />
));
const ContentWithCaptionSlideGenerator = ({ slide }) => (
  <ContentWithCaptionSlide slideId={slide.id} title="Content with Caption" />
);

const PictureWithCaptionMasterGenerator = observer(({ colorScheme }) => (
  <ContentWithCaptionMaster
    name={PictureWithCaptionMasterName}
    titleColor={colorScheme.accent1}
    accent1Color={colorScheme.accent1}
    contentType="pic"
  />
));
const PictureWithCaptionSlideGenerator = ({ slide }) => (
  <ContentWithCaptionSlide
    slideId={slide.id}
    master={PictureWithCaptionMasterName}
    title="Picture with Caption"
  />
);

const AgendaMasterGenerator = observer(({ colorScheme }) => (
  <AgendaMaster
    titleColor={colorScheme.accent1}
    accent1Color={colorScheme.accent1}
    leftBarFill={lighten(0.2, colorScheme.accent1)}
    contentColor={colorScheme.dark1}
  />
));
const AgendaSlideGenerator = ({ slide }) => (
  <AgendaSlide
    slideId={slide.id}
    title={"Agenda".toUpperCase()}
    items={agendaItems}
  />
);

const FullScreenImageMasterGenerator = () => <FullScreenImageMaster />;
const FullScreenImageSlideGenerator = observer(({ slide, images }) => (
  <FullScreenImageSlide
    slideId={slide.id}
    image={slide.background.defaults(images.background)}
  />
));

const InspirationQuoteMasterGenerator = observer(
  ({ slide, colorScheme, images }) => (
    <InspirationQuoteMater
      image={slide.background.defaults(images.background)}
      darkColor={slide.baseColor.defaults(colorScheme.accent1)}
      lightColor="ffffff"
    />
  )
);
const InspirationQuoteSlideGenerator = observer(({ slide }) => (
  <InspirationQuoteSlide
    slideId={slide.id}
    quote="Each man should frame life so that at some future hour fact and his dreaming meet."
  />
));
const BigStatementMasterGenerator = observer(({ colorScheme }) => (
  <BigStatementMaster
    titleColor={colorScheme.accent1}
    accent1Color={colorScheme.accent1}
  />
));
const BigStatementSlideGenerator = observer(({ slide }) => (
  <BigStatementSlide
    slideId={slide.id}
    title={"Mission Statement".toUpperCase()}
    body={"Improving the world, one day at a time"}
  />
));

const SideBySideComparisonSlideGenerator = observer(
  ({ slide, images, colorScheme }) => {
    return (
      <SideBySideComparisonSlide
        slideId={slide.id}
        masterName={BlankMasterName}
        backgroundColorLeft={lighten(0.2, colorScheme.accent1)}
        backgroundColorRight={lighten(0.2, colorScheme.accent2)}
        textColor={slide.textColor.defaults(colorScheme.light1)}
        imageLeft={slide.imageLeft.defaults(images.iconComparisonLeft)}
        titleLeft="15%"
        subtitleLeft={"Expected".toUpperCase()}
        descriptionLeft="Growth projections"
        imageRight={slide.imageRight.defaults(images.iconComparisonRight)}
        titleRight="25%"
        subtitleRight={"Achieved".toUpperCase()}
        descriptionRight="Reporting: Top Line"
      />
    );
  }
);

const TimelineSlideGenerator = observer(({ slide, colorScheme }) => (
  <TimelineSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Project timeline".toUpperCase()}
    timelineColor={lighten(0.2, colorScheme.accent1)}
    pointColor={lighten(0.1, colorScheme.accent1)}
    descriptionColor="#545454"
    point1Title="2016"
    point1Description="Project Kickoff"
    point2Title="2017"
    point2Description="Pilot Feedback"
    point3Title="2018"
    point3Description="Project Launch"
    point4Title="2019"
    point4Description="KPI Review"
    point5Title="2020"
    point5Description="Next steps"
  />
));

const ThreeNumbersSlideGenerator = ({ slide }) => (
  <ThreeNumbersSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Key Figures".toUpperCase()}
    points={[
      { title: "56'790", description: "Total Active Users" },
      { title: "25", description: "Daily Support Requests" },
      { title: "56'790", description: "YoY Growth" },
    ]}
  />
);

const GrowthSlideGenerator = observer(({ slide, colorScheme }) => (
  <GrowthSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"User Adoption".toUpperCase()}
    chartColor={lighten(0.2, colorScheme.accent1)}
    titleLeft="56'790"
    descriptionLeft="Total Active Users"
    chartData={growthChartData}
  />
));

const ProcessSlideGenerator = observer(({ slide, colorScheme }) => (
  <ProcessSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Project Steps".toUpperCase()}
    timelineColor={colorScheme.light2}
    chevronColor={darken(0.2, colorScheme.light2)}
    circleColors={[
      lighten(0.1, colorScheme.accent1),
      lighten(0.1, colorScheme.accent2),
      colorScheme.accent1,
      colorScheme.accent2,
      lighten(0.1, colorScheme.accent1),
      lighten(0.1, colorScheme.accent2),
    ]}
    subtitleTextColor={colorScheme.dark1}
    annotationLineColor={darken(0.2, colorScheme.light2)}
  />
));

const PrioritiesSlideGenerator = observer(({ slide, colorScheme }) => (
  <PrioritiesSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Top Priorities".toUpperCase()}
    row2BackgroundColor={slide.row2BackgroundColor.defaults(colorScheme.light2)}
    itemNumberBackgroundColor={slide.numberColor.defaults(colorScheme.accent1)}
    items={priorities}
  />
));

const RoadmapSlideGenerator = observer(({ slide, colorScheme }) => {
  const r1c = lighten(0.2, colorScheme.accent1);
  const r2c = lighten(0.2, colorScheme.accent2);
  const r3c = r1c;
  const r4c = r2c;
  return (
    <RoadmapSlide
      slideId={slide.id}
      masterName={TitleOnlyMasterName}
      columnHeadersColor={slide.columnHeadersColor.defaults(colorScheme.dark2)}
      rowSeparatorColor={slide.columnHeadersColor.defaults(colorScheme.light2)}
      title={"Roadmap".toUpperCase()}
      columnHeaders={["Q1", "Q2", "Q3", "Q4"]}
      rowHeaders={["Product", "Marketing", "Customer Success", "Operations"]}
      content={[
        [
          [
            {
              type: "rect",
              text: "User Onboarding",
              fill: r1c,
            },
            {
              type: "rect",
              text: "GDPR",
              fill: r1c,
            },
          ],
          {
            type: "rect",
            text: "Content Recommendation System",
            colspan: 2,
            fill: r1c,
          },
          [],
          [
            {
              type: "rect",
              text: "WeChat Port",
              fill: r1c,
            },
            {
              type: "rect",
              text: "Telegram Bot",
              fill: r1c,
            },
          ],
        ],
        [
          {
            type: "rect",
            text: "Newsletter",
            fill: r2c,
          },
          {
            type: "rect",
            text: "YouTube Ad Campaign",
            fill: r2c,
          },
          {
            type: "rect",
            text: "Trade Show Exhibition",
            fill: r2c,
          },
          {
            type: "rect",
            text: "APAC Market Strategy",
            fill: r2c,
          },
        ],
        [
          {
            type: "rect",
            text: "Recruit 2xFTE",
            fill: r3c,
          },
          [
            {
              type: "rect",
              text: "Knowledge Base",
              fill: r3c,
            },

            {
              type: "rect",
              text: "YouTube Lectures",
              fill: r3c,
            },
          ],
          {
            type: "rect",
            text: "Customer Interviews",
            fill: r3c,
          },
          {
            type: "rect",
            text: "On-site Workshops",
            fill: r3c,
          },
        ],
        [
          {
            type: "rect",
            text: "Uptime Dashboard",
            fill: r4c,
          },
          {
            type: "rect",
            text: "SLA Reporting",
            fill: r4c,
          },
          {
            type: "rect",
            text: "Distributed Ops Team",
            colspan: 2,
            fill: r4c,
          },
        ],
      ]}
    />
  );
});

const PyramidSlideGenerator = observer(({ slide, colorScheme }) => (
  <PyramidSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Subscription Base".toUpperCase()}
    upArrowColor={colorScheme.light2}
    upArrowTextColor={colorScheme.light1}
    upArrowText={"Enterprise".toUpperCase()}
    pyramidTextColor={colorScheme.light1}
    pyramidLevels={["\nall company", "teams &\ndepartments", "public use"]}
    pyramidColors={[
      lighten(0.1, colorScheme.accent1),
      lighten(0.2, colorScheme.accent1),
      lighten(0.3, colorScheme.accent1),
    ]}
    bulletColor={colorScheme.accent1}
    descriptionsTextColor={colorScheme.dark1}
    descriptions={[
      "enterprise customers\nauto-renewal contracts\npre-paid licenses 50K+",
      "teams up to 15 pax\npay be credit card\nmass discounts",
      "individual use\nfree of charge\ndata ownership transfer",
    ]}
    arrowsColor={colorScheme.dark1}
  />
));

const FunnelSlideGenerator = observer(({ slide, colorScheme }) => (
  <FunnelSlide
    masterName={TitleOnlyMasterName}
    slideId={slide.id}
    title={"Customer Funnel".toUpperCase()}
    backgroundColor={colorScheme.light1}
    accentColor={colorScheme.accent1}
    lightColor={colorScheme.light2}
    textColor={colorScheme.dark1}
    funnel={[
      {
        header: "Outbound",
        text: "10'000",
        footer: "10%",
      },
      {
        header: "Opportunities",
        text: "1'000",
        footer: "25%",
      },
      {
        header: "Trial",
        text: "250",
        footer: "10%",
      },
      {
        header: "Subscription",
        text: "25",
      },
    ]}
    body={[
      "Revenue 2020 = $400k+ (Trial x 250)",
      "CaC (approx) = $1’000 → costs estimated $125k in H1",
      "Goals 2021: avg subscription > 2x CaC",
    ].join("\n")}
  />
));

const ThreeContentSlideGenerator = observer(({ slide, colorScheme }) => (
  <ThreeContentSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Pivot trajectory".toUpperCase()}
    content={[
      {
        header: "Today".toUpperCase(),
        text: [
          "Exciting idea",
          "Weak product",
          "Presentation-ware",
          "Poor executing",
          "Undefined strategy",
          "Non-methodological customer acquisition",
          "Wishful pipeline",
          "Overblown EoY targets",
          "Misaligned team",
        ].join("\n"),
        lineSpacing: 1.5,
        // background: colorScheme.light2,
      },
      {
        header: "→",
        text: [
          "Bottom-up organisation with the help of management coaching",
          "Define roadmap until EoY",
          "Re-align primary departments: R&D, Product and Business development",
          "Communication & outreach: focus on simplicity & customer value",
          "Consistent bizdev through repeatable scalable pilots",
        ].join("\n"),
        align: "center",
        background: colorScheme.light2,
        bullet: false,
      },
      {
        header: "Tomorrow".toUpperCase(),
        text: [
          "Success-proven idea",
          "Strong product",
          "Crystal clear added value",
          "Defined business processes",
          "Coherent sales strategy",
          "Validated value for customers",
          "Highly aligned team",
          "Consensus leadership",
        ].join("\n"),
        lineSpacing: 1.5,
        // background: colorScheme.light2,
      },
    ]}
    accent1Color={colorScheme.accent1}
  />
));

const QuadContentSlideGenerator = observer(({ slide, colorScheme }) => (
  <QuadContentSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title="SWOT"
    accent1Color={colorScheme.accent1}
    centerArrowColor={lighten(0.1, colorScheme.accent1)}
    content={[
      [
        {
          header: "Strengths".toUpperCase(),
          body: [
            "Robust architecture",
            "High-quality team",
            "Capital access",
          ].join("\n"),
          background: colorScheme.light2,
        },
        {
          header: "Weakness".toUpperCase(),
          body: [
            "Unproven market fit",
            "Chicken & egg problem",
            "Labour-intensive PoCs",
          ].join("\n"),
        },
      ],
      [
        {
          header: "Opportunities".toUpperCase(),
          body: [
            "Standardized offer",
            "Academia partnerships",
            "Free-tier product",
          ].join("\n"),
        },
        {
          header: "Threats".toUpperCase(),
          body: [
            "Reverse-engineering",
            "Customer perception",
            "Cannibalization",
          ].join("\n"),
          background: colorScheme.light2,
        },
      ],
    ]}
    centerItems={[
      { text: "Action".toUpperCase(), fill: lighten(0.2, colorScheme.accent1) },
      {
        text: "Analyse".toUpperCase(),
        fill: lighten(0.2, colorScheme.accent2),
        arrowFill: lighten(0.1, colorScheme.accent1),
      },
      {
        text: "Identify".toUpperCase(),
        fill: lighten(0.2, colorScheme.accent2),
      },
    ]}
  />
));

const StrategySlideGenerator = observer(({ slide, colorScheme }) => (
  <StrategySlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Market Strategy".toUpperCase()}
    content={{
      headers: {
        columns: ["", "Customer Needs", "Added Value"],
        rows: ["enterprise", "paid", "free"],
      },
      content: [
        [
          {
            type: "rect",
            text: "Transform",
            align: "center",
            valign: "center",
            color: colorScheme.light1,
            fill: lighten(0.2, colorScheme.accent1),
            overrideH: false,
          },
          {
            type: "text",
            text: [
              "Partnerships",
              "Exposure",
              "Financial optimisation",
              "Outsourcing",
            ].join("\n"),
          },
          {
            type: "text",
            text: [
              "Financial modelling",
              "Contact database access + matching",
            ].join("\n"),
          },
        ],
        [
          {
            type: "rect",
            text: "Discover points of growth",
            align: "center",
            valign: "center",
            color: colorScheme.light1,
            fill: lighten(0.2, colorScheme.accent2),
            overrideH: false,
          },
          {
            type: "text",
            text: ["Strengths", "New possibilities"].join("\n"),
          },
          {
            type: "text",
            text: [
              "Aggregated profile",
              "Market ranking",
              "Cohort analytics",
            ].join("\n"),
          },
        ],
        [
          {
            type: "rect",
            text: " Undersatnd pain points",
            align: "center",
            valign: "center",
            color: colorScheme.light1,
            fill: lighten(0.2, colorScheme.accent2),
            overrideH: false,
          },
          {
            type: "text",
            text: ["Weaknessess", "Areas for improvement"].join("\n"),
          },
          {
            type: "text",
            text: "Personalised analytics",
          },
        ],
      ],
    }}
    rowHeaderColor={colorScheme.dark1}
    rowSeparatorColor={colorScheme.dark1}
    rowSeparatorIncludeFirst={false}
    columnHeaderColor={colorScheme.dark1}
    columnSeparatorColor={null}
    columnHeaderFontBold={true}
    cellTextColor={colorScheme.dark1}
    upArrowColor={lighten(0.1, colorScheme.accent1)}
    rowHeaderTriangleColor={lighten(0.2, colorScheme.accent1)}
    rowHeaderTriangleText={"volume"}
    rowHeaderTriangleTextColor={colorScheme.light1}
  />
));

const ShowcaseSlideGenerator = observer(({ slide, images }) => (
  <ImageGridSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Showcase".toUpperCase()}
    content={[
      [
        {
          title: "Banking app".toUpperCase(),
          subtitle:
            "Leading EMEA mobile-first virual banking\n50M monthly users",
          image: images.showcase1,
        },
        {
          title: "Artbook".toUpperCase(),
          subtitle:
            "Best-seller photography collection, 25th anniversary edition",
          image: images.showcase2,
        },
        {
          title: "Marketing campaign".toUpperCase(),
          subtitle:
            "Beauty products lineup for a world-leading cosmetics brand",
          image: images.showcase3,
        },
      ],
    ]}
  />
));

const MeetTheTeamSlideGenerator = observer(({ slide, images }) => (
  <ImageGridSlide
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Meet the Team".toUpperCase()}
    content={[
      [
        {
          title: "John Smith",
          subtitle: "FOUNDER & CEO\n\njohn@acme.inc",
          image: images.meetTheTeam1,
        },
        {
          title: "Alice Brooks",
          subtitle: "ART DIRECTOR\n\nalice@acme.inc",
          image: images.meetTheTeam2,
        },
        {
          title: "Marina Jones",
          subtitle: "DEVELOPER\n\nmarina@acme.inc",
          image: images.meetTheTeam3,
        },
        {
          title: "Booby Williams",
          subtitle: "INTERN\n\nbob@acme.inc",
          image: images.meetTheTeam4,
        },
      ],
    ]}
  />
));

const ContactUsSlideGenerator = observer(({ slide, colorScheme, images }) => (
  <ContentAndImage
    slideId={slide.id}
    masterName={TitleOnlyMasterName}
    title={"Contact Us".toUpperCase()}
    overlayColor={lighten(0.2, colorScheme.accent1)}
    image={images.contactUs1}
    content={[
      "contact@acme.inc",
      "",
      "",
      "46th St & 1st Ave",
      "New York, NY 10017",
      // "",
      "tel: +1 (212) 963-1234",
      "–",
      "Rue de la Loi 175",
      "B-1048 Bruxelles",
      // "",
      "tel: +32 (2) 281-61-11",
      "",
      "",
      "www.acme.inc",
    ].join("\n")}
  />
));

const GDPRComplianceSlideGenerator = observer(
  ({ slide, colorScheme, images }) => (
    <ThreeSixtySlide
      slideId={slide.id}
      masterName={TitleOnlyMasterName}
      title={"GDPR Compliance".toUpperCase()}
      backgroundColor={colorScheme.light1}
      accentColor={colorScheme.accent1}
      lightColor={colorScheme.light2}
      textColor={colorScheme.dark1}
      content={{
        icon: images.iconGDPR1,
        header:
          "All-encompassing approach to privacy and security, end-to-end personal data lifecycle",
        footer: [
          "The General Data Protection Regulation (GDPR) (Regulation (EU) 2016/679) is a regulation by the European Commission to unify personal data protection laws and regulations for all individuals within the European Union (EU)",
          "The Privacy Shield designed by the U.S. Department of Commerce and the European Commission and Swiss Administration to provide companies on both sides of the Atlantic with a mechanism to comply with data protection requirements when transferring personal data",
        ],
        items: [
          {
            title: "Collect",
            subtitle: "Only must-have personal information is collected",
            description: [
              "Terms of Use",
              "Privacy Policy",
              "Explicit intent and purpose",
            ],
          },
          {
            title: "Store",
            subtitle: "End-to-end in transfer and at-rest encryption in place",
            description: [
              "SOC2-certficied cloud providers",
              "Access control restrictions",
            ],
          },
          {
            title: "Use",
            subtitle:
              "Collected personal information used only for declared purpose",
            description: ["Quarterly risk assessment", "Annual security audit"],
          },
          {
            title: "Share",
            subtitle: "Explicit user-consent required and full audit logging",
            description: [
              "Strictly listed 3rd-parties",
              "Users notified by email",
            ],
          },
          {
            title: "Archive",
            subtitle: "Incremental, encrypted at rest",
            description: [
              "Encrypted; offsite",
              "Periodic Disaster Recovery testing",
            ],
          },
          {
            title: "Dispose",
            subtitle: "Upon request or according to the data retention policy",
            description: [
              "Data Retention Policy",
              "Bimonthly Disaster Recovery testing",
            ],
          },
        ],
      }}
    />
  )
);

const slidesMap = {
  TitleSlideMaster: TitleSlideMasterGenerator,
  TitleSlideSlide: TitleSlideSlideGenerator,

  TitleAndContentMaster: TitleAndContentMasterGenerator,
  TitleAndContentSlide: TitleAndContentSlideGenerator,

  SectionHeaderMaster: SectionHeaderMasterGenerator,
  SectionHeaderSlide: SectionHeaderSlideGenerator,

  TwoContentMaster: TwoContentMasterGenerator,
  TwoContentSlide: TwoContentSlideGenerator,

  ComparisonMaster: ComparisonMasterGenerator,
  ComparisonSlide: ComparisonSlideGenerator,

  TitleOnlyMaster: TitleOnlyMasterGenerator,
  TitleOnlySlide: TitleOnlySlideGenerator,

  BlankMaster: BlankMasterGenerator,
  BlankSlide: BlankSlideGenerator,

  ContentWithCaptionMaster: ContentWithCaptionMasterGenerator,
  ContentWithCaptionSlide: ContentWithCaptionSlideGenerator,

  PictureWithCaptionMaster: PictureWithCaptionMasterGenerator,
  PictureWithCaptionSlide: PictureWithCaptionSlideGenerator,

  AgendaMaster: AgendaMasterGenerator,
  AgendaSlide: AgendaSlideGenerator,

  FullScreenImageMaster: FullScreenImageMasterGenerator,
  FullScreenImageSlide: FullScreenImageSlideGenerator,

  InspirationQuoteMaster: InspirationQuoteMasterGenerator,
  InspirationQuoteSlide: InspirationQuoteSlideGenerator,

  BigStatementMaster: BigStatementMasterGenerator,
  BigStatementSlide: BigStatementSlideGenerator,

  SideBySideComparisonSlide: SideBySideComparisonSlideGenerator,

  TimelineSlide: TimelineSlideGenerator,

  ThreeNumbersSlide: ThreeNumbersSlideGenerator,
  GrowthSlide: GrowthSlideGenerator,
  ProcessSlide: ProcessSlideGenerator,
  PrioritiesSlide: PrioritiesSlideGenerator,
  RoadmapSlide: RoadmapSlideGenerator,
  PyramidSlide: PyramidSlideGenerator,
  FunnelSlide: FunnelSlideGenerator,
  ThreeContentSlide: ThreeContentSlideGenerator,
  QuadContentSlide: QuadContentSlideGenerator,
  StrategySlide: StrategySlideGenerator,
  ShowcaseSlide: ShowcaseSlideGenerator,
  MeetTheTeamSlide: MeetTheTeamSlideGenerator,
  ContactUsSlide: ContactUsSlideGenerator,
  GDPRComplianceSlide: GDPRComplianceSlideGenerator,
};

export default observer(
  ({ slidesDeck, defaultImages, slides, slideMasters }) => {
    return (
      <Deck
        fontFamily={slidesDeck.fontFamily}
        titleFontFamily={slidesDeck.titleFontFamily}
        colorScheme={slidesDeck.colorScheme}
        layout={slidesDeck.layout}
        viewBox={slidesDeck.viewBox}
      >
        <DeckContext.Provider value={slidesDeck}>
          {[...slideMasters, ...slides].map((slide) => {
            const Component = slidesMap[getType(slide).name];
            return (
              <Component
                key={slide.id}
                slide={slide}
                colorScheme={slidesDeck.colorScheme}
                images={defaultImages}
              />
            );
          })}
        </DeckContext.Provider>
      </Deck>
    );
  }
);

export const SingleSlide = observer(
  ({ slidesDeck, defaultImages, slide, slideMasters }) => {
    let Master = null;
    let master = null;
    const Component = slidesMap[getType(slide).name];
    if (slide.master) {
      master = slideMasters.find(
        (master) => getType(master).name === slide.master
      );
      Master = slidesMap[slide.master];
    }

    return (
      <Deck
        fontFamily={slidesDeck.fontFamily}
        titleFontFamily={slidesDeck.titleFontFamily}
        colorScheme={slidesDeck.colorScheme}
        layout={slidesDeck.layout}
        viewBox={slidesDeck.viewBox}
      >
        <DeckContext.Provider value={slidesDeck}>
          {Master && (
            <Master
              key={master.id}
              slide={master}
              colorScheme={slidesDeck.colorScheme}
              images={defaultImages}
            />
          )}
          <Component
            key={slide.id}
            slide={slide}
            colorScheme={slidesDeck.colorScheme}
            images={defaultImages}
          />
        </DeckContext.Provider>
      </Deck>
    );
  }
);
