import React from "react";

import { Group, Text, Shape } from "../../slides-engine/smartElements.jsx";

export default ({
  x = 0,
  y = 0,
  w = "100%",
  h = "100%",
  circleOffsetX = "0.25vw", // aligning circle <=> text horizontally
  circleOffsetY = "0.8vh", // aligning circle <=> text vertically
  descriptionOffsetY = "0.5vh", // bringing text <=> description closer
  circleWH = "5vh",
  circleColor = "#000000",
  circleTextColor = "#FFFFFF",
  fontSize = 14,
  circleFontSize = fontSize,
  textColor = "#000000",
  textFontSize = fontSize,
  textFontBold = false,
  descriptionColor = textColor,
  descriptionFontSize = fontSize,
  descriptionFontBold = false,
  number = "",
  text = "",
  description = "",
}) => {
  const circleX = x;
  const circleY = `calc(${y} + ${circleOffsetY})`;
  const circleH = circleWH;
  const circleW = circleH;

  const textX = `calc(${x} + ${circleWH} - ${circleOffsetX})`;
  const textY = y;
  const textW = `calc(${w} - ${circleWH})`;
  const textH = circleWH;

  const descriptionX = textX;
  const descriptionY = `calc(${textY} + ${textH} - ${descriptionOffsetY})`;
  const descriptionW = textW;
  const descriptionH = `calc(${h} - ${textH} + ${descriptionOffsetY})`;

  return (
    <Group>
      <Group>
        <Shape
          shape="oval"
          x={circleX}
          y={circleY}
          h={circleH}
          w={circleW}
          fill={circleColor}
        />
        <Text
          x={circleX}
          y={circleY}
          h={circleH}
          w={circleW}
          color={circleTextColor}
          fontSize={circleFontSize}
          align="center"
          valign="center"
        >
          {number}
        </Text>
      </Group>
      <Text
        x={textX}
        y={textY}
        h={textH}
        w={textW}
        color={textColor}
        fontSize={textFontSize}
        bold={textFontBold}
        align="left"
        valign="center"
      >
        {text}
      </Text>
      <Text
        x={descriptionX}
        y={descriptionY}
        h={descriptionH}
        w={descriptionW}
        color={descriptionColor}
        fontSize={descriptionFontSize}
        bold={descriptionFontBold}
        align="left"
        valign="top"
      >
        {description}
      </Text>
    </Group>
  );
};
