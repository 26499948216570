import React from "react";
import render, { renderBlob } from "../slides-engine";

import Presentation from "./Presentation.jsx";

export const presentationAsPPTX = ({
  deck,
  colorScheme,
  defaultImages,
  slides,
  slideMasters,
  title,
}) => {
  render(
    <Presentation
      slidesDeck={deck}
      slides={slides}
      slideMasters={slideMasters}
      colorScheme={colorScheme}
      defaultImages={defaultImages}
    />,
    title
  );
};

export const presentationAsBlob = ({
  deck,
  colorScheme,
  defaultImages,
  slides,
  slideMasters,
}) => {
  return renderBlob(
    <Presentation
      slidesDeck={deck}
      slides={slides}
      slideMasters={slideMasters}
      colorScheme={colorScheme}
      defaultImages={defaultImages}
    />
  );
};
