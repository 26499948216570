import React from "react";
import { AreaChart, Text, Slide } from "../../slides-engine/smartElements.jsx";
import {
  CONTENT_LEVEL0_FONT_SIZE,
  CONTENT_LEVEL4_FONT_SIZE,
  INNER_X,
  INNER_CY,
} from "../layoutConfig.js";
import Point, {
  DEFAULT_W as POINT_DEFAULT_W,
  DEFAULT_H as POINT_DEFAULT_H,
} from "../components/PointInfo.jsx";

export default ({
  slideId,
  masterName,

  backgroundColor = "#FFFFFF",

  containerX = INNER_X,
  containerCY = INNER_CY,

  pointW = POINT_DEFAULT_W,
  pointH = POINT_DEFAULT_H,

  titleColor = "#000000",
  titleSize = CONTENT_LEVEL0_FONT_SIZE,

  descriptionColor = "#000000",
  descriptionSize = CONTENT_LEVEL4_FONT_SIZE,

  chartColor = "#000000",

  title = "Title",

  titleLeft = "left",
  descriptionLeft = "left description",

  chartData = { labels: [], values: [] },
}) => {
  const MAXVAL = Math.max(...chartData.values);

  const pointY = `calc(${containerCY} - (${pointH} / 2))`;

  return (
    <Slide slideId={slideId} master={masterName} bkgd={backgroundColor}>
      <Text placeholder="title">{title}</Text>

      <AreaChart
        data={[chartData]}
        x="-6.1%"
        y="1.2%"
        w="112.2%"
        h="100%"
        chartColors={[chartColor]}
        chartColorsOpacity={100}
        catAxisHidden
        showCatAxisTitle={false}
        catAxisLineShow={false}
        valAxisHidden
        showValAxisTitle={false}
        valAxisLineShow={false}
        valGridLine={{ style: "none" }}
        valAxisMaxVal={MAXVAL}
      />

      <Point
        x={containerX}
        y={pointY}
        w={pointW}
        h={pointH}
        titleColor={titleColor}
        titleFontSize={titleSize}
        descriptionColor={descriptionColor}
        descriptionFontSize={descriptionSize}
        title={titleLeft}
        description={descriptionLeft}
        lineHidden={false}
      />
    </Slide>
  );
};
