import { types, getSnapshot } from "mobx-state-tree";
import sortBy from "lodash/sortBy";
import some from "lodash/some";
import { complement } from "polished";
import { deltaE } from "../../utils";

export const COLOR_DEFAULT_DARK1 = "#000000";
export const COLOR_DEFAULT_DARK2 = "#44546A";

export const COLOR_DEFAULT_LIGHT1 = "#FFFFFF";
export const COLOR_DEFAULT_LIGHT2 = "#E7E6E6";

export const COLOR_DEFAULT_ACCENT1 = "#4472C4";
export const COLOR_DEFAULT_ACCENT2 = "#ED7D31";
export const COLOR_DEFAULT_ACCENT3 = "#A5A5A5";
export const COLOR_DEFAULT_ACCENT4 = "#FFC000";
export const COLOR_DEFAULT_ACCENT5 = "#5B9BD5";
export const COLOR_DEFAULT_ACCENT6 = "#70AD47";

export const COLOR_DEFAULT_ACCENTS = [
  COLOR_DEFAULT_ACCENT1,
  COLOR_DEFAULT_ACCENT2,
  COLOR_DEFAULT_ACCENT3,
  COLOR_DEFAULT_ACCENT4,
  COLOR_DEFAULT_ACCENT5,
  COLOR_DEFAULT_ACCENT6,
];

export const COLOR_DEFAULT_HYPERLINK = "#0563C1";
export const COLOR_DEFAULT_FOLLOWED_HYPERLINK = "#954F72";

export const COLOR_SIMILARITY_THRESHOLD = 25;

export const COLOR_SCHEME_PRESET_DEFAULT = "preset1";
export const COLOR_SCHEME_PRESET_CUSTOM = "custom";

const orderPalette = (palette, colorMain) => {
  const subPalette = palette.filter((color) => color !== colorMain);
  const sorted = sortBy(subPalette, (color) => {
    return -deltaE(colorMain, color);
  });
  return [colorMain, ...sorted];
};

const filterCloseColors = (
  colorList,
  colorsToRemove,
  threshold = COLOR_SIMILARITY_THRESHOLD
) =>
  colorList.filter(
    (color) =>
      !some(colorsToRemove, (toRemove) => deltaE(toRemove, color) < threshold)
  );

const fillColorList = (colorList) => {
  // We should do something a bit smarter here and guess some colours
  return [
    ...colorList,
    ...COLOR_DEFAULT_ACCENTS.slice(colorList.length, 6),
  ].slice(0, 6);
};

const PalettePresets = types
  .model("PalettePresets", {
    originalColors: types.array(types.string),
    customColors: types.array(types.string),
  })
  .views((self) => ({
    get additionalColors() {
      return COLOR_DEFAULT_ACCENTS.slice(self.originalColors.length, 6);
    },
    get preset1() {
      const main = self.originalColors[0] || COLOR_DEFAULT_ACCENT1;
      return fillColorList(orderPalette(self.originalColors, main));
    },
    get preset2() {
      const main = self.originalColors[1] || COLOR_DEFAULT_ACCENT2;
      return fillColorList(orderPalette(self.originalColors, main));
    },
    get preset3() {
      const main = self.originalColors[2] || COLOR_DEFAULT_ACCENT3;
      return fillColorList(orderPalette(self.originalColors, main));
    },
    get preset4() {
      const main = self.originalColors[3] || COLOR_DEFAULT_ACCENT4;
      return fillColorList(orderPalette(self.originalColors, main));
    },
    get custom() {
      return fillColorList(self.customColors);
    },
  }));

const CustomColorScheme = types
  .model("CustomColorScheme", {
    accent1: types.optional(types.string, COLOR_DEFAULT_ACCENT1),
    accent2: types.optional(types.string, COLOR_DEFAULT_ACCENT2),
    accent3: types.optional(types.string, COLOR_DEFAULT_ACCENT3),
    accent4: types.optional(types.string, COLOR_DEFAULT_ACCENT4),
    accent5: types.optional(types.string, COLOR_DEFAULT_ACCENT5),
    accent6: types.optional(types.string, COLOR_DEFAULT_ACCENT6),
  })
  .views((self) => ({
    get asPalette() {
      return [
        self.accent1,
        self.accent2,
        self.accent3,
        self.accent4,
        self.accent5,
        self.accent6,
      ];
    },
  }))
  .actions((self) => ({
    change(color, newColor) {
      self[color] = newColor;
    },
    reinitialize(newPalette) {
      self.accent1 = newPalette[0] || COLOR_DEFAULT_ACCENT1;
      self.accent2 = complement(self.accent1);
      self.accent3 = newPalette[1] || COLOR_DEFAULT_ACCENT2;
      self.accent4 = complement(self.accent3);
      self.accent5 = newPalette[2] || COLOR_DEFAULT_ACCENT3;
      self.accent6 = complement(self.accent5);
    },
  }));

export default types
  .model("ColorScheme", {
    dark1: types.optional(types.string, COLOR_DEFAULT_DARK1),
    dark2: types.optional(types.string, COLOR_DEFAULT_DARK2),

    light1: types.optional(types.string, COLOR_DEFAULT_LIGHT1),
    light2: types.optional(types.string, COLOR_DEFAULT_LIGHT2),

    hLink: types.optional(types.string, COLOR_DEFAULT_HYPERLINK),
    folHLink: types.optional(types.string, COLOR_DEFAULT_FOLLOWED_HYPERLINK),

    preset: types.optional(types.string, COLOR_SCHEME_PRESET_DEFAULT),
    customScheme: types.optional(CustomColorScheme, {}),

    palette: types.optional(types.array(types.string), COLOR_DEFAULT_ACCENTS),
  })
  .views((self) => ({
    get palettePresets() {
      return PalettePresets.create({
        originalColors: getSnapshot(self.palette),
        customColors: self.customScheme.asPalette,
      });
    },
    get accent1() {
      if (self.preset === COLOR_SCHEME_PRESET_CUSTOM)
        return self.customScheme.accent1;
      return self.palettePresets[self.preset][0];
    },
    get accent2() {
      if (self.preset === COLOR_SCHEME_PRESET_CUSTOM)
        return self.customScheme.accent2;
      return self.palettePresets[self.preset][1];
    },
    get accent3() {
      if (self.preset === COLOR_SCHEME_PRESET_CUSTOM)
        return self.customScheme.accent3;
      return self.palettePresets[self.preset][2];
    },
    get accent4() {
      if (self.preset === COLOR_SCHEME_PRESET_CUSTOM)
        return self.customScheme.accent4;
      return self.palettePresets[self.preset][3];
    },
    get accent5() {
      if (self.preset === COLOR_SCHEME_PRESET_CUSTOM)
        return self.customScheme.accent5;
      return self.palettePresets[self.preset][4];
    },
    get accent6() {
      if (self.preset === COLOR_SCHEME_PRESET_CUSTOM)
        return self.customScheme.accent6;
      return self.palettePresets[self.preset][5];
    },
  }))
  .actions((self) => ({
    change(color, newColor) {
      self[color] = newColor;
    },

    fromPalette(palette) {
      if (!palette) return;

      const accentPalette = filterCloseColors(palette, [
        self.dark1,
        self.dark2,
        self.light1,
        self.light2,
      ]);

      self.palette = accentPalette;

      self.customScheme.reinitialize(self.palette);
    },
    applyPreset(id = COLOR_SCHEME_PRESET_DEFAULT) {
      self.preset = id;
    },
    presetTitle(presetId) {
      switch (presetId) {
        case "preset1":
          return "Preset 1";
        case "preset2":
          return "Preset 2";
        case "preset3":
          return "Preset 3";
        case "preset4":
          return "Preset 4";
        case "custom":
          return "Custom";
        default:
          return "";
      }
    },
  }));
