import { SHAPES, CHARTS } from "./utils";

const AVAILABLE_CHILDREN = [
  "text",
  "line",
  "rect",
  "shape",
  "image",
  "media",
  "shape",
  "tables",
  "charts",
  "area-chart",
  "line-chart",
  "slide-number",
  "group",
];

export const renderTag = (slide, child) => {
  if (child.tag === "text") {
    const { text, options } = child.render();
    slide.addText(text, options);
  } else if (child.tag === "rect") {
    slide.addShape(SHAPES.RECTANGLE, child.render());
  } else if (child.tag === "line") {
    slide.addShape(SHAPES.LINE, child.render());
  } else if (child.tag === "line-chart") {
    const [data, config] = child.render();
    slide.addChart(CHARTS.LINE, data, config);
  } else if (child.tag === "area-chart") {
    const [data, config] = child.render();
    slide.addChart(CHARTS.AREA, data, config);
  } else if (child.tag === "shape") {
    const [shape, options] = child.render();
    if (shape === "text") slide.addText(options.text, options.options);
    else slide.addShape(shape, options);
  } else if (child.tag === "image") {
    slide.addImage(child.render());
  } else if (child.tag === "slide-number") {
    slide.slideNumber(child.render());
  } else if (child.tag === "group") {
    const group = slide.newGroup();
    for (let groupChild of child.children) {
      renderTag(group, groupChild);
    }
  }
};

export class Group {
  constructor(props) {
    this.tag = "group";
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    if (child.tag && !AVAILABLE_CHILDREN.includes(child.tag)) {
      console.warn(`child of type ${child.tag} in Group ignored`);
    }
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }
}

export default class Slide {
  constructor(props) {
    this.tag = "slide";
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    if (!AVAILABLE_CHILDREN.includes(child.tag)) {
      console.warn(`child of type ${child.tag} in Slide ignored`);
    }
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render(newSlide) {
    let slide;

    if (this.props.master) slide = newSlide(this.props.master);
    else slide = newSlide();

    if (this.props.bkgd) slide.bkgd = this.props.bkgd;
    if (this.props.color) slide.color = this.props.color;

    for (let child of this.children) {
      renderTag(slide, child);
    }
  }
}
