import React from "react";
import { observer } from "mobx-react";
import ImageWrapper from "../components/ImageWrapper.jsx";

import {
  Master,
  Text,
  Slide,
  Placeholder,
} from "../../slides-engine/smartElements.jsx";

import {
  TITLE_SLIDE_TITLE_FONT_SIZE,
  TITLE_SLIDE_SUBTITLE_FONT_SIZE,
} from "../layoutConfig.js";

export const MASTER_NAME = "Title Slide";
export const MASTER_PLACEHOLDERS = {
  title: "title",
  subtitle: "subtitle",
};

export const LAYOUD_DEFAULTS = {
  OFFSET_TITLE_LEFT: "6.82vw",
  OFFSET_TITLE_RIGHT: "6.82vw",
  OFFSET_TITLE_TOP: "40vh",
  TITLE_H: "30vh",
  SUBTITLE_H: "22vh",
};

export default observer(
  ({
    name = MASTER_NAME,
    backgroundColor = "#FFFFFF",

    titleX = LAYOUD_DEFAULTS.OFFSET_TITLE_LEFT,
    titleY = LAYOUD_DEFAULTS.OFFSET_TITLE_TOP,
    titleW = `calc(100vw - ${LAYOUD_DEFAULTS.OFFSET_TITLE_LEFT} - ${LAYOUD_DEFAULTS.OFFSET_TITLE_RIGHT})`,
    titleH = LAYOUD_DEFAULTS.TITLE_H,
    titleFontSize = TITLE_SLIDE_TITLE_FONT_SIZE,
    titleBold = false,
    titleColor = "#000000",
    titleAlign = "left",
    titleValign = "bottom",

    subtitleX = titleX,
    subtitleY = `calc(${titleY} + ${titleH})`,
    subtitleW = titleW,
    subtitleH = LAYOUD_DEFAULTS.SUBTITLE_H,
    subtitleFontSize = TITLE_SLIDE_SUBTITLE_FONT_SIZE,
    subtitleColor = titleColor,
    subtitleAlign = titleAlign,
    subtitleValign = "top",

    logo,
    logoH = subtitleH,
    logoW = `calc(0.6 * ${titleW})`,
    logoX = `calc(50vw - (0.5 * ${logoW}))`,
    logoY = `calc(${titleY} - ${logoH} + 3vh)`,
  }) => {
    const logoEl = (
      <ImageWrapper
        key="logo"
        objectFit="contain"
        objectAlign="center"
        objectValign="bottom"
        x={logoX}
        y={logoY}
        w={logoW}
        h={logoH}
        {...logo}
      />
    );

    const titleEl = (
      <Placeholder
        key={MASTER_PLACEHOLDERS.title}
        name={MASTER_PLACEHOLDERS.title}
        type="title"
        align={titleAlign}
        valign={titleValign}
        fontSize={titleFontSize}
        bold={titleBold}
        color={titleColor}
        x={titleX}
        y={titleY}
        w={titleW}
        h={titleH}
      >
        Click to add title
      </Placeholder>
    );

    const subtitleEl = (
      <Placeholder
        key={MASTER_PLACEHOLDERS.subtitle}
        name={MASTER_PLACEHOLDERS.subtitle}
        type="body"
        align={subtitleAlign}
        valign={subtitleValign}
        fontSize={subtitleFontSize}
        color={subtitleColor}
        x={subtitleX}
        y={subtitleY}
        w={subtitleW}
        h={subtitleH}
      >
        Click to add subtitle
      </Placeholder>
    );

    return (
      <Master title={name} bkgd={backgroundColor}>
        {logoEl}

        {titleEl}
        {subtitleEl}
      </Master>
    );
  }
);

export const Example = observer(({ title, subtitle, slideId }) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    <Text placeholder={MASTER_PLACEHOLDERS.title}>{title}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.subtitle}>{subtitle}</Text>
  </Slide>
));
