import React from "react";

export const CaretLeft = (props) => (
  <svg {...props} viewBox="0 0 96 96">
    <path d="M32.59 78.41a2 2 0 002.83 0l29-29a2 2 0 000-2.83l-29-29a2 2 0 00-2.83 2.83L60.17 48 32.59 75.59a2 2 0 000 2.82z" />
  </svg>
);

export const CaretRight = (props) => (
  <CaretLeft {...props} transform="scale(-1, 1)" />
);
