const AVAILABLE_CHILDREN = [
  "text",
  "image",
  "rect",
  "line",
  "chart",
  "placeholder",
  "shape",
  "slide-number",
  "current-date",
];

export default class Master {
  constructor(props = {}) {
    this.tag = "master";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    if (!AVAILABLE_CHILDREN.includes(child.tag)) {
      console.warn(`child of type ${child.tag} in Master ignored`);
    }
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  get title() {
    return this.props.title || `missing title ${Math.random()}`;
  }

  get bkgd() {
    return this.props.bkgd;
  }

  render() {
    if (!this.props.title) console.warn("missing title for master");

    const master = {
      title: this.props.title,
      objects: [],
    };

    if (this.props.bkgd) master.bkgd = this.props.bkgd;
    if (this.props.color) master.color = this.props.color;

    for (let child of this.children) {
      if (child.tag === "text") {
        master.objects.push({ text: child.render() });
      } else if (child.tag === "rect") {
        master.objects.push({ rect: child.render() });
      } else if (child.tag === "shape") {
        // This is a dirty hack based on how the library works. We need to make
        // it better.
        const [shape, options] = child.render();

        if (shape === "text" || shape === "placeholder") {
          master.objects.push({ [shape]: options });
        } else {
          master.objects.push({
            text: { text: null, options: { shape, ...options } },
          });
        }
      } else if (child.tag === "line") {
        master.objects.push({ line: child.render() });
      } else if (child.tag === "image") {
        master.objects.push({ image: child.render() });
      } else if (child.tag === "placeholder") {
        master.objects.push({ placeholder: child.render() });
      } else if (child.tag === "slide-number") {
        master.objects.push({ "slide-number": { options: child.render() } });
      } else if (child.tag === "current-date") {
        master.objects.push({ "current-date": { options: child.render() } });
      }
    }

    return master;
  }
}
