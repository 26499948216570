import React from "react";

import { Text, Slide } from "../../slides-engine/smartElements.jsx";

import {
  CONTENT_LEVEL6_FONT_SIZE,
  CONTENT_LEVEL7_FONT_SIZE,
  CONTENT_LEVEL8_FONT_SIZE,
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
} from "../layoutConfig.js";
import Grid from "../components/Grid.jsx";

export default ({
  masterName,
  slideId,
  title,

  containerX = INNER_X,
  containerY = INNER_Y,
  containerW = INNER_W,
  containerH = INNER_H,

  columnHeadersWidth = null,
  columnHeaders = [],
  columnHeadersTextSize = CONTENT_LEVEL7_FONT_SIZE,
  columnHeaderFontBold = false,
  columnHeadersColor = "#B3B6B7",
  columnSeparatorColor = null,

  rowHeadersHeight = "4vh", // FIXME: -> layoutConfig
  rowHeaders = [],
  rowHeadersColor = "#000000",
  rowHeadersTextSize = CONTENT_LEVEL6_FONT_SIZE,
  rowSeparatorColor = "#B3B6B7",

  cellTextColor = "#FFFFFF",
  cellFontSize = CONTENT_LEVEL8_FONT_SIZE,
  cellRectRadius = 0.05, // FIXME: -> layoutConfig
  cellPaddingTop = "1vh",
  cellPaddingLeft = cellPaddingTop,

  content = [],
}) => {
  const gridCol1W = columnHeadersWidth
    ? columnHeadersWidth
    : `calc(${containerW} / 5)`;

  const gridEl = (
    <Grid
      x={containerX}
      y={containerY}
      w={containerW}
      h={containerH}
      columnHeaderW={gridCol1W}
      columnHeaders={columnHeaders}
      columnHeaderFontSize={columnHeadersTextSize}
      columnHeaderFontBold={columnHeaderFontBold}
      columnHeaderColor={columnHeadersColor}
      columnSeparatorColor={columnSeparatorColor}
      rowHeaderH={rowHeadersHeight}
      rowHeaders={rowHeaders}
      rowHeaderColor={rowHeadersColor}
      rowHeaderFontSize={rowHeadersTextSize}
      rowSeparatorColor={rowSeparatorColor}
      cellTextColor={cellTextColor}
      cellFontSize={cellFontSize}
      cellRectRadius={cellRectRadius}
      cellPaddingTop={cellPaddingTop}
      cellPaddingLeft={cellPaddingLeft}
      content={content}
    />
  );

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>

      {gridEl}
    </Slide>
  );
};
