import React, { createContext, useContext } from "react";
import SVGDeck from "./SVGelements/Deck";
import SVGMaster from "./SVGelements/Master";
import SVGSlide, { SlideWrapperContext } from "./SVGelements/Slide";
import SVGText from "./SVGelements/Text";
import SVGPlaceholder from "./SVGelements/Placeholder";
import SVGLine from "./SVGelements/Line";
import SVGShape from "./SVGelements/Shape";
import SVGRect from "./SVGelements/Rect";
import SVGSlideNumber from "./SVGelements/SlideNumber";
import SVGImage from "./SVGelements/Image";
import SVGAreaChart from "./SVGelements/AreaChart";

const RenderContext = createContext(false);

export const SVGSlides = ({ children, SlideWrapper }) => (
  <RenderContext.Provider value={true}>
    <SlideWrapperContext.Provider value={SlideWrapper}>
      {children}
    </SlideWrapperContext.Provider>
  </RenderContext.Provider>
);

export const Deck = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGDeck {...props} />;
  return <deck {...props} />;
};

export const Master = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGMaster {...props} />;
  return <master {...props} />;
};

export const Slide = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGSlide {...props} />;
  return <slide {...props} />;
};

export const Text = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGText {...props} />;
  return <text {...props} />;
};

export const Placeholder = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGPlaceholder {...props} />;
  return <placeholder {...props} />;
};

export const Line = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGLine {...props} />;
  return <line {...props} />;
};

export const Shape = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGShape {...props} />;
  return <shape {...props} />;
};

export const Rect = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGRect {...props} />;
  return <rect {...props} />;
};

export const Group = (props) => {
  const isSVG = useContext(RenderContext);

  // A group does not do anything, it is just a set of subelements
  if (isSVG) return <React.Fragment {...props} />;
  return <group {...props} />;
};

export const SlideNumber = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGSlideNumber {...props} />;
  return <slide-number {...props} />;
};

export const Image = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGImage {...props} />;
  return <image {...props} />;
};

export const AreaChart = (props) => {
  const isSVG = useContext(RenderContext);
  if (isSVG) return <SVGAreaChart {...props} />;
  return <area-chart {...props} />;
};
