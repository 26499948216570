import { types } from "mobx-state-tree";

export default types
  .model("Color", {
    value: types.maybe(types.string)
  })
  .actions(self => {
    const change = newColor => {
      self.value = newColor;
    };

    const clear = () => {
      self.value = undefined;
    };

    return { change, clear };
  })
  .views(self => {
    return {
      defaults(val) {
        if (self.value) return self.value;
        return val;
      }
    };
  })
  .preProcessSnapshot(color => ({ value: color }))
  .postProcessSnapshot(({ value }) => value);
