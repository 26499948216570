import React, { useRef } from "react";
import { position, imagePos, DuoToneFilter } from "../svg-helpers";
import { useSlidePlaceholder } from "./Slide";

export default React.memo((props) => {
  const style = {};
  const elemId = useRef(props.elemId || Math.random());

  const placeholder = useSlidePlaceholder(props.placeholder);
  const options = { ...placeholder.props, ...props };

  if (options.opacity) {
    style.opacity = options.opacity;
  }
  const location = position({ ...options });

  let ratio;
  if (!options.objectFit || options.objectFit === "contain") {
    ratio = `${imagePos(options.objectAlign, options.objectVAlign)} meet`;
  }

  if (options.objectFit === "cover") {
    ratio = `${imagePos(options.objectAlign, options.objectVAlign)} slice`;
  }

  if (options.objectFit === "fill") {
    ratio = "none";
  }

  const duotoneId = `duotone-${elemId.current}`;

  return (
    <>
      {options.colorBlend ? (
        <DuoToneFilter id={duotoneId} {...options.colorBlend} />
      ) : null}
      <image
        href={props.data || props.url}
        preserveAspectRatio={ratio}
        {...location}
        style={style}
        filter={options.colorBlend && `url(#${duotoneId})`}
      />
    </>
  );
});
