import React from "react";
import styled from "styled-components/macro";
import Modal from "react-modal";

Modal.setAppElement("#root");

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  border: none;
  background: transparent;
  color: #666;
  cursor: pointer;
  font-size: 1.5em;

  &:hover {
    color: #000;
  }
`;

export const DialogTitle = styled.h2`
  font-size: 1.5em;
  font-weight: 400;
  margin: 0 0 30px;
  text-align: center;
`;

export default ({ children, title, onRequestClose, ...props }) => (
  <Modal
    className="reactive-modal-content"
    overlayClassName="reactive-modal-overlay"
    onRequestClose={onRequestClose}
    {...props}
  >
    {onRequestClose && <CloseButton onClick={onRequestClose}>×</CloseButton>}
    {children}
  </Modal>
);
