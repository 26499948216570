import React from "react";
import { observer } from "mobx-react";
import ImageWrapper from "./ImageWrapper.jsx";
import { useLogo } from "../deckContext.jsx";

import { Text, Line } from "../../slides-engine/smartElements.jsx";

import {
  FOOTER_X,
  FOOTER_Y,
  FOOTER_W,
  FOOTER_H,
  SLIDE_FOOTER_FONT_SIZE,
  cmToVW,
} from "../layoutConfig.js";

import { COLOR_DEFAULT_ACCENT1 } from "../models/colorScheme";

export const FOOTER_PLACEHOLDER_LOGO = "logo";
export const FOOTER_PLACEHOLDER_FOOTER = "footer";
export const FOOTER_PLACEHOLDER_CURRENT_DATE = "current-date";
export const FOOTER_PLACEHOLDER_SLIDE_NUMBER = "slide-number";
// https://c-rex.net/projects/samples/ooxml/e1/Part4/OOXML_P4_DOCX_ST_PlaceholderType_topic_ID0EENHIB.html#topic_ID0EENHIB
export const FOOTER_PLACEHOLDER_TYPE_FOOTER = "ftr";

export default observer(
  ({
    accent1Color = COLOR_DEFAULT_ACCENT1,

    slideNumberW = cmToVW(1), // double-digit slides
    slideNumberY = FOOTER_Y,
    slideNumberH = FOOTER_H,
    slideNumberFontSize = SLIDE_FOOTER_FONT_SIZE,
    slideNumberAlign = "center",
    slideNumberValign = "center",
    slideNumberColor = "#000000",

    slideFooterY = slideNumberY,
    slideFooterW = `calc(0.30 * ${FOOTER_W})`,
    slideFooterH = slideNumberH,
    slideFooterFontSize = SLIDE_FOOTER_FONT_SIZE,
    slideFooterAlign = "left",
    slideFooterValign = "center",
    slideFooterColor = "#000000",

    // NB: temporarily removed until figure out the proper way with placeholders
    // currentDateW = `calc(0.2 * ${FOOTER_W})`,
    // currentDateX = `calc(${INNER_CX} - 0.5 * ${currentDateW})`,
    // currentDateY = FOOTER_Y,
    // currentDateH = FOOTER_H,
    // currentDateFontSize = SLIDE_FOOTER_FONT_SIZE,
    // currentDateAlign = "center",
    // currentDateValign = slideFooterValign,
    // currentDateColor = "#000000",
  }) => {
    const logo = useLogo();
    const coefH = logo && logo.height >= logo.width ? 2 : 1;
    const logoH = `calc(${coefH} * ${FOOTER_H})`;
    const logoY = `calc(${FOOTER_Y} + ${FOOTER_H} - ${logoH})`;
    const logoW =
      logo && logo.data
        ? `calc(${logoH} * (${logo.width} / ${logo.height}))`
        : 0;
    const logoX = `calc(${FOOTER_X} + ${FOOTER_W} - ${logoW})`;

    const logoEl = (
      <ImageWrapper
        key={FOOTER_PLACEHOLDER_LOGO}
        {...logo}
        x={logoX}
        y={logoY}
        w={logoW}
        h={logoH}
      />
    );

    // const currentDateEl = (
    //   <Text
    //     autoFill="datetimeFigureOut"
    //     x={currentDateX}
    //     y={currentDateY}
    //     w={currentDateW}
    //     h={currentDateH}
    //     align={currentDateAlign}
    //     valign={currentDateValign}
    //     fontSize={currentDateFontSize}
    //     color={currentDateColor}
    //   />
    // );

    const slideNumberHeaderWPerc = 0.5;
    const slideNumberHeaderWidth = 1.25;
    const slideNumberHeaderX = FOOTER_X;
    const slideNumberHeaderW = `calc(${slideNumberHeaderWPerc} * ${slideNumberW})`;
    const slideNumberHeaderY = `calc(${slideNumberY} + 0.25vh)`;
    const slideNumberHeaderEl = (
      <Line
        x={slideNumberHeaderX}
        y={slideNumberHeaderY}
        w={slideNumberHeaderW}
        h={0}
        color={accent1Color}
        width={slideNumberHeaderWidth}
      />
    );

    const slideNumberX = `calc(${slideNumberHeaderX} - 0.5 * (1 - ${slideNumberHeaderWPerc}) * ${slideNumberW})`;
    const slideNumberEl = (
      <Text
        autoFill="slidenum"
        x={slideNumberX}
        y={slideNumberY}
        w={slideNumberW}
        h={slideNumberH}
        align={slideNumberAlign}
        valign={slideNumberValign}
        fontSize={slideNumberFontSize}
        color={slideNumberColor}
      />
    );

    const slideFooterX = `calc(${slideNumberX} + ${slideNumberW} - 0.5vw)`;
    const slideFooterEl = (
      <Text
        type={FOOTER_PLACEHOLDER_TYPE_FOOTER}
        name={FOOTER_PLACEHOLDER_FOOTER}
        x={slideFooterX}
        y={slideFooterY}
        w={slideFooterW}
        h={slideFooterH}
        align={slideFooterAlign}
        valign={slideFooterValign}
        fontSize={slideFooterFontSize}
        color={slideFooterColor}
      >
        Confidential
      </Text>
    );

    return (
      <>
        {logoEl}
        {slideFooterEl}
        {slideNumberEl}
        {slideNumberHeaderEl}
      </>
    );
  }
);
