import { useRef, useEffect, useCallback } from "react";

/* useRefFcn
 * This simple helper hook allows us to always use callback or event like
 * functions within effects without having to worry about when the function is
 * refreshed.
 *
 * Only the latest version of the function will be called and the reference to
 * that function will also keep being the same with not change with refreshes.
 */

export default fcn => {
  const savedFcn = useRef(fcn);

  useEffect(() => {
    savedFcn.current = fcn;
  }, [fcn]);

  return useCallback((...args) => {
    if (savedFcn.current) return savedFcn.current(...args);
    return null;
  }, []);
};
