import React from "react";

import {
  Master,
  Text,
  Slide,
  Placeholder,
} from "../../slides-engine/smartElements.jsx";

import HeaderPlaceholder, {
  TITLE_PLACEHOLDER_NAME,
} from "../components/HeaderPlaceholder";
import FooterPlaceholder from "../components/FooterPlaceholder";
import {
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  GUTTER_SIZE,
  CONTENT_SLIDE_BODY_FONT_SIZE,
  TITLE_SLIDE_SUBTITLE_FONT_SIZE,
  LIST_INDENT,
  BULLET_TYPE_DEFAULT,
  BULLET_CODE_DEFUALT,
} from "../layoutConfig.js";

export const MASTER_NAME = "Comparison";
export const MASTER_PLACEHOLDERS = {
  title: TITLE_PLACEHOLDER_NAME,
  headerLeft: "header-left",
  contentLeft: "content-left",
  headerRight: "header-right",
  contentRight: "content-right",
};

export default ({
  name = MASTER_NAME,
  backgroundColor = "FFFFFF",

  titleColor = "#000000",

  accent1Color = titleColor,

  headerH = "12.02vh", // 2.29cm / 19.05cm

  contentX = INNER_X,
  contentY = INNER_Y,
  contentW = INNER_W,
  contentH = INNER_H,
  contentColumnsOffsetW = GUTTER_SIZE,

  bulletType = BULLET_TYPE_DEFAULT,
  bulletCode = BULLET_CODE_DEFUALT,
  bulletIndent = LIST_INDENT,
  bulletColor = titleColor,

  textColor = "#000000",
}) => {
  const headerLeftX = contentX;
  const headerLeftY = contentY;
  const headerLeftW = `calc((${contentW} / 2) - (${contentColumnsOffsetW} / 2))`;
  const headerLeftH = headerH;

  const headerRightX = `calc(${headerLeftX} + ${headerLeftW} + ${contentColumnsOffsetW})`;
  const headerRightY = headerLeftY;
  const headerRightW = headerLeftW;
  const headerRightH = headerLeftH;

  const contentLeftX = headerLeftX;
  const contentLeftY = `calc(${headerLeftY} + ${headerLeftH})`;
  const contentLeftW = headerLeftW;
  const contentLeftH = `calc(${contentH} - ${headerLeftH})`;

  const contentRightX = headerRightX;
  const contentRightY = contentLeftY;
  const contentRightW = contentLeftW;
  const contentRightH = contentLeftH;

  return (
    <Master title={name} bkgd={backgroundColor}>
      <HeaderPlaceholder titleColor={titleColor} />

      <Placeholder
        name={MASTER_PLACEHOLDERS.headerLeft}
        type="body"
        align="left"
        valign="bottom"
        color={textColor}
        bold
        fontSize={TITLE_SLIDE_SUBTITLE_FONT_SIZE}
        x={headerLeftX}
        y={headerLeftY}
        w={headerLeftW}
        h={headerLeftH}
      >
        Click to add text
      </Placeholder>

      <Placeholder
        name={MASTER_PLACEHOLDERS.contentLeft}
        type="obj"
        align="left"
        valign="top"
        color={textColor}
        fontSize={CONTENT_SLIDE_BODY_FONT_SIZE}
        x={contentLeftX}
        y={contentLeftY}
        w={contentLeftW}
        h={contentLeftH}
        bullet={{
          type: bulletType,
          code: bulletCode,
          indent: bulletIndent,
          color: bulletColor,
        }}
      >
        Click to add text
      </Placeholder>

      <Placeholder
        name={MASTER_PLACEHOLDERS.headerRight}
        type="body"
        align="left"
        valign="bottom"
        color={textColor}
        bold
        fontSize={TITLE_SLIDE_SUBTITLE_FONT_SIZE}
        x={headerRightX}
        y={headerRightY}
        w={headerRightW}
        h={headerRightH}
      >
        Click to add text
      </Placeholder>

      <Placeholder
        name={MASTER_PLACEHOLDERS.contentRight}
        type="obj"
        align="left"
        valign="top"
        color={textColor}
        fontSize={CONTENT_SLIDE_BODY_FONT_SIZE}
        x={contentRightX}
        y={contentRightY}
        w={contentRightW}
        h={contentRightH}
        bullet={{
          type: bulletType,
          code: bulletCode,
          indent: bulletIndent,
          color: bulletColor,
        }}
      >
        Click to add text
      </Placeholder>

      <FooterPlaceholder accent1Color={accent1Color} />
    </Master>
  );
};

export const Example = ({
  title,
  slideId,
  headerLeft,
  contentLeft,
  headerRight,
  contentRight,
}) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    <Text placeholder={MASTER_PLACEHOLDERS.title}>{title}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.headerLeft}>{headerLeft}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.contentLeft}>{contentLeft}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.headerRight}>{headerRight}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.contentRight}>{contentRight}</Text>
  </Slide>
);
