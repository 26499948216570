import { CaretLeft, CaretRight } from "./CaretIcons.jsx";
import React, { useContext, useCallback } from "react";
import styled, { css } from "styled-components/macro";
import { SVGSlides } from "../slides-engine/smartElements.jsx";

import { observer } from "mobx-react";
import Presentation, { SingleSlide } from "../presentation/Presentation.jsx";

const grid = css`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => (props.small ? "14%" : "20%")}, 1fr)
  );
  grid-gap: 2vh 2%;
`;

const Slides = styled.div`
  width: 100%;
  padding-bottom: 30px;
  ${grid}
`;

const SlideBorder = styled.div`
  border: 1px #cccccc solid;
  display: flex;
  ${(props) =>
    props.selected && "outline: solid lightsteelblue 3px; outline-offset: 5px;"}
  cursor: pointer;
  user-select: none;
`;

const CurrentSlideBorder = styled(SlideBorder)`
  cursor: default;
  min-width: 200px;
  flex: 1 1 100%;
`;

const SlideViewerWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  margin-bottom: 40px;
  width: 100%;

  @media screen and (max-width: 390px) {
    display: none;
  }
`;

const SlidesPreviewWrapper = styled.div`
  background-color: #eee;
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  overflow: auto;
  height: calc(100vh - 120px);
  width: 100%;
`;

const SlideSelectButton = styled.button`
  background-color: inherit;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  opacity: 0.6;
  padding: 0.2em 1em;

  &:hover {
    background-color: inherit;
    color: inherit;
    opacity: 1;
  }
`;

const StateContext = React.createContext(null);

const SlideWrapper = observer(({ slideId, children }) => {
  const state = useContext(StateContext);
  const select = useCallback(() => {
    state.selectSlide(slideId);
  }, [slideId, state]);
  return (
    <SlideBorder
      selected={state.selectedSlide && slideId === state.selectedSlide.id}
      onClick={select}
    >
      {children}
    </SlideBorder>
  );
});

export default observer(({ state }) => {
  return (
    <SlidesPreviewWrapper>
      {state.selectedSlide && (
        <SlideViewerWrapper>
          <SlideSelectButton onClick={state.selectPrevSlide}>
            <CaretRight height="2em" />
          </SlideSelectButton>
          <CurrentSlideBorder>
            <SVGSlides>
              <SingleSlide
                slidesDeck={state.deck}
                slide={state.selectedSlide}
                slideMasters={state.slideMasters}
                defaultImages={state.images}
              />
            </SVGSlides>
          </CurrentSlideBorder>
          <SlideSelectButton onClick={state.selectNextSlide}>
            <CaretLeft height="2em" />
          </SlideSelectButton>
        </SlideViewerWrapper>
      )}
      <StateContext.Provider value={state}>
        <Slides small={!!state.selectedSlide}>
          <SVGSlides SlideWrapper={SlideWrapper}>
            <Presentation
              slidesDeck={state.deck}
              slides={state.slides}
              slideMasters={state.slideMasters}
              defaultImages={state.images}
            />
          </SVGSlides>
        </Slides>
      </StateContext.Provider>
    </SlidesPreviewWrapper>
  );
});
