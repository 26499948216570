import React from "react";

import {
  Master,
  Image,
  Slide,
  Placeholder,
} from "../../slides-engine/smartElements.jsx";

export const MASTER_NAME = "Full Screen Image";
export const MASTER_PLACEHOLDERS = {
  image: "image",
};

export default () => {
  return (
    <Master title={MASTER_NAME}>
      <Placeholder
        name={MASTER_PLACEHOLDERS.image}
        type="pic"
        x="0%"
        y="0%"
        w="100%"
        h="100%"
        objectFit="cover"
      />
    </Master>
  );
};

export const Example = ({ image, slideId }) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    {image && <Image placeholder={MASTER_PLACEHOLDERS.image} {...image} />}
  </Slide>
);
