import React from "react";
import { observer } from "mobx-react";

import {
  Master,
  Text,
  Slide,
  Placeholder,
} from "../../slides-engine/smartElements.jsx";

import FooterPlaceholder from "../components/FooterPlaceholder";
import ImageWrapper from "../components/ImageWrapper.jsx";

import {
  OFFSET_TOP,
  OFFSET_RIGHT,
  OFFSET_LEFT,
  TITLE_HEIGHT,
  CONTENT_LEVEL0_FONT_SIZE,
  CONTENT_LEVEL6_FONT_SIZE,
  LIST_INDENT,
  BULLET_TYPE_DEFAULT,
  BULLET_CODE_DEFUALT,
} from "../layoutConfig.js";

export const MASTER_NAME = "Content with Caption";
export const MASTER_NAME_PICTURE = "Picture with Caption";
export const MASTER_PLACEHOLDERS = {
  title: "title",
  content: "content",
  caption: "caption",
};

export default observer(
  ({
    name = MASTER_NAME,
    backgroundColor = "FFFFFF",

    contentType = "obj",

    logoW = "15vw",
    logoOffsetY = "1vw",

    titleColor = "#000000",

    accent1Color = titleColor,

    // completely custom layout unfortunately
    titleX = OFFSET_LEFT,
    titleY = "6.66vh", // 1.27cm / 19.05cm - https://www.youtube.com/watch?v=LfrENoTJdo4
    titleW = "32.24vw", // 10.92cm /  33.87cm
    titleH = "23.25vh", // 4.45cm / 19.05cm

    captionX = titleX,
    captionY = `calc(${titleY} + ${titleH})`,
    captionW = titleW,
    captionH = `55.59vh`, // 10.59cm / 19.05cm

    contentX = "42.52vw", // 14.4cm / 33.87cm
    contentY = "14.38vh", // 2.74cm / 19.05cm
    contentW = "50.63vw", // 17.15cm / 33.87cm
    contentH = "71.09vh", // 13.54cm / 19.05cm

    textColor = "#000000",
    titleFontSize = CONTENT_LEVEL0_FONT_SIZE,
    captionFontSize = CONTENT_LEVEL6_FONT_SIZE,
    contentFontSize = CONTENT_LEVEL0_FONT_SIZE,

    bulletType = BULLET_TYPE_DEFAULT,
    bulletCode = BULLET_CODE_DEFUALT,
    bulletIndent = LIST_INDENT,
    bulletColor = titleColor,

    logo = null, //useLogo(),
    logoY = `calc(${OFFSET_TOP} + ${logoOffsetY})`,
    logoX = `calc(100% - ${OFFSET_RIGHT} - ${logoW})`,
    logoH = `calc(${TITLE_HEIGHT} - 2 * ${logoOffsetY})`,
  }) => {
    const contentPlaceholder =
      contentType === "pic" ? "Click to add image" : "Click to add text";

    return (
      <Master title={name} bkgd={backgroundColor}>
        <ImageWrapper
          {...logo}
          objectFit="contain"
          objectAlign="right"
          x={logoX}
          y={logoY}
          w={logoW}
          h={logoH}
        />

        <Placeholder
          name={MASTER_PLACEHOLDERS.title}
          type="title"
          color={titleColor}
          align="left"
          valign="bottom"
          fontSize={titleFontSize}
          x={titleX}
          y={titleY}
          w={titleW}
          h={titleH}
        >
          Click to add title
        </Placeholder>

        <Placeholder
          name={MASTER_PLACEHOLDERS.caption}
          type="body"
          align="left"
          valign="top"
          color={textColor}
          fontSize={captionFontSize}
          x={captionX}
          y={captionY}
          w={captionW}
          h={captionH}
        >
          Click to add text
        </Placeholder>

        <Placeholder
          name={MASTER_PLACEHOLDERS.content}
          type={contentType}
          align="left"
          valign="top"
          color={textColor}
          fontSize={contentFontSize}
          x={contentX}
          y={contentY}
          w={contentW}
          h={contentH}
          bullet={{
            type: bulletType,
            code: bulletCode,
            indent: bulletIndent,
            color: bulletColor,
          }}
        >
          {contentPlaceholder}
        </Placeholder>

        <FooterPlaceholder accent1Color={accent1Color} />
      </Master>
    );
  }
);

export const Example = observer(
  ({ slideId, master = MASTER_NAME, title, content, caption }) => (
    <Slide slideId={slideId} master={master}>
      <Text placeholder={MASTER_PLACEHOLDERS.title}>{title}</Text>
      <Text placeholder={MASTER_PLACEHOLDERS.content}>{content}</Text>
      <Text placeholder={MASTER_PLACEHOLDERS.caption}>{caption}</Text>
    </Slide>
  )
);
