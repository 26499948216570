import {
  Root,
  Deck,
  Master,
  Slide,
  Group,
  Rect,
  Placeholder,
  Text,
  Line,
  Shape,
  Image,
  LineChart,
  AreaChart,
  SlideNumber,
  CurrentDate
} from "./elements";

function createElement(type, props) {
  const COMPONENTS = {
    root: () => new Root(),

    deck: () => new Deck(props),
    slide: () => new Slide(props),
    master: () => new Master(props),
    rect: () => new Rect(props),
    placeholder: () => new Placeholder(props),
    text: () => new Text(props),
    line: () => new Line(props),
    shape: () => new Shape(props),
    image: () => new Image(props),
    group: () => new Group(props),
    "line-chart": () => new LineChart(props),
    "area-chart": () => new AreaChart(props),
    "slide-number": () => new SlideNumber(props),
    "current-date": () => new CurrentDate(props),

    default: undefined
  };

  return COMPONENTS[type]() || COMPONENTS.default;
}

export default createElement;
