import renderer from "./renderer";
import createElement from "./createElement";

export default (element, title) => {
  const container = createElement("root");
  const node = renderer.createContainer(container, false, false);
  renderer.updateContainer(element, node, null);

  container.render().writeFile(title || "bulletpoints.studio_template.pptx");
};

export const renderBlob = async (element) => {
  const container = createElement("root");
  const node = renderer.createContainer(container, false, false);
  renderer.updateContainer(element, node, null);

  return container.render().write("blob");
};
