import React from "react";

import {
  Master,
  Text,
  Slide,
  Placeholder,
} from "../../slides-engine/smartElements.jsx";

import HeaderPlaceholder, {
  TITLE_PLACEHOLDER_NAME,
} from "../components/HeaderPlaceholder";
import FooterPlaceholder from "../components/FooterPlaceholder";
import {
  OFFSET_LEFT,
  OFFSET_RIGHT,
  INNER_Y,
  INNER_H,
} from "../layoutConfig.js";

export const MASTER_NAME = "Big Statement";
export const MASTER_PLACEHOLDERS = {
  body: "body",
};

export default ({
  name = MASTER_NAME,
  backgroundColor = "#FFFFFF",

  titleColor = "#000000",

  accent1Color = titleColor,

  containerX = OFFSET_LEFT,
  containerY = INNER_Y,
  containerW = `calc(100vw - ${OFFSET_LEFT} - ${OFFSET_RIGHT})`,
  containerH = INNER_H,

  textColor = "#000000",
  textFontSize = 54,
  textFontBold = false,
}) => {
  const bodyPlaceholder = (
    <Placeholder
      key="body"
      name={MASTER_PLACEHOLDERS.body}
      type="body"
      x={containerX}
      y={containerY}
      w={containerW}
      h={containerH}
      color={textColor}
      fontSize={textFontSize}
      bold={textFontBold}
      align="center"
      valign="center"
    >
      Click to add text
    </Placeholder>
  );

  return (
    <Master title={name} bkgd={backgroundColor}>
      <HeaderPlaceholder titleColor={titleColor} />

      {bodyPlaceholder}

      <FooterPlaceholder accent1Color={accent1Color} />
    </Master>
  );
};

export const Example = ({ slideId, title, body }) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    <Text placeholder={TITLE_PLACEHOLDER_NAME}>{title}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.body}>{body}</Text>
  </Slide>
);
