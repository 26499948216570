import React from "react";
import { Group, Text, Line } from "../../slides-engine/smartElements.jsx";

export default ({
  topLeftX = 0,
  topLeftY = 0,
  width = "10vh",
  align = "left",
  valign = "top",
  lineHeight = "10vh",
  lineColor = "000000",
  textColor = "000000",
  textFontSize = 10,
  textHeight = "10vh",
  text = "",
}) => {
  const lineTopLeftX =
    align === "left" ? topLeftX : `calc(${topLeftX} + ${width})`;

  const textOffsetH = align === "left" ? "-0.5vh" : "0.5vh";
  const textTopLeftX = `calc(${topLeftX} + ${textOffsetH})`;

  const textOffsetV = "2.5vh";
  const textOffsetAlignV = valign === "top" ? "0" : lineHeight;
  const textTopLeftY = `calc(${topLeftY} - ${textOffsetV} + ${textOffsetAlignV})`;

  return (
    <Group>
      <Line
        x={lineTopLeftX}
        y={topLeftY}
        h={lineHeight}
        w={0}
        color={lineColor}
        head={valign === "top" ? "oval" : "none"}
        tail={valign === "bottom" ? "oval" : "none"}
      />
      <Text
        type="body"
        color={textColor}
        fontSize={textFontSize}
        align={align}
        valign="top"
        x={textTopLeftX}
        y={textTopLeftY}
        h={textHeight}
        w={width}
      >
        {text}
      </Text>
    </Group>
  );
};
