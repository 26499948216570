import React, { createContext, useContext, useState, useCallback } from "react";

const DeckContext = createContext({
  registerMaster: () => null,
  unRegisterMaster: () => null,
  masters: new Map(),
});

export const useDeck = () => useContext(DeckContext);

export const useMasterInfo = (masterId) => {
  const { masters, registerMaster, unRegisterMaster, ...props } = useContext(
    DeckContext
  );
  const master = masters.get(masterId);
  if (!masterId || !master) {
    return {
      ...props,
      components: null,
      placeholders: new Map(),
    };
  }

  return {
    ...props,
    placeholders: master.placeholders,
    components: master.components,
  };
};

export default React.memo(({ children, ...props }) => {
  const [masters, setMasters] = useState(new Map());
  const registerMaster = useCallback((masterId, master) => {
    setMasters((p) => {
      if (p.has(masterId)) return p;
      p.set(masterId, master);
      return new Map(p);
    });
  }, []);

  const unRegisterMaster = useCallback((masterId) => {
    setMasters((p) => {
      if (!p.has(masterId)) return p;
      p.delete(masterId);
      return new Map(p);
    });
  }, []);

  return (
    <DeckContext.Provider
      value={{
        masters,
        registerMaster,
        unRegisterMaster,
        ...props,
      }}
    >
      {children}
    </DeckContext.Provider>
  );
});
