export default class ImageClass {
  constructor(props = {}) {
    this.tag = "image";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    console.warn(`children in image ignored`);
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    const {
      x,
      y,
      w,
      h,
      data,
      height,
      width,
      hyperlink,
      opacity,
      rounding,
      placeholder,
      objectFit,
      objectAlign,
      objectVAlign,
      colorBlend,
    } = this.props;

    return {
      x,
      y,
      w,
      h,
      data,
      hyperlink,
      rounding,
      opacity,
      placeholder,
      // We default to contain if there is no placeholder defined
      objectFit: objectFit || (placeholder ? objectFit : "contain"),
      objectAlign,
      objectVAlign,
      imageFormat: { width, height },
      colorBlend,
    };
  }
}
