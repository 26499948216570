export default class CurrentDate {
  constructor(props = {}) {
    this.tag = "current-date";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    console.warn(`children in current-date ignored`);
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    const {
      x,
      y,
      h,
      w,
      dateFormat,
      fontFace,
      fontSize,
      charSpacing,
      color,
      bold,
      italic,
      strike,
      underline,
      subscript,
      superscript,
      outline,
    } = this.props;
    return {
      x,
      y,
      h,
      w,
      dateFormat: dateFormat && parseInt(dateFormat),
      fontFace,
      fontSize,
      charSpacing,
      color,
      bold,
      italic,
      strike,
      underline,
      subscript,
      superscript,
      outline,
    };
  }
}
