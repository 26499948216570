import { cleanProps } from "../utils";

export default class Placeholder {
  constructor(props = {}) {
    this.tag = "placeholder";
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  get name() {
    return this.props.name;
  }

  get position() {
    return {
      x: this.props.x,
      y: this.props.y,
      w: this.props.w,
      h: this.props.h,
    };
  }

  render() {
    const {
      type = "body",
      name,
      x,
      y,
      h,
      w,
      fill,
      bold,
      align,
      valign,
      autoFit,
      breakLine,
      bullet,
      charSpacing,
      color,
      fontFace,
      fontSize,
      hyperlink,
      indentLevel = 0,
      inset,
      isTextBox,
      italic,
      lang,
      line,
      lineSpacing,
      margin,
      outline,
      paraSpaceAfter,
      paraSpaceBefore,
      rectRadius,
      rotate,
      rtlMode,
      shadow,
      shrinkText,
      strike,
      subscript,
      superscript,
      underline,
      vert,
      objectFit,
      opacity,
      colorBlend,
      autoFill,
    } = this.props;

    let text = this.children.join("");
    if (autoFill) {
      text = [{ text, options: {}, fieldType: autoFill }];
    }

    return {
      text,
      options: cleanProps({
        type,
        name,
        x,
        y,
        h,
        w,
        fill,
        bold,
        align,
        valign,
        autoFit,
        breakLine,
        bullet,
        charSpacing,
        color,
        fontFace,
        fontSize,
        hyperlink,
        indentLevel,
        inset,
        isTextBox,
        italic,
        lang,
        line,
        lineSpacing,
        margin,
        outline,
        paraSpaceAfter,
        paraSpaceBefore,
        rectRadius,
        rotate,
        rtlMode,
        shadow,
        shrinkText,
        strike,
        subscript,
        superscript,
        underline,
        vert,
        objectFit,
        opacity,
        colorBlend,
      }),
    };
  }
}
