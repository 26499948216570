import React from "react";
import PointCircle from "../components/PointCircle.jsx";
import TextAnnotation from "../components/TextAnnotation.jsx";

import {
  Text,
  Slide,
  Rect,
  Group,
  Shape,
} from "../../slides-engine/smartElements.jsx";

import {
  CONTENT_LEVEL8_FONT_SIZE,
  CONTENT_LEVEL9_FONT_SIZE,
} from "../layoutConfig.js";

export default ({
  masterName,
  slideId,

  timelineColor = "000000",
  chevronColor = "000000",
  circleColors = ["000000", "000000", "000000", "000000", "000000"],

  circleTextSize = CONTENT_LEVEL8_FONT_SIZE,
  circleTextColor = "FFFFFF",

  phaseTextColor = "000000",

  subtitleTextSize = CONTENT_LEVEL9_FONT_SIZE,
  subtitleTextColor = "000000",

  annotationLineColor = "000000",
  annotationFontSize = CONTENT_LEVEL9_FONT_SIZE,

  title = "",
}) => {
  const timelineY = "40%";
  const timelineH = "15%";
  const circleWH = "15vh";

  const circle1TopLeftX = "7%";
  const circle2TopLeftX = "26%";
  const circle3TopLeftX = "45%";
  const circle4TopLeftX = "64%";
  const circle5TopLeftX = "83%";

  const step1TopLeftX = `calc(${circle1TopLeftX} + ${circleWH})`;
  const step1Width = `calc(${circle2TopLeftX} - ${circle1TopLeftX} - ${circleWH})`;
  const step2TopLeftX = `calc(${circle2TopLeftX} + ${circleWH})`;
  const step2Width = `calc(${circle3TopLeftX} - ${circle2TopLeftX} - ${circleWH})`;
  const step3TopLeftX = `calc(${circle3TopLeftX} + ${circleWH})`;
  const step3Width = `calc(${circle4TopLeftX} - ${circle3TopLeftX} - ${circleWH})`;
  const step4TopLeftX = `calc(${circle4TopLeftX} + ${circleWH})`;
  const step4Width = `calc(${circle5TopLeftX} - ${circle4TopLeftX} - ${circleWH})`;

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>
      <Group>
        <Rect
          fill={timelineColor}
          x="0%"
          y={timelineY}
          w="100%"
          h={timelineH}
        />
        {/* left arrow */}
        <Shape
          shape="chevron"
          fill={chevronColor}
          x="-6vw"
          y={timelineY}
          w="12vw"
          h={timelineH}
          radius="0.35"
        />
        {/* right arrow */}
        <Shape
          shape="chevron"
          fill={chevronColor}
          x="95vw"
          y={timelineY}
          w="12vw"
          h={timelineH}
          radius="0.35"
        />
      </Group>

      <Group>
        <TextAnnotation
          topLeftX="11%"
          topLeftY="30%"
          width="15vw"
          text={`Long-term vision\nMarket trends\nHot button topics`}
          lineColor={annotationLineColor}
          textColor={subtitleTextColor}
          textFontSize={annotationFontSize}
        />
        <PointCircle
          topLeftX={circle1TopLeftX}
          topLeftY={timelineY}
          circleWidth={circleWH}
          circleHeight={circleWH}
          circleColor={circleColors[0]}
          circleText="Kickoff"
          circleTextSize={circleTextSize}
          circleTextColor={circleTextColor}
          subtitleText="DAY 1"
          subtitleTextSize={subtitleTextSize}
          subtitleTextColor={subtitleTextColor}
        />
        <TextAnnotation
          topLeftX="11%"
          topLeftY="60%"
          width="20vw"
          lineHeight="20vh"
          valign="bottom"
          text="Subject to budget approval"
          lineColor={annotationLineColor}
          textColor={subtitleTextColor}
          textFontSize={annotationFontSize}
        />
      </Group>
      <Group>
        <Text
          type="body"
          color={phaseTextColor}
          fontSize={circleTextSize}
          align="center"
          valign="center"
          x={step1TopLeftX}
          y={timelineY}
          h={circleWH}
          w={step1Width}
        >
          Dev
        </Text>
        <TextAnnotation
          topLeftX="21%"
          topLeftY="55%"
          width="15vw"
          lineHeight="5vh"
          valign="bottom"
          text="Nearshore"
          lineColor={annotationLineColor}
          textColor={subtitleTextColor}
          textFontSize={annotationFontSize}
        />
      </Group>

      <PointCircle
        topLeftX={circle2TopLeftX}
        topLeftY={timelineY}
        circleWidth={circleWH}
        circleHeight={circleWH}
        circleColor={circleColors[1]}
        circleText="Feature Freeze"
        circleTextSize={circleTextSize}
        circleTextColor={circleTextColor}
        subtitleText=""
        subtitleTextSize={subtitleTextSize}
        subtitleTextColor={subtitleTextColor}
      />

      <Group>
        <Text
          type="body"
          color={phaseTextColor}
          fontSize={circleTextSize}
          align="center"
          valign="center"
          x={step2TopLeftX}
          y={timelineY}
          h={circleWH}
          w={step2Width}
        >
          QA
        </Text>
        <TextAnnotation
          topLeftX="30%"
          topLeftY="55%"
          width="10vw"
          lineHeight="10vh"
          align="right"
          valign="bottom"
          text="Executed by 3rd-party"
          lineColor={annotationLineColor}
          textColor={subtitleTextColor}
          textFontSize={annotationFontSize}
        />
      </Group>
      <Group>
        <PointCircle
          topLeftX={circle3TopLeftX}
          topLeftY={timelineY}
          circleWidth={circleWH}
          circleHeight={circleWH}
          circleColor={circleColors[2]}
          circleText="Deploy"
          circleTextSize={circleTextSize}
          circleTextColor={circleTextColor}
          subtitleText="1st week"
          subtitleTextSize={subtitleTextSize}
          subtitleTextColor={subtitleTextColor}
        />
        <TextAnnotation
          topLeftX="49%"
          topLeftY="60%"
          width="20vw"
          lineHeight="10vh"
          align="left"
          valign="bottom"
          text={`Internal announcement\nPre-sales brief\nDocumentation`}
          lineColor={annotationLineColor}
          textColor={subtitleTextColor}
          textFontSize={annotationFontSize}
        />
      </Group>
      <Group>
        <TextAnnotation
          topLeftX="59%"
          topLeftY="30%"
          width="15vw"
          text={`Webinars\nInternal blog post`}
          lineColor={annotationLineColor}
          textColor={subtitleTextColor}
          textFontSize={annotationFontSize}
        />
        <Text
          type="body"
          color={phaseTextColor}
          fontSize={circleTextSize}
          align="center"
          valign="center"
          x={step3TopLeftX}
          y={timelineY}
          h={circleWH}
          w={step3Width}
        >
          Pilot
        </Text>
      </Group>
      <Group>
        <PointCircle
          topLeftX={circle4TopLeftX}
          topLeftY={timelineY}
          circleWidth={circleWH}
          circleHeight={circleWH}
          circleColor={circleColors[3]}
          circleText="Announce"
          circleTextSize={circleTextSize}
          circleTextColor={circleTextColor}
          subtitleText="T - 2 weeks"
          subtitleTextSize={subtitleTextSize}
          subtitleTextColor={subtitleTextColor}
        />
        <TextAnnotation
          topLeftX="68%"
          topLeftY="62%"
          width="20vw"
          lineHeight="20vh"
          align="left"
          valign="bottom"
          text={`Knowledge base update\nPublic announcement sign-off`}
          lineColor={annotationLineColor}
          textColor={subtitleTextColor}
          textFontSize={annotationFontSize}
        />
      </Group>

      <Text
        type="body"
        color={phaseTextColor}
        fontSize={circleTextSize}
        align="center"
        valign="center"
        x={step4TopLeftX}
        y={timelineY}
        h={circleWH}
        w={step4Width}
      >
        QA
      </Text>

      <Group>
        <PointCircle
          topLeftX={circle5TopLeftX}
          topLeftY={timelineY}
          circleWidth={circleWH}
          circleHeight={circleWH}
          circleColor={circleColors[4]}
          circleText="GA"
          circleTextSize={circleTextSize}
          circleTextColor={circleTextColor}
          subtitleText="Last Wed of the month"
          subtitleTextSize={subtitleTextSize}
          subtitleTextColor={subtitleTextColor}
        />
        <TextAnnotation
          topLeftX="87%"
          topLeftY="68%"
          width="20vw"
          lineHeight="10vh"
          align="left"
          valign="bottom"
          text={`Blog post\nNewsletter`}
          lineColor={annotationLineColor}
          textColor={subtitleTextColor}
          textFontSize={annotationFontSize}
        />
      </Group>
    </Slide>
  );
};
