import React, { useCallback, useEffect, useState } from "react";
import { readAsImage, rgb2hex } from "../utils";

import ColorThief from "colorthief";
import { observer } from "mobx-react";
import styled from "styled-components/macro";
import { useDropzone } from "react-dropzone";
import useRefFcn from "../hooks/useRefFcn";

const ClickableZone = styled.div`
  height: 150px;
  /* width: 240px; */
  background-color: #eeeeee;
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px dashed #ccc;
  cursor: pointer;
`;

export const Logo = styled.img`
  height: 150px;
  width: 240px;
  border: 1px solid #999;
  object-fit: contain;
`;

const usePalette = (imageSrc, onPalette) => {
  const [palette, setPalette] = useState(null);
  useEffect(() => {
    if (!imageSrc) return;

    const img = document.createElement("img");
    img.src = imageSrc;
    const onLoad = () => {
      const colorThief = new ColorThief();
      const thiefPalette = colorThief.getPalette(img, 10);

      const newPalette = thiefPalette.map((color) => rgb2hex(color));

      setPalette(newPalette);
      onPalette(newPalette);
    };

    // Make sure image is finished loading
    if (img.complete) {
      setTimeout(onLoad);
    } else {
      img.addEventListener("load", onLoad);
    }
  }, [imageSrc, onPalette]);

  return palette;
};

const LogoViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  & > :not(:first-child) {
    margin-top: 10px;
  }
  width: auto;
`;

const DropzoneText = ({ isDragActive }) => {
  return isDragActive ? (
    <p>Drop the logo here ...</p>
  ) : (
    <p>Drop a logo here, or click to select a logo file</p>
  );
};

export default observer(
  ({
    onFile: onFileInput,
    onPalette: onPaletteInput,
    onImgSrc: onImgSrcInput,
    imgSrc: imgSrcInput,
  }) => {
    const onFile = useRefFcn(onFileInput);
    const onPalette = useRefFcn(onPaletteInput);
    const onImgSrc = useRefFcn(onImgSrcInput);

    const [logoFile, setLogoFile] = useState(null);
    const [logoSrc, setLogoSrc] = useState(null);
    const [imgSrc, setImgSrc] = useState(imgSrcInput);

    useEffect(() => {
      if (imgSrcInput === imgSrc) return;

      setImgSrc(imgSrcInput);
      setLogoSrc(null);
    }, [imgSrcInput, imgSrc]);

    const onDrop = useCallback(
      (files) => {
        setLogoFile(files[0]);
        onFile(files[0]);
      },
      [onFile]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    useEffect(() => {
      if (!logoFile) return;
      readAsImage(logoFile).then((src) => {
        setLogoSrc(src);
        onImgSrc(src);
      });
    }, [logoFile, onImgSrc]);

    usePalette(logoSrc, onPalette);

    return (
      <LogoViewWrapper>
        <div css="display: flex;" {...getRootProps()}>
          <input {...getInputProps()} accept="image/png, image/jpeg" />
          {logoSrc || imgSrc ? (
            <Logo src={logoSrc || imgSrc} />
          ) : (
            <ClickableZone>
              <DropzoneText isDragActive={isDragActive} />
            </ClickableZone>
          )}
        </div>
      </LogoViewWrapper>
    );
  }
);
