import { cleanProps } from "../utils";

export default class Text {
  constructor(props) {
    this.tag = "text";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    const {
      placeholder,
      x,
      y,
      h,
      w,
      fill,
      bold,
      align,
      valign,
      autoFit,
      breakLine,
      bullet,
      charSpacing,
      color,
      fontFace,
      fontSize,
      hyperlink,
      indentLevel,
      inset,
      isTextBox,
      italic,
      lang,
      line,
      lineSpacing,
      margin,
      outline,
      paraSpaceAfter,
      paraSpaceBefore,
      rectRadius,
      rotate,
      rtlMode,
      shadow,
      shrinkText,
      strike,
      subscript,
      superscript,
      underline,
      vert,
      autoFill,
    } = this.props;

    // Line Spacing
    // when provided as a number
    // assuming the number of lines
    // converting to the corresponding percentage
    const lnSpc =
      typeof lineSpacing === "number" ? `${100 * lineSpacing}pct` : lineSpacing;
    const lineHeight = typeof lineSpacing === "number" ? lineSpacing : null;

    let text = this.children.join("");
    if (autoFill) {
      text = [{ text, options: {}, fieldType: autoFill }];
    }

    return {
      text,
      options: cleanProps({
        placeholder,
        x,
        y,
        h,
        w,
        fill,
        bold,
        align,
        valign,
        autoFit,
        breakLine,
        bullet,
        charSpacing,
        color,
        fontFace,
        fontSize,
        hyperlink,
        indentLevel,
        inset,
        isTextBox,
        italic,
        lang,
        line,
        lineSpacing: lnSpc,
        lineHeight,
        margin,
        outline,
        paraSpaceAfter,
        paraSpaceBefore,
        rectRadius,
        rotate,
        rtlMode,
        shadow,
        shrinkText,
        strike,
        subscript,
        superscript,
        underline,
        vert,
      }),
    };
  }
}
