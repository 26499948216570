import React from "react";
import styled from "styled-components/macro";
import { observer } from "mobx-react";

import reloadIcon from "../images/refresh-icon.png";
import { useApp } from "../state/context";

import { DialogTitle } from "./DialogSections.jsx";
import { Button, ButtonIcon } from "./Button.jsx";

export default observer(() => {
  const appState = useApp();

  return (
    <Button
      outlined
      color="primary"
      css="margin-top: 5%"
      width="100%"
      onClick={appState.openResetDialog}
    >
      <ButtonIcon src={reloadIcon}></ButtonIcon>
      RESET
    </Button>
  );
});

const Paragraph = styled.div`
  font-weight: 300;
  font-size: 0.875em;
  & + & {
    margin-top: 1em;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2em;
  justify-content: flex-end;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 1em;
  }
`;

export const ResetDialog = observer(() => {
  const appState = useApp();

  return (
    <>
      <DialogTitle>Are you sure you want to reset?</DialogTitle>
      <div css="max-width: 400px">
        <Paragraph>
          It will remove the logo and reset any changes to the color scheme and
          selected fonts.
        </Paragraph>
        {appState.isPurchased && (
          <>
            <Paragraph>
              This operation will remove any information about the current
              purchased presentation.
            </Paragraph>
            <Paragraph>
              <span css="font-weight: 600;">
                Please make sure you have a copy of the current presentation!
              </span>
            </Paragraph>
          </>
        )}
      </div>
      <ButtonBar>
        <Button onClick={appState.closeModal}>Cancel</Button>
        <Button
          solid
          onClick={() => {
            appState.reset();
            appState.closeModal();
          }}
        >
          Reset
        </Button>
      </ButtonBar>
    </>
  );
});
