import React from "react";

import {
  Text,
  Slide,
  Group,
  Shape,
  Rect,
} from "../../slides-engine/smartElements.jsx";

import {
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  LIST_INDENT,
  GUTTER_SIZE,
  DEFAULT_VERTICAL_SPACE,
  CONTENT_LEVEL3_FONT_SIZE,
} from "../layoutConfig.js";

export default ({
  masterName,
  slideId,
  title,

  containerX = INNER_X,
  containerY = INNER_Y,
  containerW = INNER_W,
  containerH = INNER_H,

  accent1Color = "#4472C4", // default color scheme
  listIndent = LIST_INDENT,

  contentOffsetLeft = "8vw", // FIXME: extract -> layoutConfig
  contentOffsetRight = contentOffsetLeft,

  headerH = "8vh", // FIXME: extract -> layoutConfig

  headerColor = "#000000",
  headerFontSize = CONTENT_LEVEL3_FONT_SIZE,
  headerFontBold = true,

  bodyTextColor = "#000000",
  bodyFontSize = CONTENT_LEVEL3_FONT_SIZE,
  bodyFontBold = false,

  content = [
    [{}, {}],
    [{}, {}],
  ], // 0,0:top-left, 0,1:top-right, 1,0:bottom-left, 1,1:bottom-right

  centerItemOffsetTop = headerH,
  centerItemOffsetBottom = centerItemOffsetTop,
  centerItemOffsetLeft = GUTTER_SIZE,
  centerItemOffsetRight = centerItemOffsetLeft,
  centerItemSpacerH = `calc(0.75 * ${headerH})`,
  centerItemColor = accent1Color,
  centerItemTextColor = "#FFFFFF",
  centerItemFontSize = CONTENT_LEVEL3_FONT_SIZE,
  centerItemAlign = "center",
  centerItemValign = "center",

  centerArrowOffsetTop = `calc(0.5 * ${DEFAULT_VERTICAL_SPACE})`,
  centerArrowOffsetBottom = centerArrowOffsetTop,
  centerArrowW = GUTTER_SIZE,
  centerArrowColor = accent1Color,

  centerItems = [],
}) => {
  const colsC = content[0] && content[0].length ? content[0].length : 1;
  const rowsC = content.length;

  const itemW = `calc(${containerW} / ${colsC})`;
  const itemH = `calc(${containerH} / ${rowsC})`;

  const bodyW = `calc(${itemW} - ${contentOffsetLeft} - ${contentOffsetRight})`;

  const bulletConf = {
    type: "number",
    style: "arabicPeriod",
    indent: listIndent,
    color: accent1Color,
  };

  const contentEls = content
    .map((contentRow = [], ridx) =>
      contentRow.map((contentEl = {}, cidx) => {
        const {
          header,
          body,
          background,
          align = "left",
          valign = "top",
          headerValign = "bottom",
          bullet = true,
          lineSpacing = 1.5,
        } = contentEl;

        const elX = `calc(${containerX} + (${cidx} * ${itemW}))`;
        const elY = `calc(${containerY} + (${ridx} * ${itemH}))`;

        const backgroundEl = background ? (
          <Rect
            key={`background-${ridx}-${cidx}`}
            x={elX}
            y={elY}
            w={itemW}
            h={itemH}
            fill={background}
          />
        ) : null;

        const elTextX = `calc(${elX} + ${contentOffsetLeft})`;

        const headerEl = header ? (
          <Text
            key={`header-${ridx}-${cidx}`}
            type="body"
            x={elTextX}
            y={elY}
            w={bodyW}
            h={headerH}
            align={align}
            valign={headerValign}
            color={headerColor}
            fontSize={headerFontSize}
            bold={headerFontBold}
          >
            {header}
          </Text>
        ) : null;

        const elTextY = `calc(${elY} + ${headerH})`;
        const elTextH = `calc(${itemH} - ${headerH})`;

        const bodyEl = body ? (
          <Text
            key={`body-${ridx}-${cidx}`}
            type="body"
            x={elTextX}
            y={elTextY}
            w={bodyW}
            h={elTextH}
            align={align}
            valign={valign}
            color={bodyTextColor}
            fontSize={bodyFontSize}
            bold={bodyFontBold}
            bullet={bullet ? bulletConf : false}
            lineSpacing={lineSpacing}
          >
            {body}
          </Text>
        ) : null;

        return (
          <Group key={`group-${ridx}-${cidx}`}>
            {backgroundEl}
            {headerEl}
            {bodyEl}
          </Group>
        );
      })
    )
    .flat();

  const centerSpacerCount = centerItems.length > 1 ? centerItems.length - 1 : 0;
  const centerItemW = `calc(${contentOffsetLeft} + ${contentOffsetRight} - ${centerItemOffsetLeft} - ${centerItemOffsetRight})`;
  const centerItemH = `calc((${containerH} - ${centerItemOffsetTop} - ${centerItemOffsetBottom} - (${centerSpacerCount} * ${centerItemSpacerH})) / ${centerItems.length})`;
  const centerItemX = `calc(${containerX} + (${containerW} / 2) - (${centerItemW} / 2))`;
  const centerItemYs = centerItems.map((_, idx) => {
    const y = `calc(${containerY} + ${centerItemOffsetTop} + ${idx} * (${centerItemH} + ${centerItemSpacerH}))`;
    return y;
  });
  const centerItemEls = centerItems.map((el, idx) => {
    const {
      text,
      fill = centerItemColor,
      color = centerItemTextColor,
      fontSize = centerItemFontSize,
      align = centerItemAlign,
      valign = centerItemValign,
    } = el;
    const rectEl = (
      <Shape
        key={`center-shape-${idx}`}
        shape="rounded_rectangle"
        x={centerItemX}
        y={centerItemYs[idx]}
        w={centerItemW}
        h={centerItemH}
        fill={fill}
      >
        <Text
          color={color}
          align={align}
          valign={valign}
          fontSize={fontSize}
          autoFit
        >
          {text}
        </Text>
      </Shape>
    );

    return rectEl;
  });

  const centerArrowX = `calc(${centerItemX} + (0.5 * ${centerItemW}) - (0.5 * ${centerArrowW}))`;
  const centerArrowH = `calc(${centerItemSpacerH} - ${centerArrowOffsetTop} - ${centerArrowOffsetBottom})`;
  const centerArrowYs = centerItemYs.map(
    (y) => `calc(${y} + ${centerItemH} + ${centerArrowOffsetTop})`
  );
  const centerArrowEls = centerItems.slice(0, -1).map((el, idx) => {
    const { fill = centerArrowColor, arrowFill = fill } = el;
    return (
      <Shape
        key={`center-arrow-${idx}`}
        shape="up_arrow"
        x={centerArrowX}
        y={centerArrowYs[idx]}
        w={centerArrowW}
        h={centerArrowH}
        fill={arrowFill}
      />
    );
  });

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>

      {contentEls}

      {centerItemEls}
      {centerArrowEls}
    </Slide>
  );
};
