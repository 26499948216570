import React from "react";
import styled, { css } from "styled-components/macro";
import { parseToRgb } from "polished";

import calc, { CALC_EXPR } from "calc-units";

// TODO: remove global state!
// pass explicitly as a function param
const layout = {
  width: 2080,
  height: 1170,
};

export const ptToPx = (pt) => (Number(pt) * 20) / 9;

export const transformLength = (size = 0, xyDir) => {
  // FIRST: Convert string numeric value if reqd
  if (typeof size === "string" && !isNaN(Number(size))) size = Number(size);

  // Number is already converted to something other than inches
  if (typeof size === "number" && size >= 100) return size;
  else if (typeof size === "number") return size * 30;

  if (typeof size === "string" && CALC_EXPR.test(size)) {
    return calc(size, (v) => transformLength(v, xyDir, layout));
  }

  // Percentage (ex: '50%')
  if (typeof size === "string" && size.indexOf("%") > -1) {
    if (xyDir && xyDir === "X")
      return Math.round((parseFloat(size) / 100) * layout.width);
    if (xyDir && xyDir === "Y")
      return Math.round((parseFloat(size) / 100) * layout.height);

    // Default: Assume width (x/cx)
    return Math.round((parseFloat(size) / 100) * layout.width);
  }

  if (typeof size === "string" && size.indexOf("in") > -1)
    return parseFloat(size) * 30;
  if (typeof size === "string" && size.indexOf("cm") > -1)
    return (parseFloat(size) / 2.54) * 30;

  // viewport height and width (by analogy to the css vh and vw
  // units)
  if (typeof size === "string" && size.indexOf("vh") > -1) {
    return Math.round((parseFloat(size) / 100) * layout.height);
  }
  if (typeof size === "string" && size.indexOf("vw") > -1) {
    return Math.round((parseFloat(size) / 100) * layout.width);
  }

  // LAST: Default value
  console.warn(`could not parse size ${size}, using default value 0 instead`);
  return 0;
};

export const position = ({ x, y, h, w }) => {
  const dx = transformLength(w, "X");
  const dy = transformLength(h, "Y");
  return {
    x: transformLength(x, "X"),
    y: transformLength(y, "Y"),
    dx,
    dy,
    width: dx,
    height: dy,
  };
};

export const TextDiv = styled.div`
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: ${ptToPx(18)}px;
  box-sizing: border-box;
  padding: 10px;
  white-space: pre-wrap;

  & ul,
  ol {
    padding-left: 0;
    margin: 0;
    list-style: none;
  }

  & li::before {
    display: inline-block;
  }

  & li:not(:first-child) {
    margin-top: 0.1em;
  }
`;

const bulletColor = (props) =>
  props.color ? `color: ${asHex(props.color)};` : "";
const bulletIndent = ({ indent = "2in" }) => {
  const correctedIndent = transformLength(indent, "X");
  return css`
    & li::before {
      width: calc(${correctedIndent}px);
      margin-left: -${correctedIndent}px;
    }

    & li {
      margin-left: calc(${correctedIndent}px);
    }
  `;
};
export const UnorderedList = styled.ol`
  & li::before {
    content: "•";
    ${bulletColor};
  }
  ${bulletIndent}
`;

export const OrderedList = styled.ol`
  padding-left: 0;
  margin: 0;
  list-style: none;
  counter-reset: li;

  & li::before {
    content: counter(li);
    ${bulletColor}
  }
  ${bulletIndent}

  & li {
    counter-increment: li;
  }
`;

export const ALIGNMENT = {
  top: "flex-start",
  left: "flex-start",
  bottom: "flex-end",
  right: "flex-end",
  center: "center",
  middle: "center",
};

export const asHex = (color) => {
  if (!color || color === "none") return "transparent";
  if (color[0] === "#") return color;
  return `#${color}`;
};

export const DuoToneFilter = ({ id, darkColor, lightColor }) => {
  const darkRGB = parseToRgb(asHex(darkColor));
  const lightRGB = parseToRgb(asHex(lightColor));
  return (
    <filter id={id || "duotone"}>
      <feColorMatrix
        type="matrix"
        result="grayscale"
        values="1 0 0 0 0
              1 0 0 0 0
              1 0 0 0 0
              0 0 0 1 0"
      ></feColorMatrix>
      <feComponentTransfer colorInterpolationFilters="sRGB" result="duotone">
        <feFuncR
          type="table"
          tableValues={`${darkRGB.red / 255} ${lightRGB.red / 255}`}
        ></feFuncR>
        <feFuncG
          type="table"
          tableValues={`${darkRGB.green / 255} ${lightRGB.green / 255}`}
        ></feFuncG>
        <feFuncB
          type="table"
          tableValues={`${darkRGB.blue / 255} ${lightRGB.blue / 255}`}
        ></feFuncB>
        <feFuncA type="table" tableValues="0 1"></feFuncA>
      </feComponentTransfer>
    </filter>
  );
};

export const asLineCap = (cap) => {
  const capMap = { rnd: "round", flat: "butt", sq: "square" };
  return capMap[cap] || "none";
};

export const buildSVGText = (text, options, elemId) => {
  const style = {
    width: "100%",
    height: "100%",
    padding: "10px 14px",
    overflowWrap: "anywhere",
    wordBreak: "break-word",
  };
  if (options.type === "title") {
    style.fontSize = ptToPx(44);
  }
  if (options.type === "body") {
    style.fontSize = ptToPx(32);
  }

  if (options.bullet) {
    const { type, indent = "3%", color } = options.bullet;
    const List = type === "number" ? OrderedList : UnorderedList;
    text = (
      <List indent={indent} color={color}>
        {text.split(/\n/).map((l) => (
          <li key={l}>{l}</li>
        ))}
      </List>
    );
  }

  if (options.fontSize) {
    style.fontSize = ptToPx(options.fontSize);
  }
  if (options.bold) {
    style.fontWeight = "bold";
  }
  if (options.valign) {
    style.alignItems = ALIGNMENT[options.valign];
  }
  if (options.align) {
    style.justifyContent = ALIGNMENT[options.align];
    if (options.align === "center") style.textAlign = "center";
    if (options.align === "right") style.textAlign = "right";
  }
  if (options.color) {
    style.color = asHex(options.color);
  }
  if (options.lineHeight) {
    style.lineHeight = options.lineHeight;
  }

  const wrapperStyle = {
    overflow: "visible",
  };
  if (options.vert) {
    // Should be options.vert === "vert" ? "vertical-lr" : "sideways-lr";
    // Let's fix it when blink has it implemented
    style.writingMode = "vertical-lr";
  }

  return (
    <foreignObject key={elemId} {...position(options)} style={wrapperStyle}>
      <TextDiv
        className={options.type === "title" ? "title-text" : "body-text"}
        style={style}
      >
        {text}
      </TextDiv>
    </foreignObject>
  );
};

const ALIGN = {
  center: "Mid",
  middle: "Mid",
  left: "Min",
  top: "Min",
  right: "Max",
  bottom: "Max",
};
export const imagePos = (align = "center", valign = "middle") => {
  return `x${ALIGN[align]}Y${ALIGN[valign]}`;
};
