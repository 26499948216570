import React, { useEffect, useMemo, useContext } from "react";
import { usePlaceholdersRegistration } from "./Master";
import { extractTxtProps, TextOverridesContext } from "./Text";

export default React.memo(({ name, children, ...props }) => {
  const overrides = useContext(TextOverridesContext);
  const placeholderInfo = useMemo(() => {
    const opts = extractTxtProps(children, { ...props, ...overrides });
    opts.props = props;
    return opts;
  }, [props, children, overrides]);

  const {
    registerPlaceholder,
    unRegisterPlaceholder,
  } = usePlaceholdersRegistration();

  useEffect(() => {
    registerPlaceholder(name, placeholderInfo);
    return () => unRegisterPlaceholder(name);
  }, [name, placeholderInfo, registerPlaceholder, unRegisterPlaceholder]);

  return null;
});
