import bulletpoints from "bulletpoints";

const AVAILABLE_CHILDREN = ["master", "slide"];

export default class Deck {
  constructor(props = {}) {
    this.tag = "deck";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    if (!AVAILABLE_CHILDREN.includes(child.tag)) {
      console.warn(`child of type ${child.tag} in Deck ignored`);
    }
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    const presLayout = this.props.layout;
    const deck = new bulletpoints({ presLayout });

    if (this.props.author) deck.author = this.props.author;
    if (this.props.company) deck.company = this.props.company;
    if (this.props.revision) deck.revision = this.props.revision;
    if (this.props.subject) deck.subject = this.props.subject;

    deck.title = this.props.title || "Bulletpoints.studio Template";

    if (this.props.rtl) deck.rltMode = true;

    if (this.props.fontFamily || this.props.colorScheme)
      deck.configureTheme(
        this.props.fontFamily.replace(/-/g, " "),
        this.props.titleFontFamily.replace(/-/g, " "),
        this.props.colorScheme
      );

    for (let child of this.children) {
      if (child.tag === "slide") {
        child.render(deck.addSlide.bind(deck));
      } else if (child.tag === "master") {
        deck.defineSlideMaster(child.render());
      }
    }

    return deck;
  }
}
