import { SHAPES } from "./utils";

export default class Shape {
  constructor(props = {}) {
    this.tag = "shape";
    this.elemId = Math.random();
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    if (child.tag !== "text" && child.tag !== "placeholder") {
      console.warn(`${child.tag} children are ignored in shape`);
      return;
    }
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    const {
      shape: inputShape,
      x,
      y,
      h,
      w,
      fill,
      transparency,
      borderStyle,
      borderHead,
      borderTail,
      borderCap,
      borderWidth,
      borderColor,
      borderTransparency,
      radius = 0,
      flipH,
      flipV,
    } = this.props;

    let shape = SHAPES[inputShape.toUpperCase()];
    if (radius === 0 && shape === SHAPES.ROUNDED_RECTANGLE) {
      // PowerPoint assumes non-zero radius by default
      // tweaking into the regular rectangle
      shape = SHAPES.RECTANGLE;
    } else if (!shape) {
      shape = SHAPES.RECTANGLE;
      console.warn(`Could not find shape "${inputShape}", using a rectangle`);
    }

    let fillProps = transparency ? { color: fill, alpha: transparency } : fill;
    const lineProps = borderTransparency
      ? { color: borderColor, alpha: borderTransparency }
      : borderColor;

    const shapeConfig = {
      x,
      y,
      h,
      w,
      fill: fillProps,
      line: lineProps,
      lineDash: borderStyle,
      lineHead: borderHead,
      lineTail: borderTail,
      lineCap: borderCap,
      lineSize: borderWidth,
      rectRadius: radius,
      flipH,
      flipV,
    };

    if (!this.children.length) return [shape, shapeConfig];
    if (this.children.length > 1) {
      console.warn("You need to have only one child for a shape");
      return [shape, shapeConfig];
    }

    const { text, options } = this.children[0].render();
    const config = {
      text,
      options: {
        ...options,
        ...shapeConfig,
        shape,
      },
    };

    return [this.children[0].tag, config];
  }
}
