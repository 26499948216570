import React from "react";
import { Group, Text, Line } from "../../slides-engine/smartElements.jsx";

export const DEFAULT_W = "20vw";
export const DEFAULT_H = "20vh";

export default ({
  x = 0,
  y = 0,
  w = DEFAULT_W,
  h = DEFAULT_H,

  titleColor = "#000000",
  titleFontSize = 32,
  titleFontBold = false,
  titleH = "10vh",

  descriptionColor = "#000000",
  descriptionFontSize = 18,
  descriptionFontBold = false,

  titleDescriptionOffsetTop = "-2vh",

  lineColor = "000000",
  lineWidth = 1,
  lineHidden = false,
  lineOffsetLeft = "0vw",
  lineOffsetTop = "3vh",

  title = "",
  description = "",
}) => {
  const textX = `calc(${x} + ${lineOffsetLeft})`;
  const textY = `calc(${y} + ${lineOffsetTop})`;
  const textW = `calc(${w} - ${lineOffsetLeft})`;
  // FIXME:
  // the two values below must be calculated relative to the font
  // yet at the moment I have no clue how to implement this purely technically
  // hence adding to the tech / product debt
  const descriptionY = `calc(${y} + ${lineOffsetTop} + ${titleH} + (${titleDescriptionOffsetTop}))`;
  const descriptionH = `calc(${h} - ${lineOffsetTop} - ${titleH} - (${titleDescriptionOffsetTop}))`;

  return (
    <Group>
      {/* <rect x={x} y={y} h={h} w={w} fill="#000000" /> */}
      {!lineHidden && (
        <Line x={x} y={y} h={h} w={0} color={lineColor} width={lineWidth} />
      )}

      <Text
        type="title"
        color={titleColor}
        fontSize={titleFontSize}
        bold={titleFontBold}
        align="left"
        valign="bottm"
        x={textX}
        y={textY}
        w={textW}
        h={titleH}
      >
        {title}
      </Text>

      <Text
        type="body"
        color={descriptionColor}
        fontSize={descriptionFontSize}
        bold={descriptionFontBold}
        align="left"
        valign="top"
        x={textX}
        y={descriptionY}
        w={textW}
        h={descriptionH}
      >
        {description}
      </Text>
    </Group>
  );
};
