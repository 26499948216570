import React from "react";
import { observer } from "mobx-react";

import { COLOR_DEFAULT_DARK1 } from "../models/colorScheme";
import ImageWrapper from "../components/ImageWrapper";
import { Group, Text } from "../../slides-engine/smartElements.jsx";

import {
  DEFAULT_VERTICAL_SPACE,
  GUTTER_SIZE,
  CONTENT_LEVEL3_FONT_SIZE,
  CONTENT_LEVEL5_FONT_SIZE,
} from "../layoutConfig.js";

export const X_DEFAULT = 0;
export const Y_DEFAULT = 0;
export const W_DEFAULT = "100vw";
export const H_DEFAULT = "100vh";

export const CELL_OFFSET_LEFT_DEFAULT = `calc(2 * ${GUTTER_SIZE})`;
export const CELL_OFFSET_RIGHT_DEFAULT = CELL_OFFSET_LEFT_DEFAULT;
export const CELL_OFFSET_TOP_DEFAULT = `calc(2 * ${DEFAULT_VERTICAL_SPACE})`;
export const CELL_OFFSET_BOTTOM_DEFAULT = CELL_OFFSET_TOP_DEFAULT;

export const CELL_TITLE_COLOR_DEFAULT = COLOR_DEFAULT_DARK1;
export const CELL_TITLE_ALIGN_DEFAULT = "center";
export const CELL_TITLE_VALIGN_DEFAULT = "bottom";
export const CELL_TITLE_FONT_SIZE_DEFAULT = CONTENT_LEVEL3_FONT_SIZE;
export const CELL_TITLE_FONT_BOLD_DEFAULT = true;
export const CELL_TITLE_H_DEFAULT = "6vh";

export const CELL_SUBTITLE_COLOR_DEFAULT = CELL_TITLE_COLOR_DEFAULT;
export const CELL_SUBTITLE_ALIGN_DEFAULT = CELL_TITLE_ALIGN_DEFAULT;
export const CELL_SUBTITLE_VALIGN_DEFAULT = "top";
export const CELL_SUBTITLE_FONT_SIZE_DEFAULT = CONTENT_LEVEL5_FONT_SIZE;
export const CELL_SUBTITLE_FONT_BOLD_DEFAULT = false;
export const CELL_SUBTITLE_H_DEFAULT = `calc(3 * ${CELL_TITLE_H_DEFAULT})`;

export default observer(
  ({
    x = X_DEFAULT,
    y = Y_DEFAULT,
    w = W_DEFAULT,
    h = H_DEFAULT,

    cellOffsetLeft = CELL_OFFSET_LEFT_DEFAULT,
    cellOffsetRight = CELL_OFFSET_RIGHT_DEFAULT,
    cellOffsetTop = CELL_OFFSET_TOP_DEFAULT,
    cellOffsetBottom = CELL_OFFSET_BOTTOM_DEFAULT,

    cellTitleColor = CELL_TITLE_COLOR_DEFAULT,
    cellTitleAlign = CELL_TITLE_ALIGN_DEFAULT,
    cellTitleValign = CELL_TITLE_VALIGN_DEFAULT,
    cellTitleFontSize = CELL_TITLE_FONT_SIZE_DEFAULT,
    cellTitleFontBold = CELL_TITLE_FONT_BOLD_DEFAULT,
    cellTitleH = CELL_TITLE_H_DEFAULT,

    cellSubtitleColor = CELL_SUBTITLE_COLOR_DEFAULT,
    cellSubtitleAlign = CELL_SUBTITLE_ALIGN_DEFAULT,
    cellSubtitleValign = CELL_SUBTITLE_VALIGN_DEFAULT,
    cellSubtitleFontSize = CELL_SUBTITLE_FONT_SIZE_DEFAULT,
    cellSubtitleFontBold = CELL_SUBTITLE_FONT_BOLD_DEFAULT,
    cellSubtitleH = CELL_SUBTITLE_H_DEFAULT,

    content = [],
  }) => {
    const rowsCount = (content && content.length) || 1;
    const rowsSpacersCount = rowsCount - 1;
    const rowH = `calc((${h} - (${rowsSpacersCount} * (${cellOffsetTop} + ${cellOffsetBottom}))) / ${rowsCount})`;

    const gridEls = content
      .map((row, ridx) => {
        const colsCount = (row && row.length) || 1;
        const colsSpacersCount = colsCount - 1;
        const colW = `calc((${w} - (${colsSpacersCount} * (${cellOffsetLeft} + ${cellOffsetRight}))) / ${colsCount})`;
        return row
          .map((cell, cidx) => {
            const cellX = `calc(${x} + ${cidx} * (${colW} + ${cellOffsetLeft} + ${cellOffsetRight}))`;
            const cellY = `calc(${y} + ${ridx} * (${rowH} + ${cellOffsetTop} + ${cellOffsetBottom}))`;

            const {
              subtitle,
              subtitleColor = cellSubtitleColor,
              subtitleAlign = cellSubtitleAlign,
              subtitleValign = cellSubtitleValign,
              subtitleFontSize = cellSubtitleFontSize,
              subtitleFontBold = cellSubtitleFontBold,
              subtitleH = cellSubtitleH,
            } = cell;
            const subtitleY = `calc(${cellY} + ${rowH} - ${subtitleH})`;
            const subtitleEl = subtitle ? (
              <Text
                x={cellX}
                y={subtitleY}
                w={colW}
                h={subtitleH}
                color={subtitleColor}
                align={subtitleAlign}
                valign={subtitleValign}
                fontSize={subtitleFontSize}
                bold={subtitleFontBold}
              >
                {subtitle}
              </Text>
            ) : null;

            const {
              title,
              titleColor = cellTitleColor,
              titleAlign = cellTitleAlign,
              titleValign = cellTitleValign,
              titleFontSize = cellTitleFontSize,
              titleFontBold = cellTitleFontBold,
              titleH = cellTitleH,
            } = cell;
            const titleY = `calc(${subtitleY} - ${titleH})`;
            const titleEl = title ? (
              <Text
                x={cellX}
                y={titleY}
                w={colW}
                h={titleH}
                color={titleColor}
                align={titleAlign}
                valign={titleValign}
                fontSize={titleFontSize}
                bold={titleFontBold}
              >
                {title}
              </Text>
            ) : null;

            const { image, imageFit = "width" } = cell;

            // NOTE: wrapped as a function because I would
            // prefer imageW and imageH to be constants
            const { imageW, imageH } = (() => {
              if (imageFit === "width") {
                const imageW = colW;
                const imageH =
                  image && image.data
                    ? `calc(${imageW} * (${image.height} / ${image.width}))`
                    : 0;
                return { imageW, imageH };
              } else {
                const imageH = `calc(${rowH} - ${titleH} - ${subtitleH})`;
                const imageW =
                  image && image.data
                    ? `calc(${imageH} * (${image.width} / ${image.height}))`
                    : 0;
                return { imageW, imageH };
              }
            })();
            const imageY = `calc(${cellY} + 0.5 * (${rowH} - ${titleH} - ${subtitleH} - ${imageH}))`;
            const imageX = `calc(${cellX} + 0.5 * (${colW} - ${imageW}))`;

            const imageEl = image ? (
              <ImageWrapper
                {...(image || {})}
                x={imageX}
                y={imageY}
                w={imageW}
                h={imageH}
              />
            ) : null;

            return (
              <Group key={`imagegrid-r${ridx}c${cidx}`}>
                {/* <rect x={cellX} y={cellY} w={colW} h={rowH} fill="#44546A" /> */}
                {imageEl}
                {titleEl}
                {subtitleEl}
              </Group>
            );
          })
          .flat();
      })
      .flat();

    return (
      <Group>
        {/* <rect x={x} y={y} w={w} h={h} fill="#E7E6E6" /> */}
        {gridEls}
      </Group>
    );
  }
);
