export default class Root {
  constructor(props = {}) {
    this.tag = "root";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.children = [];
  }

  appendChild(child) {
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    return this.children[0].render();
  }

  renderSVG() {
    return this.children[0].renderSVG();
  }
}
