import React, {
  createContext,
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useDeck } from "./Deck";

const PlaceholdersContext = createContext({});

export const usePlaceholdersRegistration = () =>
  useContext(PlaceholdersContext);

export default React.memo(({ children, title }) => {
  const [placeholders, setPlaceholders] = useState(new Map());
  const registerPlaceholder = useCallback((placeholderId, placeholder) => {
    setPlaceholders((p) => {
      if (p.has(placeholderId)) return p;
      p.set(placeholderId, placeholder);
      return new Map(p);
    });
  }, []);
  const unRegisterPlaceholder = useCallback((placeholderId) => {
    setPlaceholders((p) => {
      if (!p.has(placeholderId)) return p;
      p.delete(placeholderId);
      return new Map(p);
    });
  }, []);

  const { registerMaster, unRegisterMaster } = useDeck();

  const renderedTree = useMemo(
    () => (
      <PlaceholdersContext.Provider
        value={{
          registerPlaceholder,
          unRegisterPlaceholder,
        }}
      >
        {children}
      </PlaceholdersContext.Provider>
    ),
    [children, registerPlaceholder, unRegisterPlaceholder]
  );

  useEffect(() => {
    registerMaster(title, { placeholders, components: renderedTree });
    return () => unRegisterMaster(title);
  }, [placeholders, renderedTree, registerMaster, unRegisterMaster, title]);

  return null;
});
