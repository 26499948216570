export default class Rect {
  constructor(props) {
    this.tag = "rect";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    console.warn(`children in rect ignored`);
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    const { x, y, h, w, fill, opacity } = this.props;
    const fillProps = !!opacity
      ? { color: fill, alpha: 100 - opacity * 100 }
      : fill;
    return {
      x,
      y,
      h,
      w,
      fill: fillProps,
    };
  }
}
