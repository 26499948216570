import React from "react";
import { asHex, position, ptToPx } from "../svg-helpers";

export default React.memo((props) => {
  const { x, y, dx, dy } = position(props);
  const angle = dx || dy ? (180 * Math.atan2(dy, dx)) / Math.PI : 0;
  const { flipH = false, flipV = false } = props;
  const { color, opacity } = props;
  const { head, tail } = props;
  const r = 6;
  const width = props.width || 1;
  const dash = props.dash ? "12 4" : "";

  // https://stackoverflow.com/questions/23899718/scale-and-mirror-svg-object
  const trX = flipH ? x + (x + dx) : 0;
  const trY = flipV ? y + (y + dy) : 0;
  const scX = flipH ? -1 : 1;
  const scY = flipV ? -1 : 1;

  return (
    <g
      transform={
        flipH || flipV ? `translate(${trX},${trY}) scale(${scX}, ${scY})` : null
      }
    >
      {head === "oval" ? (
        <ellipse
          fill={asHex(color)}
          opacity={opacity}
          cx={x}
          cy={y}
          rx={r}
          ry={r}
        />
      ) : null}

      {head === "triangle" ? (
        <polygon
          points={[
            `${x},${y}`,
            `${x + r * width},${y + r * width}`,
            `${x + r * width},${y - r * width}`,
          ].join(" ")}
          transform={angle ? `rotate(${angle} ${x} ${y})` : null}
          fill={asHex(color)}
          opacity={opacity}
        />
      ) : null}

      <line
        x1={x}
        x2={x + dx}
        y1={y}
        y2={y + dy}
        stroke={asHex(color)}
        strokeWidth={ptToPx(width)}
        strokeDasharray={dash}
        opacity={opacity}
      />

      {tail === "oval" ? (
        <ellipse
          fill={asHex(color)}
          opacity={opacity}
          cx={x + dx}
          cy={y + dy}
          rx={r}
          ry={r}
        />
      ) : null}

      {tail === "triangle" ? (
        <polygon
          points={[
            `${x + dx},${y + dy}`,
            `${x + dx - r * width},${y + dy + r * width}`,
            `${x + dx - r * width},${y + dy - r * width}`,
          ].join(" ")}
          transform={angle ? `rotate(${angle} ${x + dx} ${y + dy})` : null}
          fill={asHex(color)}
          opacity={opacity}
        />
      ) : null}
    </g>
  );
});
