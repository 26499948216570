import React from "react";
import { asHex, position } from "../svg-helpers";

export default React.memo((props) => {
  const style = {};
  if (props.chartColorsOpacity) {
    style.opacity = Number(props.chartColorsOpacity) / 100;
  }

  // Hacking the area around the chart back in here
  const location = position({
    x: `calc(${props.x || 0} + 6.1%)`,
    y: `calc(${props.y || 0} - 1.2%)`,
    w: `calc(${props.w || 0} - 12.2%)`,
    h: props.h,
  });

  const x0 = location.x;
  const xMax = location.x + location.dx;

  const y0 = location.y + location.dy;

  const values = props.data[0].values;
  const maxValue = props.valAxisMaxVal || Math.max(values);
  const pointsCount = values.length;

  const points = props.data[0].values.map((value, k) => {
    return [
      x0 + k * (location.dx / (pointsCount - 1)),
      y0 - (value / maxValue) * location.dy,
    ];
  });

  const path = `M ${x0} ${y0} ${points
    .map(([x, y]) => {
      return `L ${x} ${y}`;
    })
    .join(" ")} L ${xMax} ${y0} Z`;

  return <path fill={asHex(props.chartColors[0])} d={path} style={style} />;
});
