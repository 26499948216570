import { types } from "mobx-state-tree";

import Image, { OptionalImage } from "./image";
import Color from "./color";
import ColorScheme from "./colorScheme";

const TitleSlideMaster = types.model("TitleSlideMaster", {
  id: types.identifierNumber,
  background: OptionalImage,
  overlayColor: Color,
});
const TitleSlideSlide = types.model("TitleSlideSlide", {
  id: types.identifierNumber,
  master: "TitleSlideMaster",
});

const TitleAndContentMaster = types.model("TitleAndContentMaster", {
  id: types.identifierNumber,
});
const TitleAndContentSlide = types.model("TitleAndContentSlide", {
  id: types.identifierNumber,
});

const SectionHeaderMaster = types.model("SectionHeaderMaster", {
  id: types.identifierNumber,
  background: OptionalImage,
  backgroundColor: Color,
  textColor: Color,
});
const SectionHeaderSlide = types.model("SectionHeaderSlide", {
  id: types.identifierNumber,
  master: "SectionHeaderMaster",
});

const TwoContentMaster = types.model("TwoContentMaster", {
  id: types.identifierNumber,
});
const TwoContentSlide = types.model("TwoContentSlide", {
  id: types.identifierNumber,
});

const ComparisonMaster = types.model("ComparisonMaster", {
  id: types.identifierNumber,
});
const ComparisonSlide = types.model("ComparisonSlide", {
  id: types.identifierNumber,
});

const TitleOnlyMaster = types.model("TitleOnlyMaster", {
  id: types.identifierNumber,
});
const TitleOnlySlide = types.model("TitleOnlySlide", {
  id: types.identifierNumber,
});

const BlankMaster = types.model("BlankMaster", {
  id: types.identifierNumber,
});
const BlankSlide = types.model("BlankSlide", {
  id: types.identifierNumber,
});

const ContentWithCaptionMaster = types.model("ContentWithCaptionMaster", {
  id: types.identifierNumber,
});
const ContentWithCaptionSlide = types.model("ContentWithCaptionSlide", {
  id: types.identifierNumber,
});

const PictureWithCaptionMaster = types.model("PictureWithCaptionMaster", {
  id: types.identifierNumber,
});
const PictureWithCaptionSlide = types.model("PictureWithCaptionSlide", {
  id: types.identifierNumber,
});

const AgendaMaster = types.model("AgendaMaster", {
  id: types.identifierNumber,
  backgroundColor: Color,
});
const AgendaSlide = types.model("AgendaSlide", {
  id: types.identifierNumber,
  master: "AgendaMaster",
  backgroundColor: Color,
});

const FullScreenImageMaster = types.model("FullScreenImageMaster", {
  id: types.identifierNumber,
});
const FullScreenImageSlide = types.model("FullScreenImageSlide", {
  id: types.identifierNumber,
  master: "FullScreenImageMaster",
  background: OptionalImage,
});

const InspirationQuoteMaster = types.model("InspirationQuoteMaster", {
  id: types.identifierNumber,
  background: OptionalImage,
  baseColor: Color,
});
const InspirationQuoteSlide = types.model("InspirationQuoteSlide", {
  id: types.identifierNumber,
  master: "InspirationQuoteMaster",
});

const BigStatementMaster = types.model("BigStatementMaster", {
  id: types.identifierNumber,
  backgroundColor: Color,
  textColor: Color,
});
const BigStatementSlide = types.model("BigStatementSlide", {
  id: types.identifierNumber,
  master: "BigStatementMaster",
});

const SideBySideComparisonSlide = types.model("SideBySideComparisonSlide", {
  id: types.identifierNumber,
  master: "BlankMaster",
  backgroundColorLeft: Color,
  imageLeft: OptionalImage,
  backgroundColorRight: Color,
  imageRight: OptionalImage,
  textColor: Color,
});

const TimelineSlide = types.model("TimelineSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
  color: Color,
});

const ThreeNumbersSlide = types.model("ThreeNumbersSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const GrowthSlide = types.model("GrowthSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
  color: Color,
});

const ProcessSlide = types.model("ProcessSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const PrioritiesSlide = types.model("PrioritiesSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
  numberColor: Color,
  row2BackgroundColor: Color,
});

const RoadmapSlide = types.model("RoadmapSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
  columnHeadersColor: Color,
  rowSeparatorColor: Color,
  row1Color: Color,
  row2Color: Color,
  row3Color: Color,
  row4Color: Color,
});

const PyramidSlide = types.model("PyramidSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const FunnelSlide = types.model("FunnelSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const ThreeContentSlide = types.model("ThreeContentSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const QuadContentSlide = types.model("QuadContentSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const StrategySlide = types.model("StrategySlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const ShowcaseSlide = types.model("ShowcaseSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const MeetTheTeamSlide = types.model("MeetTheTeamSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const ContactUsSlide = types.model("ContactUsSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const GDPRComplianceSlide = types.model("GDPRComplianceSlide", {
  id: types.identifierNumber,
  master: "TitleOnlyMaster",
});

const slidesTypes = {
  TitleSlideMaster,
  TitleSlideSlide,

  TitleAndContentMaster,
  TitleAndContentSlide,

  SectionHeaderMaster,
  SectionHeaderSlide,

  TwoContentMaster,
  TwoContentSlide,

  ComparisonMaster,
  ComparisonSlide,

  TitleOnlyMaster,
  TitleOnlySlide,

  BlankMaster,
  BlankSlide,

  ContentWithCaptionMaster,
  ContentWithCaptionSlide,

  PictureWithCaptionMaster,
  PictureWithCaptionSlide,

  AgendaMaster,
  AgendaSlide,

  FullScreenImageMaster,
  FullScreenImageSlide,

  InspirationQuoteMaster,
  InspirationQuoteSlide,

  BigStatementMaster,
  BigStatementSlide,

  SideBySideComparisonSlide,
  TimelineSlide,
  ThreeNumbersSlide,
  GrowthSlide,
  ProcessSlide,
  PrioritiesSlide,
  RoadmapSlide,
  PyramidSlide,
  FunnelSlide,
  ThreeContentSlide,
  QuadContentSlide,
  StrategySlide,
  ShowcaseSlide,
  MeetTheTeamSlide,
  ContactUsSlide,
  GDPRComplianceSlide,
};
export const Slide = types.union({
  dispatcher: (snapshot) => {
    return slidesTypes[snapshot.type];
  },
});

const Deck = types
  .model("Deck", {
    logo: Image,
    colorScheme: ColorScheme,
    fontFamily: types.string,
    titleFontFamily: types.string,
    customFonts: types.array(types.string),
    layout: types.string,
    viewBox: types.string,
  })
  .actions((self) => ({
    setFontFamily(newFontFamily) {
      self.fontFamily = newFontFamily;
    },
    setTitleFontFamily(newFontFamily) {
      self.titleFontFamily = newFontFamily;
    },
    addNewFont(font) {
      self.customFonts.push(font);
    },
  }));

export default Deck;
