export default class Line {
  constructor(props = {}) {
    this.tag = "line";
    this.elemId = props.elemId || `${this.tag}-${Math.random()}`;
    this.props = props;
    this.children = [];
  }

  appendChild(child) {
    console.warn(`children in line ignored`);
    this.children.push(child);
  }

  removeChild(child) {
    const index = this.children.indexOf(child);
    this.children.splice(index, 1);
  }

  render() {
    const {
      x,
      y,
      h,
      w,
      color,
      dash,
      head,
      tail,
      width,
      flipH,
      flipV,
    } = this.props;
    return {
      x,
      y,
      h,
      w,
      line: color,
      lineDash: dash,
      lineHead: head,
      lineTail: tail,
      lineSize: width,
      flipH,
      flipV,
    };
  }
}
