import { types, applySnapshot } from "mobx-state-tree";

import { STORAGE_KEY } from "../constants";
import landscape from "../images/landscape.jpeg";
import iconComparisonLeft from "../images/icon-comparison-left.png";
import iconComparisonRight from "../images/icon-comparison-right.png";
import photoBusinessMeeting from "../images/photo-business-meeting.jpg";
import photoTitleSlideBackground from "../images/photo-title-slide-background.jpg";
import showcase1 from "../images/showcase-1.jpg";
import showcase2 from "../images/showcase-2.jpg";
import showcase3 from "../images/showcase-3.jpg";
import meetTheTeam1 from "../images/meet-the-team-1.png";
import meetTheTeam2 from "../images/meet-the-team-2.png";
import meetTheTeam3 from "../images/meet-the-team-3.png";
import meetTheTeam4 from "../images/meet-the-team-4.png";
import contactUs1 from "../images/contant-us-1.jpg";
import iconGDPR1 from "../images/icon-gdpr-1.png";
import iconLock from "../images/icon-lock.svg";

import Deck, { Slide } from "../presentation/models/deck";
import { OptionalImage } from "../presentation/models/image";

const Palette = types
  .model("Palette", {
    lightVibrant: types.maybe(types.string),
    vibrant: types.maybe(types.string),
    darkVibrant: types.maybe(types.string),
    lightMuted: types.maybe(types.string),
    muted: types.maybe(types.string),
    darkMuted: types.maybe(types.string),
  })
  .actions((self) => ({
    import(palette) {
      if (!palette) return;
      self.lightVibrant = palette[0];
      self.vibrant = palette[1];
      self.darkVibrant = palette[2];
      self.lightMuted = palette[3];
      self.muted = palette[4];
      self.darkMuted = palette[5];
    },
  }));

const DefaultImages = types.model("DefaultImages", {
  background: OptionalImage,
  iconComparisonLeft: OptionalImage,
  iconComparisonRight: OptionalImage,
  photoBusinessMeeting: OptionalImage,
  photoTitleSlideBackground: OptionalImage,
  showcase1: OptionalImage,
  showcase2: OptionalImage,
  showcase3: OptionalImage,
  meetTheTeam1: OptionalImage,
  meetTheTeam2: OptionalImage,
  meetTheTeam3: OptionalImage,
  meetTheTeam4: OptionalImage,
  contactUs1: OptionalImage,
  iconGDPR1: OptionalImage,
  iconLock: OptionalImage,
});

const initDefaultImages = () => {
  const images = DefaultImages.create();

  images.background.load(landscape);
  images.iconComparisonLeft.load(iconComparisonLeft);
  images.iconComparisonRight.load(iconComparisonRight);
  images.photoBusinessMeeting.load(photoBusinessMeeting);
  images.photoTitleSlideBackground.load(photoTitleSlideBackground);
  images.showcase1.load(showcase1);
  images.showcase2.load(showcase2);
  images.showcase3.load(showcase3);
  images.meetTheTeam1.load(meetTheTeam1);
  images.meetTheTeam2.load(meetTheTeam2);
  images.meetTheTeam3.load(meetTheTeam3);
  images.meetTheTeam4.load(meetTheTeam4);
  images.contactUs1.load(contactUs1);
  images.iconGDPR1.load(iconGDPR1);
  images.iconLock.load(iconLock);

  return images;
};

const defaultImages = initDefaultImages();

const slideRef = types.safeReference(Slide, {
  get(identifier, parent) {
    return parent.slides.find((u) => u.id === identifier) || null;
  },
  set(value) {
    return value.id;
  },
});

export const PresentationState = types
  .model("PresentationState", {
    selectedSlide: slideRef,
    deck: Deck,
    palette: Palette,
    images: DefaultImages,
    slideMasters: types.array(Slide),
    slides: types.array(Slide),

    sampleSlideMasters: types.array(Slide),
    sampleSlides: types.array(Slide),
  })
  .actions((self) => ({
    deselectSlide() {
      self.selectedSlide = undefined;
    },

    selectSlide(slideId) {
      if (self.selectedSlide && self.selectedSlide.id === slideId)
        self.deselectSlide();
      else self.selectedSlide = slideId;
    },

    selectNextSlide() {
      let currentIndex = self.slides.findIndex(
        ({ id }) => id === self.selectedSlide.id
      );
      if (!currentIndex && currentIndex !== 0) return;

      let selectIndex = currentIndex + 1;
      if (selectIndex === self.slides.length) selectIndex = 0;

      self.selectSlide(self.slides[selectIndex].id);
    },

    selectPrevSlide() {
      let currentIndex = self.slides.findIndex(
        ({ id }) => id === self.selectedSlide.id
      );
      if (!currentIndex && currentIndex !== 0) return;

      let selectIndex = currentIndex - 1;
      if (selectIndex < 0) selectIndex = self.slides.length - 1;

      self.selectSlide(self.slides[selectIndex].id);
    },

    selectFirstSlide() {
      if (!self.deck || !self.slides.length) return;
      self.selectSlide(self.slides[0].id);
    },

    reset() {
      localStorage.removeItem(STORAGE_KEY);
      applySnapshot(self, presentationInitialState);
    },
  }));

export const presentationInitialState = {
  palette: {},
  images: defaultImages,
  slideMasters: [
    // Masters comes first
    "TitleSlideMaster",
    "TitleAndContentMaster",
    "SectionHeaderMaster",
    "TwoContentMaster",
    "ComparisonMaster",
    "TitleOnlyMaster",
    "BlankMaster",
    "ContentWithCaptionMaster",
    "PictureWithCaptionMaster",
    "AgendaMaster",
    "FullScreenImageMaster",
    "InspirationQuoteMaster",
    "BigStatementMaster",
  ].map((val, idx) => {
    return { id: idx + 1000, type: val };
  }),
  sampleSlideMasters: ["TitleSlideMaster", "TitleOnlyMaster"].map(
    (val, idx) => {
      return { id: idx + 1000, type: val };
    }
  ),

  slides: [
    // Deck slides come after
    // IMPORTANT: ensure the corresponding
    // master slide present in the deck already
    "TitleSlideSlide",
    "AgendaSlide",
    "BigStatementSlide",
    "SectionHeaderSlide",
    "SideBySideComparisonSlide",
    "ShowcaseSlide",
    "ThreeContentSlide",
    "TimelineSlide",
    "ThreeNumbersSlide",
    "GrowthSlide",
    "ProcessSlide",
    "PrioritiesSlide",
    "RoadmapSlide",
    "PyramidSlide",
    "FunnelSlide",
    "QuadContentSlide",
    "StrategySlide",
    "GDPRComplianceSlide",
    "MeetTheTeamSlide",
    "ContactUsSlide",
    // commenting-out but not removing the slides
    // for convinience in future dev
    // "BlankSlide",
    // "TitleAndContentSlide",
    // "TwoContentSlide",
    // "ComparisonSlide",
    // "TitleOnlySlide",
    // "ContentWithCaptionSlide",
    // "PictureWithCaptionSlide",
    // "FullScreenImageSlide",
    // "InspirationQuoteSlide",
  ].map((val, idx) => {
    return { id: idx + 1, type: val };
  }),

  sampleSlides: [
    // Deck slides come after
    // IMPORTANT: ensure the corresponding
    // master slide present in the deck already
    "TitleSlideSlide",
    "TimelineSlide",
    "GrowthSlide",
  ].map((val, idx) => {
    return { id: idx + 1, type: val };
  }),

  selectedSlide: 1, // FIXME: calculate dynamically
  deck: {
    logo: {},
    fontFamily: "Helvetica Neue-Thin",
    titleFontFamily: "Helvetica Neue-Light",
    customFonts: [],
    colorScheme: {},
    layout: "LAYOUT_WIDE",
    viewBox: "0 0 2080 1170",
  },
};
