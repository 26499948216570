import React from "react";

import {
  Text,
  Slide,
  Group,
  Shape,
  Line,
} from "../../slides-engine/smartElements.jsx";

import {
  CONTENT_LEVEL5_FONT_SIZE,
  CONTENT_LEVEL6_FONT_SIZE,
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  LIST_INDENT,
  BULLET_TYPE_DEFAULT,
  BULLET_CODE_DEFUALT,
} from "../layoutConfig.js";

export default ({
  masterName,
  slideId,
  title,

  containerX = INNER_X,
  containerY = INNER_Y,
  containerW = INNER_W,
  containerH = INNER_H,

  upArrowColor = "#AF7AC5",
  upArrowTextColor = "#FFFFFF",
  upArrowFontSize = CONTENT_LEVEL6_FONT_SIZE,
  upArrowFontBold = false,
  upArrowText = "ENTERPRISE PIPELINE",

  descriptions = ["top1\ntop2", "middle1\nmiddle2", "bottom1\nbottom2"],
  descriptionsTextColor = "#000000",
  descriptionsFontSize = CONTENT_LEVEL5_FONT_SIZE,

  bulletType = BULLET_TYPE_DEFAULT,
  bulletCode = BULLET_CODE_DEFUALT,
  bulletIndent = LIST_INDENT,
  bulletColor = "#000000",

  arrowsColor = "#000000",
  arrowsHead = "triangle",
  arrowsTail = undefined,
  arrowsWidth = 1,

  pyramidLevels = ["top", "middle", "bottom"],
  pyramidColors = ["#F4D03F", "#AF7AC5", "#2980B9"],
  pyramidTextColor = "#FFFFFF",
  pyramidFontSize = CONTENT_LEVEL6_FONT_SIZE,
  pyramidTextFrameInset = "2vh",
}) => {
  // const frameInset = "3vw";
  const frameX = containerX;
  const frameY = containerY;
  const frameW = containerW;
  const frameH = containerH;

  const upArrrowW = "6vw";
  const upArrow = (
    <Shape
      shape="up_arrow"
      fill={upArrowColor}
      x={frameX}
      y={frameY}
      w={upArrrowW}
      h={frameH}
      avLst={{ adj1: 75000, adj2: 50000 }}
    >
      <Text
        color={upArrowTextColor}
        align="center"
        valign="center"
        text={upArrowText}
        fontSize={upArrowFontSize}
        bold={upArrowFontBold}
        vert="vert270"
      >
        {upArrowText}
      </Text>
    </Shape>
  );

  const pyramidW = `calc(${frameW}/2 - 10vw)`;
  const pyramidX = `calc(${frameX} + ${frameW} - ${pyramidW} - 2vw)`;
  const pyramidY = frameY;
  const pyramidH = frameH;

  const pyramidPositions = pyramidLevels.map((pyramidLevel, idx) => {
    const x = `calc(${pyramidX} + 0.5 * (${pyramidW} * (${
      pyramidLevels.length - (idx + 1)
    } / ${pyramidLevels.length})))`;
    const y = pyramidY;
    const w = `calc(${pyramidW} * (${idx + 1} / ${pyramidLevels.length}))`;
    const h = `calc(${pyramidH} * (${idx + 1} / ${pyramidLevels.length}))`;

    const textX = `calc(${x} + ${pyramidTextFrameInset})`;
    const textY = `calc(${pyramidY} + (${pyramidH} * (${idx} / ${pyramidLevels.length})) + ${pyramidTextFrameInset})`;
    const textH = `calc((${pyramidH} / ${pyramidLevels.length}) - 2 * ${pyramidTextFrameInset})`;
    const textW = `calc(${w} - 2 * ${pyramidTextFrameInset})`;

    return {
      x,
      y,
      w,
      h,
      textX,
      textY,
      textH,
      textW,
    };
  });

  const pyramidLevelsComponents = pyramidLevels.map((pyramidLevel, idx) => {
    const { x, y, w, h, textX, textY, textH, textW } = pyramidPositions[idx];

    return (
      <Group key={`pyramid${idx}`}>
        <Shape
          x={x}
          y={y}
          h={h}
          w={w}
          fill={pyramidColors[idx]}
          shape="isosceles_triangle"
        />
        <Text
          x={textX}
          y={textY}
          h={textH}
          w={textW}
          color={pyramidTextColor}
          fontSize={pyramidFontSize}
          align="center"
          valign="center"
        >
          {pyramidLevel}
        </Text>
      </Group>
    );
  });

  const upArrowDescriptionsOffsetH = "1vw";
  const descriptionsX = `calc(${frameX} + ${upArrrowW} + ${upArrowDescriptionsOffsetH})`;
  const descriptionsWInset = "-20vw";
  const descriptionsW = `calc(${pyramidX} - ${descriptionsX} + ${descriptionsWInset})`;
  const descriptionsComponents = pyramidPositions.map(
    ({ textY, textH }, idx) => {
      return (
        <Text
          key={`description k${idx}`}
          x={descriptionsX}
          y={textY}
          w={descriptionsW}
          h={textH}
          align="left"
          valign="center"
          color={descriptionsTextColor}
          fontSize={descriptionsFontSize}
          bullet={
            descriptions[idx].indexOf("\n") !== -1
              ? {
                  type: bulletType,
                  code: bulletCode,
                  indent: bulletIndent,
                  color: bulletColor,
                }
              : false
          }
        >
          {descriptions[idx]}
        </Text>
      );
    }
  );

  const descriptionsArrowsOffsetLeft = "5vw";
  const descriptionsArrowsOffsetRight = descriptionsArrowsOffsetLeft;
  const arrowsX = `calc(${descriptionsX} + ${descriptionsW} + ${descriptionsArrowsOffsetLeft})`;
  const arrows = pyramidPositions.map(({ x, textY, textH }, idx) => {
    return (
      <Line
        key={`arrow k${idx}`}
        x={arrowsX}
        y={`calc(${textY} + (${textH} / 2))`}
        w={`calc(${x} - ${arrowsX} - ${descriptionsArrowsOffsetRight})`}
        h={0}
        width={arrowsWidth}
        color={arrowsColor}
        head={arrowsHead}
        tail={arrowsTail}
      />
    );
  });

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>
      {upArrow}
      {pyramidLevelsComponents.reverse()}
      {arrows}
      {descriptionsComponents}
    </Slide>
  );
};
