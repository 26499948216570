// LAYOUT_WIDE
// * width  33.87 cm
// * height 19.05 cm

export const LAYOUT_WIDTH_CM = 33.87;
export const LAYOUT_HEIGHT_CM = 19.05;

export const cmToVW = (cm) =>
  `${Number(100 * (cm / LAYOUT_WIDTH_CM)).toFixed(2)}vw`;
export const cmToVH = (cm) =>
  `${Number(100 * (cm / LAYOUT_HEIGHT_CM)).toFixed(2)}vh`;

export const OFFSET_LEFT = cmToVW(1.35); // "6.88vw"; // 2.33cm / 33.87cm
export const OFFSET_RIGHT = OFFSET_LEFT;
export const OFFSET_TOP = cmToVH(1); // "5.30vh"; // 1.01cm / 19.05cm
export const OFFSET_BOTTOM = cmToVH(1.89); // "9.92vh"; // 1.89cm / 19.05cm

export const DEFAULT_VERTICAL_SPACE = "2.04vh"; // (5.07cm - 4.69cm = 0.39cm)/ 19.05cm
export const GUTTER_SIZE = "1.26vw"; // 0.43cm / 33.87cm

export const TITLE_HEIGHT = cmToVH(2.4); // "19.31vh"; // 3.68cm / 33.87cm

export const INNER_X = OFFSET_LEFT;
export const INNER_Y = `calc(${OFFSET_TOP} + ${TITLE_HEIGHT} + ${DEFAULT_VERTICAL_SPACE})`; // "26.61vh"; // 5.07cm / 19.05cm
export const INNER_W = `calc(100vw - ${OFFSET_LEFT} - ${OFFSET_RIGHT})`;
export const INNER_H = `calc(100vh - ${INNER_Y} - ${OFFSET_BOTTOM})`;
export const INNER_CX = `calc(${INNER_X} + (${INNER_W} / 2))`;
export const INNER_CY = `calc(${INNER_Y} + (${INNER_H} / 2))`;

export const FOOTER_X = INNER_X;
export const FOOTER_Y = cmToVH(18.16); // "92.70vh"; // 17.66cm / 19.05cm
export const FOOTER_W = INNER_W;
export const FOOTER_H = cmToVH(0.65); // "5.3vh"; // 1.01cm / 19.05cm

// exactly as PowerPoint
export const TITLE_SLIDE_TITLE_FONT_SIZE = 48;
export const TITLE_SLIDE_SUBTITLE_FONT_SIZE = 20;

export const CONTENT_SLIDE_TITLE_FONT_SIZE = 40;
export const CONTENT_SLIDE_BODY_FONT_SIZE = 24;

export const CONTENT_LEVEL0_FONT_SIZE = 28;
export const CONTENT_LEVEL1_FONT_SIZE = 28;
export const CONTENT_LEVEL2_FONT_SIZE = 24;
export const CONTENT_LEVEL3_FONT_SIZE = 20;
export const CONTENT_LEVEL4_FONT_SIZE = 18;
export const CONTENT_LEVEL5_FONT_SIZE = 18;

export const SLIDE_FOOTER_FONT_SIZE = 12;

// our additions
export const CONTENT_LEVEL6_FONT_SIZE = 16;
export const CONTENT_LEVEL7_FONT_SIZE = 14;
export const CONTENT_LEVEL8_FONT_SIZE = 12;
export const CONTENT_LEVEL9_FONT_SIZE = 10;

export const LIST_INDENT = "2.21vw"; // 0.75cm / 33.87cm

export const BULLET_TYPE_DEFAULT = "bullet";
export const BULLET_CODE_DEFUALT = 2022; // •
