import { createContext, useContext } from "react";

export const DeckContext = createContext({});

export const useLogo = () => {
  return useContext(DeckContext).logo;
};

export const useColorScheme = () => {
  return useContext(DeckContext).colorScheme;
};
