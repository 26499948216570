import React from "react";

import {
  Text,
  Slide,
  Group,
  Shape,
  Line,
} from "../../slides-engine/smartElements.jsx";

import {
  CONTENT_LEVEL3_FONT_SIZE,
  CONTENT_LEVEL4_FONT_SIZE,
} from "../layoutConfig.js";

const Point = ({
  titleUnder,
  centerX,
  centerY,
  color,
  titleColor,
  titleFontSize = CONTENT_LEVEL4_FONT_SIZE,
  textColor,
  title,
  description,
  descriptionFontSize = CONTENT_LEVEL3_FONT_SIZE,
  pointRadius = "2vh",
}) => {
  const titleY = titleUnder
    ? `calc(${centerY} + ${pointRadius})`
    : `calc(${centerY} - 10vh - ${pointRadius})`;

  const lineY = titleUnder
    ? `calc(${centerY} - 2 * ${pointRadius} - 10vh)`
    : `calc(${centerY} + 2 * ${pointRadius})`;

  const descriptionY = titleUnder
    ? `calc(${centerY} - 2 * ${pointRadius} - 20vh)`
    : `calc(${centerY} + 2 * ${pointRadius} + 10vh)`;

  return (
    <Group>
      <Text
        type="body"
        color={titleColor}
        fontSize={titleFontSize}
        align="center"
        valign={titleUnder ? "top" : "bottom"}
        x={`calc(${centerX} - 5vw)`}
        y={titleY}
        w="10vw"
        h="10vh"
      >
        {title}
      </Text>
      <Shape
        shape="oval"
        fill={color}
        x={`calc(${centerX} - ${pointRadius})`}
        y={`calc(${centerY} - ${pointRadius})`}
        h={`calc(2 * ${pointRadius})`}
        w={`calc(2 * ${pointRadius})`}
      />
      <Line
        x={centerX}
        y={lineY}
        h="10vh"
        w={0}
        color={textColor}
        dash="dash"
      />
      <Text
        x={`calc(${centerX} - 7.5vw)`}
        y={descriptionY}
        w="15vw"
        h="10vh"
        type="body"
        color={textColor}
        fontSize={descriptionFontSize}
        align="center"
        valign={titleUnder ? "bottom" : "top"}
      >
        {description}
      </Text>
    </Group>
  );
};

export default ({
  masterName,
  slideId,

  timelineColor = "#000000",
  textColor = "#000000",
  descriptionColor = "#545454",
  pointColor = timelineColor,

  title = "Title",

  point1Title = "point1",
  point1Description = "point1 description",

  point2Title = "point2",
  point2Description = "point2 description",

  point3Title = "point3",
  point3Description = "point3 description",

  point4Title = "point4",
  point4Description = "point4 description",

  point5Title = "point5",
  point5Description = "point5 description",
}) => (
  <Slide slideId={slideId} master={masterName}>
    <Text placeholder="title">{title}</Text>

    <Line x="10%" y="55%" h={0} w="80%" width={3} color={timelineColor} />

    <Point
      centerX="10%"
      centerY="55%"
      color={pointColor}
      titleColor={textColor}
      textColor={descriptionColor}
      title={point1Title}
      description={point1Description}
    />

    <Point
      titleUnder
      centerX="30%"
      centerY="55%"
      color={pointColor}
      titleColor={textColor}
      textColor={descriptionColor}
      title={point2Title}
      description={point2Description}
    />

    <Point
      centerX="50%"
      centerY="55%"
      color={pointColor}
      titleColor={textColor}
      textColor={descriptionColor}
      title={point3Title}
      description={point3Description}
    />
    <Point
      titleUnder
      centerX="70%"
      centerY="55%"
      color={pointColor}
      titleColor={textColor}
      textColor={descriptionColor}
      title={point4Title}
      description={point4Description}
    />

    <Point
      centerX="90%"
      centerY="55%"
      color={pointColor}
      titleColor={textColor}
      textColor={descriptionColor}
      title={point5Title}
      description={point5Description}
    />
  </Slide>
);
