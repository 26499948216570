import { createContext, useContext } from "react";

export const StateContext = createContext(null);

export const useApp = () => {
  return useContext(StateContext);
};

export const usePresentation = () => {
  const app = useApp();
  return app && app.presentation;
};
