import React from "react";
import { observer } from "mobx-react";

import { Image, Rect } from "../../slides-engine/smartElements.jsx";

export default observer(
  ({
    data,
    x,
    y,
    w,
    h,
    fallbackFill = "#FFFFFF",
    fallbackOpacity = 0,
    ...props
  }) => {
    return (
      <>
        {data ? (
          <Image data={data} x={x} y={y} w={w} h={h} {...props} />
        ) : (
          <Rect
            x={x}
            y={y}
            w={w}
            h={h}
            fill={fallbackFill}
            opacity={fallbackOpacity}
            {...props}
          />
        )}
      </>
    );
  }
);
