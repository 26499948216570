import { loadStripe } from "@stripe/stripe-js";
import {
  SUPPORT_EMAIL,
  STRIPE_PATHS,
  STRIPE_PRICE_ID,
  STRIPE_PUBLISHABLE_KEY,
  CHECKOUT_ID_KEY,
  CHECKOUT_STATES,
  CHECKOUT_STATUS_KEY,
} from "./constants";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const buyFromStripe = async () => {
  const stripe = await stripePromise;

  const { localStorage } = window;

  const checkoutId = (
    Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
  ).toLowerCase();
  localStorage.setItem(CHECKOUT_ID_KEY, checkoutId);
  localStorage.setItem(CHECKOUT_STATUS_KEY, CHECKOUT_STATES.CHECKOUT);

  const appUrl = window.location.origin;
  const successUrl = `${appUrl}/?${STRIPE_PATHS.SUCCESS}=${checkoutId}`;
  const cancelUrl = `${appUrl}/?${STRIPE_PATHS.CANCEL}=${checkoutId}`;

  const price = STRIPE_PRICE_ID;

  const stripeSessionIdEndpoint = `${window.location.origin}/.netlify/functions/generate-stripe-session-id`;
  const stripeSessionIdResp = await fetch(stripeSessionIdEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      price,
      success_url: successUrl,
      cancel_url: cancelUrl,
    }),
  });
  if (!stripeSessionIdResp.ok) {
    throw Error(stripeSessionIdResp.statusText);
  }
  const stripeSessionIdBody = await stripeSessionIdResp.json();

  const config = {
    sessionId: stripeSessionIdBody.id,
  };
  const { error } = await stripe.redirectToCheckout(config);
  // If `redirectToCheckout` fails due to a browser or network
  // error, display the localized error message to your customer
  // using `error.message`.
  if (error) {
    console.error(error);
  }
};

export const stripeCheckoutInfo = ({ location, localStorage, history }) => {
  let purchased = false,
    cancelled = false,
    checkoutId = null;

  const { search } = location;
  const urlParams = new URLSearchParams(search);
  let setFromUrl = false;

  for (const [k, v] of urlParams.entries()) {
    const storageCheckoutId = localStorage.getItem(CHECKOUT_ID_KEY);

    if (v !== storageCheckoutId) continue;

    if (k === STRIPE_PATHS.SUCCESS) {
      checkoutId = v;
      localStorage.setItem(CHECKOUT_STATUS_KEY, CHECKOUT_STATES.PURCHASED);
      setFromUrl = true;
      history.replaceState({}, document.title, window.location.pathname);
    } else if (k === STRIPE_PATHS.CANCEL) {
      checkoutId = v;
      localStorage.setItem(CHECKOUT_STATUS_KEY, CHECKOUT_STATES.CANCELLED);
      setFromUrl = true;
      history.replaceState({}, document.title, window.location.pathname);
    }
  }

  const checkoutStatus = localStorage.getItem(CHECKOUT_STATUS_KEY);

  purchased = checkoutStatus === CHECKOUT_STATES.PURCHASED;
  cancelled = checkoutStatus === CHECKOUT_STATES.CANCELLED;

  return { purchased, cancelled, checkoutId, setFromUrl };
};

export const sendPresentationEmail = async (email, presentationBlob) => {
  const presignedEndpoint = `${window.location.origin}/.netlify/functions/generate-presigned-url`;
  const presignedResp = await fetch(presignedEndpoint);
  const presignedBody = await presignedResp.json();
  // console.log("presignedBody", presignedBody);
  const { upload_url: uploadUrl, download_url: downloadUrl } = presignedBody;
  // alert(`upload: ${uploadUrl}\ndownload: ${downloadUrl}`);
  // console.log(presentationBlob);
  const uploadResp = await fetch(uploadUrl, {
    method: "PUT",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    },
    body: presentationBlob,
  });
  // console.log(uploadResp);
  if (!uploadResp.ok) {
    throw Error(uploadResp.statusText);
  }

  const emailLinkEndpoint = `${window.location.origin}/.netlify/functions/email-template-link`;
  const emailLinkResp = await fetch(emailLinkEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      template_url: downloadUrl,
    }),
  });
  // console.log(emailLinkResp);
  if (!emailLinkResp.ok) {
    throw Error(emailLinkResp.statusText);
  }
};

export const mailToSupportUrl = () => {
  const to = SUPPORT_EMAIL;
  const subject = encodeURIComponent("Bulletpoints.studio Support Request");
  const body = encodeURIComponent(
    [
      "Dear Bulletpoints.studio Support Team,",
      "",
      "I would like you to help me with the following request:",
      "",
      "...DESCRIBE YOUR HELP REQUEST HERE...",
      "",
      "Kind regards,",
      "_",
    ].join("\n")
  );
  const url = `mailto:${to}?subject=${subject}&body=${body}`;
  return url;
};
