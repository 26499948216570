import React from "react";
import { Group, Text } from "../../slides-engine/smartElements.jsx";

import {
  COLOR_DEFAULT_DARK1,
  COLOR_DEFAULT_ACCENT1,
} from "../models/colorScheme";
import {
  cmToVW,
  cmToVH,
  CONTENT_LEVEL4_FONT_SIZE,
  CONTENT_LEVEL9_FONT_SIZE,
} from "../layoutConfig.js";

export const DEFAULT_X = 0;
export const DEFAULT_Y = 0;
export const DEFAULT_W = "100vw";
export const DEFAULT_H = "100vh";

export const DEFAULT_TITLE_COLOR = COLOR_DEFAULT_ACCENT1;
export const DEFAULT_TITLE_FONT_SIZE = CONTENT_LEVEL4_FONT_SIZE;
export const DEFAULT_TITLE_FONT_BOLD = true;

export const DEFAULT_SUBTITLE_COLOR = COLOR_DEFAULT_DARK1;
export const DEFAULT_SUBTITLE_FONT_SIZE = CONTENT_LEVEL9_FONT_SIZE;
export const DEFAULT_SUBTITLE_FONT_BOLD = false;

export const DEFAULT_RADIUS_PCT = 0.65;
export const DEFAULT_ITEM_W = `${cmToVW(5.2)}`;
export const DEFAULT_ITEM_H = `${cmToVH(2)}`;
export const DEFAULT_ITEM_TITLE_H = `${cmToVH(0.8)}`;

export default ({
  x = DEFAULT_X,
  y = DEFAULT_Y,
  w = DEFAULT_W,
  h = DEFAULT_H,

  titleColor = DEFAULT_TITLE_COLOR,
  titleFontSize = DEFAULT_TITLE_FONT_SIZE,
  titleFontBold = DEFAULT_TITLE_FONT_BOLD,

  subtitleColor = DEFAULT_SUBTITLE_COLOR,
  subtitleFontSize = DEFAULT_SUBTITLE_FONT_SIZE,
  subtitleFontBold = DEFAULT_SUBTITLE_FONT_BOLD,

  radiusPct = DEFAULT_RADIUS_PCT,
  itemW = DEFAULT_ITEM_W,
  itemH = DEFAULT_ITEM_H,
  itemTitleH = DEFAULT_ITEM_TITLE_H,

  items = [],
}) => {
  const cx = `calc(${x} + 0.5 * ${w})`;
  const cy = `calc(${y} + 0.5 * ${h})`;

  const theta = (Math.PI * 2) / items.length;
  const radius = `calc(${radiusPct} * ${h} / 2)`;
  const angleOffset = Math.PI / 2 + (0.5 * (2 * Math.PI)) / items.length;
  return items
    .map((item, idx) => {
      const angle = theta * (idx + 1) - angleOffset;

      const isRightHalf = angle >= -Math.PI / 2 && angle <= Math.PI / 2;

      const cos = Math.cos(angle).toFixed(2);
      const sin = Math.sin(angle).toFixed(2);

      const w = itemW;
      const h = itemH;
      const x = `calc(${cx} + ${radius} * ${cos} - ${isRightHalf ? 0 : w})`;
      const y = `calc(${cy} + ${radius} * ${sin} - 0.5 * ${h})`;

      const { title, subtitle } = item;

      // FIXME: this approach to layouting becomes a mess
      // we must figure out a way to style the <text .../>
      const titleEl = title ? (
        <Text
          x={x}
          y={y}
          w={w}
          h={itemTitleH}
          color={titleColor}
          fontSize={titleFontSize}
          bold={titleFontBold}
          align={isRightHalf ? "left" : "right"}
          valign={"bottom"}
        >
          {title}
        </Text>
      ) : null;
      const subtitleEl = subtitle ? (
        <Text
          x={x}
          y={`calc(${y} + ${itemTitleH} - ${cmToVH(0.35)})`}
          w={w}
          h={`calc(${h} - ${itemTitleH})`}
          color={subtitleColor}
          fontSize={subtitleFontSize}
          bold={subtitleFontBold}
          align={isRightHalf ? "left" : "right"}
          valign={"top"}
        >
          {subtitle}
        </Text>
      ) : null;

      return (
        <Group key={idx.toString()}>
          {titleEl}
          {subtitleEl}
        </Group>
      );
    })
    .flat();
};
