import Reconciler from "react-reconciler";
import createElement from "./createElement";

const emptyObject = {};

// This is lifted from https://github.com/nitin42/redocx/blob/master/src/renderer/renderer.js
export default Reconciler({
  appendInitialChild(parentInstance, child) {
    if (parentInstance.appendChild) {
      parentInstance.appendChild(child);
    } else {
      parentInstance.document = child;
    }
  },

  createTextInstance(text, rootContainerInstance, internalInstanceHandle) {
    return text;
  },

  finalizeInitialChildren(wordElement, type, props) {
    return false;
  },

  getPublicInstance(inst) {
    return inst;
  },

  prepareForCommit() {
    // noop
  },

  prepareUpdate(wordElement, type, oldProps, newProps) {
    return true;
  },

  resetAfterCommit() {
    // noop
  },

  resetTextContent(wordElement) {
    // Redocx does not have a text node like DOM
  },

  // If you've such use case where you need to provide data from the root instance,
  // see the below example. This may help you in creating your own custom renderer

  createInstance(type, props, internalInstanceHandle) {
    // 'internalInstanceHandle' is not transparent here. So use host context methods
    // to get data from roots
    return createElement(type, props);
  },

  // Use this current instance to pass data from root
  getRootHostContext(instance) {},

  getChildHostContext() {
    return emptyObject;
  },

  shouldSetTextContent(type, props) {
    return false;
  },

  now: Date.now,

  useSyncScheduling: true,
  supportsMutation: true,

  appendChildToContainer: (parent, child) => {
    parent.appendChild(child);
  }
});
