import "typeface-poppins";

import { createGlobalStyle } from "styled-components/macro";

export default createGlobalStyle`

  :root {
    --color-primary: #4682b4;
    --color-primary-light: #e9f0f6;
    --color-primary-dark: #325d81;
    --color-secondary: #d49991;
    --color-secondary-light: #f2e0de;
    --color-secondary-dark: #c0695d;
  }

  body {
    color: #444;
    font-family: 'Poppins', sans-serif;
        margin: 0;
  }

 * {
    box-sizing: border-box;
  }

  .reactive-modal-content {
    position: relative;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;

    min-width: 50%;
    max-width: 95%;
    max-height: 95%;
        
    padding: 30px 20px;
    border-style: none;
    border-radius: 3px;

    overflow: auto;

    outline: none;

    @media screen and (max-width: 450px) {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      max-height: 90%;
    }
  }

  .reactive-modal-overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1000;
    display: flex;
    backdrop-filter: blur(1px);
    background-color: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;


  @media screen and (max-width: 450px) {
    align-items: flex-end;
  }

  }
`;
