import styled from "styled-components/macro";

export default styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.6);
  margin-top: 0px !important;
  user-select: none;
`;

export const OverlayMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(238, 238, 238);
  font-size: 0.875em;
  height: 150px;
  padding: 2em;
  text-align: center;
  width: 100%;

  & p {
    margin: 0;
  }
`;
