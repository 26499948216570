import { types, flow } from "mobx-state-tree";
import * as Mixpanel from "mixpanel-browser";

import { PresentationState } from "./presentation";
import { presentationAsBlob, presentationAsPPTX } from "../presentation/index";
import { buyFromStripe, sendPresentationEmail } from "../backendServices";

export const AppState = types
  .model("AppState", {
    email: types.optional(types.string, ""),
    sendingEmail: types.optional(types.boolean, false),
    isPurchased: types.optional(types.boolean, false),
    presentation: PresentationState,

    showPurchaseInfo: types.optional(types.boolean, false),
    showCheckoutInfo: types.optional(types.boolean, false),
    showResetDialog: types.optional(types.boolean, false),
    modalInfo: types.optional(types.string, ""),
  })
  .views((self) => ({
    get modalIsOpen() {
      return (
        this.showPurchaseInfo ||
        !!self.modalInfo ||
        self.showCheckoutInfo ||
        self.showResetDialog
      );
    },
  }))
  .actions((self) => ({
    download() {
      Mixpanel.track("download#onclick");
      presentationAsPPTX({
        deck: self.presentation.deck,
        slides: self.presentation.slides,
        slideMasters: self.presentation.slideMasters,
        defaultImages: self.presentation.images,
      });
    },
    downloadSample() {
      Mixpanel.track("sample#onclick");
      presentationAsPPTX({
        deck: self.presentation.deck,
        slides: self.presentation.sampleSlides,
        slideMasters: self.presentation.sampleSlideMasters,
        defaultImages: self.presentation.images,
        title: "bulletpoints.studio_sample_template.pptx",
      });
    },

    emailPresentation: flow(function* emailPresentation() {
      if (!self.email) {
        return;
      }

      self.sendingEmail = true;
      self.modalInfo = "Sending email...";

      try {
        const presentationBlob = yield presentationAsBlob({
          deck: self.presentation.deck,
          slides: self.presentation.slides,
          slideMasters: self.presentation.slideMasters,
          defaultImages: self.presentation.images,
        });

        yield sendPresentationEmail(self.email, presentationBlob);

        self.sendingEmail = false;
        self.modalInfo = "";
        self.email = "";
        self.showPurchaseInfo = false;

        return null;
      } catch (e) {
        self.modalInfo = "";
        self.sendingEmail = false;
        return e;
      }
    }),

    askForCheckout() {
      self.showCheckoutInfo = true;
    },

    openResetDialog() {
      self.showResetDialog = true;
    },

    buy() {
      Mixpanel.track("buy#onclick");
      self.closeModal();
      self.modalInfo = "Redirecting to checkout...";
      if (!self.isPurchased) {
        buyFromStripe();
      }
    },

    closeModal() {
      self.showPurchaseInfo = false;
      self.showResetDialog = false;
      self.showCheckoutInfo = false;
      self.modalInfo = "";
    },

    updateEmail(updatedEmail) {
      self.email = updatedEmail;
    },

    reset() {
      Mixpanel.track("reset#onclick");

      window.localStorage.clear();
      self.presentation.reset();
      self.isPurchased = false;
      self.showPurchaseInfo = false;
      self.email = "";
      self.sendingEmail = false;
    },
  }));
