import React from "react";
import ImageWrapper from "./ImageWrapper.jsx";
import { observer } from "mobx-react";

import { Placeholder } from "../../slides-engine/smartElements.jsx";

import {
  TITLE_HEIGHT,
  OFFSET_LEFT,
  OFFSET_TOP,
  OFFSET_RIGHT,
  CONTENT_SLIDE_TITLE_FONT_SIZE,
} from "../layoutConfig.js";

export const TITLE_PLACEHOLDER_NAME = "title";

export default observer(
  ({
    titleX = OFFSET_LEFT,
    titleY = OFFSET_TOP,
    titleW = `calc(100% - ${OFFSET_LEFT} - ${OFFSET_RIGHT})`,
    titleH = TITLE_HEIGHT,
    titleColor = "#000000",
    titleFontSize = CONTENT_SLIDE_TITLE_FONT_SIZE,

    logo = null, // useLogo()
    logoW = "15vw",
    logoOffsetY = "1vw",
    logoY = `calc(${titleY} + ${logoOffsetY})`,
    logoX = `calc(${titleX} + ${titleW} - ${logoW})`,
    logoH = `calc(${titleH} - 2 * ${logoOffsetY})`,
  }) => {
    return (
      <>
        {logo ? (
          <ImageWrapper
            {...logo}
            objectFit="contain"
            objectAlign="right"
            x={logoX}
            y={logoY}
            w={logoW}
            h={logoH}
          />
        ) : null}

        <Placeholder
          name={TITLE_PLACEHOLDER_NAME}
          type="title"
          color={titleColor}
          fontSize={titleFontSize}
          align="left"
          valign="top"
          x={titleX}
          y={titleY}
          w={titleW}
          h={titleH}
        >
          Click to add title
        </Placeholder>
      </>
    );
  }
);
