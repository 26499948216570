import React from "react";

import {
  Master,
  Text,
  Image,
  Slide,
  Placeholder,
} from "../../slides-engine/smartElements.jsx";

import { TITLE_SLIDE_TITLE_FONT_SIZE } from "../layoutConfig.js";

export const MASTER_NAME = "Inspiration Quote";
export const MASTER_PLACEHOLDERS = {
  title: "title",
};

export default ({ image, darkColor, lightColor }) => {
  return (
    <Master title={MASTER_NAME}>
      <Placeholder
        name={MASTER_PLACEHOLDERS.title}
        type="title"
        font={TITLE_SLIDE_TITLE_FONT_SIZE}
        align="center"
        valign="center"
        x="5%"
        y="15%"
        w="90%"
        h="70%"
      />
      <Image
        {...image}
        x="0%"
        y="0%"
        w="100%"
        h="100%"
        objectFit="cover"
        colorBlend={{ darkColor, lightColor }}
        opacity={0.5}
      />
    </Master>
  );
};

export const Example = ({ quote, slideId }) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    <Text placeholder={MASTER_PLACEHOLDERS.title}>{quote}</Text>
  </Slide>
);
