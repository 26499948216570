import React from "react";
import { Text, Shape } from "../../slides-engine/smartElements.jsx";

import {
  COLOR_DEFAULT_LIGHT1,
  COLOR_DEFAULT_DARK1,
  COLOR_DEFAULT_ACCENT1,
} from "../models/colorScheme";
import {
  cmToVW,
  cmToVH,
  CONTENT_LEVEL8_FONT_SIZE,
  CONTENT_LEVEL9_FONT_SIZE,
  BULLET_TYPE_DEFAULT,
  BULLET_CODE_DEFUALT,
} from "../layoutConfig.js";

export const X_DEFAULT = 0;
export const Y_DEFAULT = 0;
export const W_DEFAULT = "100vw";
export const H_DEFAULT = "100vh";

export const DEFAULT_BULLET_TYPE = BULLET_TYPE_DEFAULT;
export const DEFUALT_BULLET_CODE = BULLET_CODE_DEFUALT;
export const DEFAULT_BULLET_INDENT = `${cmToVW(0.3)}vw`;
export const DEFAULT_BULLET_COLOR = COLOR_DEFAULT_ACCENT1;

export const DEFAULT_BULLET_W = `${cmToVW(3.75)}vw`;
export const DEFAULT_BULLET_H = `${cmToVH(1.2)}vw`;
export const DEFAULT_BULLET_FILL = COLOR_DEFAULT_ACCENT1;
export const DEFAULT_BULLET_RADIUS = 0.05;
export const DEFAULT_BULLET_TEXT_COLOR = COLOR_DEFAULT_LIGHT1;
export const DEFAULT_BULLET_TEXT_FONT_SIZE = CONTENT_LEVEL8_FONT_SIZE;
export const DEFAULT_BULLET_TEXT_FONT_BOLD = false;
export const DEFAULT_BULLET_TEXT_ALIGN = "center";
export const DEFAULT_BULLET_TEXT_VALIGN = "center";

export const DEFAULT_TEXT_COLOR = COLOR_DEFAULT_DARK1;
export const DEFAULT_TEXT_FONT_SIZE = CONTENT_LEVEL9_FONT_SIZE;
export const DEFAULT_TEXT_FONT_BOLD = false;
export const DEFAULT_TEXT_ALIGN = "left";
export const DEFAULT_TEXT_VALIGN = "center";

export default ({
  x = X_DEFAULT,
  y = Y_DEFAULT,
  w = W_DEFAULT,
  h = H_DEFAULT,

  bulletType = DEFAULT_BULLET_TYPE,
  bulletCode = DEFUALT_BULLET_CODE,
  bulletIndent = DEFAULT_BULLET_INDENT,
  bulletColor = DEFAULT_BULLET_COLOR,

  bulletW = DEFAULT_BULLET_W,
  bulletH = DEFAULT_BULLET_H,
  bulletFill = DEFAULT_BULLET_FILL,
  bulletRadius = DEFAULT_BULLET_RADIUS,
  bulletTextColor = DEFAULT_BULLET_TEXT_COLOR,
  bulletTextFontSize = DEFAULT_BULLET_TEXT_FONT_SIZE,
  bulletTextFontBold = DEFAULT_BULLET_TEXT_FONT_BOLD,
  bulletTextAlign = DEFAULT_BULLET_TEXT_ALIGN,
  bulletTextValign = DEFAULT_BULLET_TEXT_VALIGN,

  textColor = DEFAULT_TEXT_COLOR,
  textFontSize = DEFAULT_TEXT_FONT_SIZE,
  textFontBold = DEFAULT_TEXT_FONT_BOLD,
  textAlign = DEFAULT_TEXT_ALIGN,
  textValign = DEFAULT_TEXT_VALIGN,

  items = [],
}) => {
  const totalH = `calc(${items.length} * ${bulletH})`;
  const spacerH = `calc((${h} - ${totalH}) / ${items.length - 1 || 0})`;

  const bulletConf = {
    type: bulletType,
    code: bulletCode,
    indent: bulletIndent,
    color: bulletColor,
  };

  return items
    .map((item, idx) => {
      const {
        title,
        description,
        fill = bulletFill,
        radius = bulletRadius,
      } = item;

      const bulletX = x;
      const bulletY = `calc(${y} + ${idx} * (${bulletH} + ${spacerH}))`;
      const rectEl = (
        <Shape
          key={`bulletrect-${idx}-rect`}
          shape="rounded_rectangle"
          x={bulletX}
          y={bulletY}
          w={bulletW}
          h={bulletH}
          fill={fill}
          radius={radius}
        >
          <Text
            key={`bulletrect-${idx}-rect-text`}
            color={bulletTextColor}
            fontSize={bulletTextFontSize}
            bold={bulletTextFontBold}
            align={bulletTextAlign}
            valign={bulletTextValign}
          >
            {title}
          </Text>
        </Shape>
      );

      const textX = `calc(${bulletX} + ${bulletW})`;
      const textY = `calc(${bulletY} - ${cmToVH(0.25)}vh)`;
      const textW = `calc(${w} - ${bulletW})`;
      const textH = `calc(${bulletH} + 2 * ${cmToVH(0.25)}vh)`;
      const textEl = (
        <Text
          key={`bulletrect-${idx}-text`}
          x={textX}
          y={textY}
          w={textW}
          h={textH}
          color={textColor}
          fontSize={textFontSize}
          bold={textFontBold}
          align={textAlign}
          valign={textValign}
          bullet={Array.isArray(description) ? bulletConf : undefined}
        >
          {Array.isArray(description) ? description.join("\n") : description}
        </Text>
      );
      return [rectEl, textEl];
    })
    .flat();
};
