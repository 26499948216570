import React from "react";

import {
  Text,
  Slide,
  Line,
  Shape,
} from "../../slides-engine/smartElements.jsx";

import {
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  DEFAULT_VERTICAL_SPACE,
  GUTTER_SIZE,
  CONTENT_LEVEL6_FONT_SIZE,
} from "../layoutConfig.js";
import Grid from "../components/Grid.jsx";

export default ({
  masterName,
  slideId,
  title,

  containerX = INNER_X,
  containerY = INNER_Y,
  containerW = INNER_W,
  containerH = INNER_H,

  rowHeaderH = "4vh", // FIXME: extract -> layoutConfig

  col1W = `calc(0.2 * ${containerW})`,

  rowSeparatorWidth = 0.5, // FIXME: extract -> layoutConfig
  rowSeparatorColor = "#000000",
  rowSeparatorIncludeFirst = true,

  rowHeaderColor = "#000000",
  rowHeaderFontSize = CONTENT_LEVEL6_FONT_SIZE,
  rowHeaderFontBold = false,
  rowHeaderAlign = "left",
  rowHeaderValign = "bottom",

  columnSeparatorWidth = 0.5, // FIXME: extract -> layoutConfig
  columnSeparatorColor = "#000000",
  columnSeparatorIncludeFirst = true,

  columnHeaderFontSize = CONTENT_LEVEL6_FONT_SIZE,
  columnHeaderFontBold = false,
  columnHeaderColor = "#000000",
  columnHeaderAlign = "left",
  columnHeaderValign = "bottom",

  cellTextColor = "#000000",
  cellFontSize = CONTENT_LEVEL6_FONT_SIZE,
  cellFontBold = false,
  cellTextAutoFit = true,
  cellRectRadius = 0,
  cellAlign = "left",
  cellValign = "top",

  cellPaddingTop = DEFAULT_VERTICAL_SPACE,
  cellPaddingBottom = cellPaddingTop,
  cellPaddingLeft = `calc(2 * ${GUTTER_SIZE})`,
  cellPaddingRight = cellPaddingLeft,

  upArrowColor = "#4472C4",
  upArrowW = `calc(1.25 * ${GUTTER_SIZE})`,
  upArrowPaddingTop = `calc(0.3 * ${DEFAULT_VERTICAL_SPACE} + 0.1vh)`,
  upArrowPaddingBottom = `calc(0.2 * ${DEFAULT_VERTICAL_SPACE})`,

  rowHeaderTriangleOffsetLeft = "6vw",
  rowHeaderTriangleColor = "#4472C4",
  rowHeaderTriangleText = "",
  rowHeaderTriangleTextColor = "#000000",
  rowHeaderTriangleSize = CONTENT_LEVEL6_FONT_SIZE,
  rowHeaderTriangleBold = false,
  rowHeaderTriangleAutoFit = true,
  rowHeaderTriangleAlign = "right",
  rowHeaderTriangleValign = "bottom",

  rowHeaderArrowTriangleOffsetLeft = "1vw",
  rowHeaderArrowColor = rowHeaderTriangleColor,
  rowHeaderArrowWidth = 2.25,

  content = {
    headers: {
      columns: [],
      rows: [],
    },
    content: [],
  },
}) => {
  const gridEl = (
    <Grid
      x={containerX}
      y={containerY}
      w={containerW}
      h={containerH}
      columnHeaderW={col1W}
      columnHeaders={content.headers.columns}
      columnHeaderFontSize={columnHeaderFontSize}
      columnHeaderFontBold={columnHeaderFontBold}
      columnHeaderColor={columnHeaderColor}
      columnHeaderAlign={columnHeaderAlign}
      columnHeaderValign={columnHeaderValign}
      columnSeparatorWidth={columnSeparatorWidth}
      columnSeparatorColor={columnSeparatorColor}
      columnSeparatorIncludeFirst={columnSeparatorIncludeFirst}
      rowHeaderH={rowHeaderH}
      rowHeaders={content.headers.rows}
      rowHeaderColor={rowHeaderColor}
      rowHeaderFontSize={rowHeaderFontSize}
      rowHeaderFontBold={rowHeaderFontBold}
      rowHeaderAlign={rowHeaderAlign}
      rowHeaderValign={rowHeaderValign}
      rowSeparatorWidth={rowSeparatorWidth}
      rowSeparatorColor={rowSeparatorColor}
      rowSeparatorIncludeFirst={rowSeparatorIncludeFirst}
      cellTextColor={cellTextColor}
      cellFontSize={cellFontSize}
      cellFontBold={cellFontBold}
      cellTextAutoFit={cellTextAutoFit}
      cellRectRadius={cellRectRadius}
      cellAlign={cellAlign}
      cellValign={cellValign}
      cellPaddingTop={cellPaddingTop}
      cellPaddingBottom={cellPaddingBottom}
      cellPaddingLeft={cellPaddingLeft}
      cellPaddingRight={cellPaddingRight}
      upArrowColor={upArrowColor}
      upArrowW={upArrowW}
      upArrowPaddingTop={upArrowPaddingTop}
      upArrowPaddingBottom={upArrowPaddingBottom}
      content={content.content}
    />
  );

  const rowHeaderTriangleEl = (
    <>
      <Shape
        key="row-header-triangle"
        shape="right_triangle"
        x={`calc(${containerX} + ${cellPaddingLeft} + ${rowHeaderTriangleOffsetLeft})`}
        y={`calc(${containerY} + ${rowHeaderH} + ${cellPaddingTop})`}
        w={`calc(${col1W} - ${cellPaddingLeft} - ${rowHeaderTriangleOffsetLeft})`}
        h={`calc(${containerH} - ${rowHeaderH} - ${cellPaddingTop} - ${cellPaddingBottom})`}
        flipH={true}
        fill={rowHeaderTriangleColor}
      />
      <Text
        key="row-header-triangle-text"
        x={`calc(${containerX} + ${cellPaddingLeft} + ${rowHeaderTriangleOffsetLeft})`}
        y={`calc(${containerY} + ${rowHeaderH} + ${cellPaddingTop})`}
        w={`calc(${col1W} - ${cellPaddingLeft} - ${rowHeaderTriangleOffsetLeft})`}
        h={`calc(${containerH} - ${rowHeaderH} - ${cellPaddingTop} - ${cellPaddingBottom})`}
        color={rowHeaderTriangleTextColor}
        align={rowHeaderTriangleAlign}
        valign={rowHeaderTriangleValign}
        fontSize={rowHeaderTriangleSize}
        bold={rowHeaderTriangleBold}
        autoFit={rowHeaderTriangleAutoFit}
      >
        {rowHeaderTriangleText}
      </Text>
    </>
  );

  const col1ArrowEl = (
    <Line
      key={`line-arrow-up`}
      x={`calc(${containerX} + ${cellPaddingLeft} + ${rowHeaderTriangleOffsetLeft} - ${rowHeaderArrowTriangleOffsetLeft})`}
      y={`calc(${containerY} + ${rowHeaderH} + ${cellPaddingTop})`}
      w={`calc(${col1W} - ${cellPaddingLeft} - ${rowHeaderTriangleOffsetLeft})`}
      h={`calc(${containerH} - ${rowHeaderH} - ${cellPaddingTop} - ${cellPaddingBottom})`}
      flipH={true}
      head={"triangle"}
      // tail={"triangle"}
      color={rowHeaderArrowColor}
      width={rowHeaderArrowWidth}
    />
  );

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>

      {gridEl}

      {rowHeaderTriangleEl}
      {col1ArrowEl}
    </Slide>
  );
};
