const {
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_STRIPE_PRICE_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SUPPORT_EMAIL,
  REACT_APP_MIXPANEL_TOKEN,
} = process.env;

export const STRIPE_PUBLISHABLE_KEY = REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const STRIPE_PRICE_ID = REACT_APP_STRIPE_PRICE_ID;

export const STORAGE_KEY = "deck-store-v1";

export const STRIPE_PATHS = {
  SUCCESS: "stripe-checkout-success-id",
  CANCEL: "stripe-checkout-cancel-id",
};
export const CHECKOUT_ID_KEY = "stripe-checkout-id";
export const CHECKOUT_STATUS_KEY = "stripe-checkout-status";
export const CHECKOUT_STATES = {
  CHECKOUT: "checkout",
  PURCHASED: "purchased",
  CANCELLED: "cancelled",
};

export const CHECKOUT_MODAL_CLOSED_KEY = "checkout-modal-closed";

export const SENTRY_DSN = REACT_APP_SENTRY_DSN;

export const SUPPORT_EMAIL = REACT_APP_SUPPORT_EMAIL;

export const MIXPANEL_TOKEN = REACT_APP_MIXPANEL_TOKEN;
