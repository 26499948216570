import React, { useContext } from "react";
import styled from "styled-components/macro";

import { useMasterInfo } from "./Deck.js";
import { asHex } from "../svg-helpers";

const SVGSlide = styled.svg`
  font-family: ${(props) => props.fontFamily || "Arial"}, sans-serif;
  width: 100%;
  height: 100%;

  & .title-text {
    font-family: ${(props) =>
        props.titleFontFamily || props.fontFamily || "Arial"},
      sans-serif;
  }
`;

const emptyPlaceholders = new Map();

const SlideContext = React.createContext(emptyPlaceholders);
export const SlideWrapperContext = React.createContext(null);

export const useSlidePlaceholder = (placeholderId) => {
  const placeholders = useContext(SlideContext);
  if (placeholderId && placeholders.has(placeholderId)) {
    return placeholders.get(placeholderId);
  }
  return { props: {} };
};

export default React.memo(
  ({ children, master: masterId, style = {}, ...props }) => {
    const master = useMasterInfo(masterId);
    const Wrapper = useContext(SlideWrapperContext);

    const background = props.bkgd || master.bkgd || "#ffffff";
    if (background) {
      style.backgroundColor = asHex(background);
    }

    const slide = (
      <SVGSlide
        key={props.slideId}
        fontFamily={master.fontFamily}
        titleFontFamily={master.titleFontFamily}
        viewBox={master.viewBox}
        style={style}
        {...props}
      >
        <SlideContext.Provider value={master.placeholders || emptyPlaceholders}>
          {master.components}
          {children}
        </SlideContext.Provider>
      </SVGSlide>
    );

    if (Wrapper) return <Wrapper slideId={props.slideId}>{slide}</Wrapper>;
    return slide;
  }
);
