import React from "react";
import { Group, Text, Shape } from "../../slides-engine/smartElements.jsx";

export default ({
  topLeftX = 0,
  topLeftY = 0,
  circleWidth = "10vh",
  circleHeight = "10vh",
  circleColor = "000000",
  circleText = "",
  circleTextSize = 12,
  circleTextColor = "FFFFFF",
  subtitleText = "",
  subtitleTextSize = 10,
  subtitleTextColor = "000000",
}) => {
  const subtitleTopY = `calc(${topLeftY} + ${circleHeight})`;
  const subtitleHeight = `5vh`; // FIXME: calculate depending on the font

  return (
    <Group>
      <Shape
        shape="oval"
        fill={circleColor}
        x={topLeftX}
        y={topLeftY}
        h={circleWidth}
        w={circleHeight}
      />
      <Text
        type="body"
        color={circleTextColor}
        fontSize={circleTextSize}
        align="center"
        valign="center"
        x={topLeftX}
        y={topLeftY}
        h={circleWidth}
        w={circleHeight}
      >
        {circleText}
      </Text>
      {subtitleText && subtitleText.length && (
        <Text
          type="body"
          color={subtitleTextColor}
          fontSize={subtitleTextSize}
          align="center"
          valign="top"
          x={topLeftX}
          y={subtitleTopY}
          h={subtitleHeight}
          w={circleHeight}
        >
          {subtitleText}
        </Text>
      )}
    </Group>
  );
};
