import React from "react";
import { Text, Slide } from "../../slides-engine/smartElements.jsx";

import ImageGrid from "../components/ImageGrid";
import { INNER_X, INNER_Y, INNER_W, INNER_H } from "../layoutConfig.js";

export default ({
  masterName,
  slideId,
  title,

  containerX = INNER_X,
  containerY = INNER_Y,
  containerW = INNER_W,
  containerH = INNER_H,

  content = [],
}) => {
  const gridEl = (
    <ImageGrid
      x={containerX}
      y={containerY}
      w={containerW}
      h={containerH}
      content={content}
    />
  );

  return (
    <Slide slideId={slideId} master={masterName}>
      <Text placeholder="title">{title}</Text>

      {gridEl}
    </Slide>
  );
};
