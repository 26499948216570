import styled from "styled-components/macro";


export const DialogContent = styled.div`
  display: flex;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    & > :not(:first-child) {
      margin-top: 40px;
    }
  }

  @media screen and (min-width: 650px) {
    & > :not(:first-child) {
      margin-left: 40px;
    }
  }
`;

export const DialogTitle = styled.div`
  font-size: 1.5em;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  flex: 1 0 auto;
`;

export const DialogSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }

  & > div {
    width: 100%;
  }
`;

export const HorizontalDivider = styled.div`
  position: relative;
  background: lightgrey;
  flex-shrink: 0;

  height: 1px;
  width: 100%;
`;

export const OrDivider = styled.div`
  position: relative;
  background: lightgrey;
  flex-shrink: 0;

  @media screen and (max-width: 649px) {
    margin: auto 0;
    height: 1px;
  }

  @media screen and (min-width: 650px) {
    margin: 0 auto;
    width: 1px;
  }

  &::after {
    content: "or";
    position: absolute;
    top: 48%;
    left: -15px;

    transform: translateY(-50%);

    @media screen and (max-width: 649px) {
      left: calc(50% - 15px);
    }

    background: #fff;
    line-height: 32px;
    text-align: center;
    width: 32px;
  }
`;
