import React from "react";
import styled from "styled-components/macro";
import { observer } from "mobx-react";
import { useSnackbar } from "react-simple-snackbar";

import emailIcon from "../images/email.svg";

import { Button } from "./Button.jsx";
import { PresetInput } from "./ColorScheme.jsx";
import { Logo } from "./LogoDropzone.jsx";
import Sidebar from "./Sidebar.jsx";
import ResetButton from "./ResetButton.jsx";

import { useApp, usePresentation } from "../state/context";
import {
  DialogTitle,
  DialogSection,
  DialogContent,
  OrDivider,
  HorizontalDivider,
} from "./DialogSections.jsx";

// from https://stackoverflow.com/a/1373724
const EMAIL_RE = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const isValidEmail = (email) => {
  return EMAIL_RE.test(email);
};

const DownloadLogo = styled(Logo)`
  width: 100%;
  border-color: #eee;
`;

const NoLogo = styled.div`
  width: 100%;
  padding: 20px;
  opacity: 0.5;
  text-align: center;
  border: 1px solid #999;
`;

const SendIcon = styled.img`
  height: 100px;
  width: 100px;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:first-child) {
    margin-top: 8px;
  }
`;

const Input = styled.input`
  font-size: 1.2em;
  border: 1px solid var(--color-primary);
  border-radius: 0.1em;
  height: 55px;
  padding: 0 12px;
  transition: box-shadow 300ms;
  width: 100%;

  &::placeholder {
    color: lightgray;
  }

  &:focus {
    box-shadow: 0 0 1px 0 var(--color-primary);
    outline: none;
  }

  &:not(:focus):invalid {
    box-shadow: none;
  }
`;

const Label = styled.label`
  left: 8px;
  background-color: #fff;
  padding: 2px 4px;
  font-size: 0.75em;
`;

const CurrentChoicesPreview = observer(({ children }) => {
  const presentationState = usePresentation();

  return (
    <DialogSection>
      {presentationState.deck.logo.data ? (
        <DownloadLogo src={presentationState.deck.logo.data} />
      ) : (
        <NoLogo>No Logo</NoLogo>
      )}
      <PresetInput
        selected={presentationState.deck.colorScheme.preset}
        presets={presentationState.deck.colorScheme.palettePresets}
        presetId={presentationState.deck.colorScheme.preset}
        noRadio
      >
        {presentationState.deck.colorScheme.presetTitle(
          presentationState.deck.colorScheme.preset
        )}
      </PresetInput>
      {children}
    </DialogSection>
  );
});

const EmailPresentation = observer(() => {
  const appState = useApp();
  const [openSnackbar] = useSnackbar({
    position: "top-center",
  });
  const [openErrorSnackbar] = useSnackbar({
    position: "top-center",
    style: {
      backgroundColor: "var(--color-secondary-dark)",
    },
  });

  const emailPresentationOnClick = async (e) => {
    e.preventDefault();
    const emailSent = appState.email;

    const error = await appState.emailPresentation();
    if (!error)
      openSnackbar(
        `Template download URL has been emailed to ${emailSent}. Please check your inbox.`
      );
    else {
      openErrorSnackbar(
        "Unable to send the email – please try again later or contact support",
        60000
      );
      // Sentry should catch it
      throw error;
    }
  };

  return (
    <DialogSection as="form" onSubmit={emailPresentationOnClick}>
      <SendIcon src={emailIcon} alt="envelope icon" />
      <InputBox>
        <Label>Email me a link to the template</Label>
        <Input
          required
          type="email"
          placeholder="your@email.com"
          value={appState.email}
          onChange={(e) => appState.updateEmail(e.target.value)}
        />
      </InputBox>
      <Button
        solid
        disabled={!isValidEmail(appState.email)}
        type="submit"
        color="primary"
        width="100%"
        css="font-size: 0.75em"
      >
        SEND
      </Button>
    </DialogSection>
  );
});

export default observer(() => {
  const appState = useApp();

  return (
    <>
      <DialogTitle>Thank you for your purchase!</DialogTitle>

      <DialogContent>
        <CurrentChoicesPreview>
          <Button solid color="primary" onClick={appState.download}>
            DOWNLOAD
          </Button>
        </CurrentChoicesPreview>
        <OrDivider />
        <EmailPresentation />
      </DialogContent>
    </>
  );
});

export const AccessPurchaseSidebar = observer(() => {
  return (
    <Sidebar>
      <CurrentChoicesPreview />
      <HorizontalDivider />
      <EmailPresentation />
      <HorizontalDivider />
      <div css="font-size: 0.75em;">
        You can only access this template. You need to reset to create a new
        one.
      </div>
      <ResetButton />
    </Sidebar>
  );
});
