import React from "react";
import { observer } from "mobx-react";
import ImageWrapper from "../components/ImageWrapper.jsx";

import {
  Master,
  Text,
  Slide,
  Placeholder,
  Line,
  Rect,
} from "../../slides-engine/smartElements.jsx";

import {
  OFFSET_LEFT,
  OFFSET_RIGHT,
  OFFSET_BOTTOM,
  TITLE_SLIDE_TITLE_FONT_SIZE,
  TITLE_SLIDE_SUBTITLE_FONT_SIZE,
} from "../layoutConfig.js";

export const MASTER_NAME = "Section Header";
export const MASTER_PLACEHOLDERS = {
  title: "title",
  subtitle: "subtitle",
};

export default observer(
  ({
    name = MASTER_NAME,

    backgroundColor = "#FFFFFF",
    backgroundImage,

    overlayColor = "#000000",
    overlayOpacity = 0.6,

    textColor = "#FFFFFF",
    darkColor = "#000000",
    lightColor = "#FFFFFF",

    titleOffsetLeft = OFFSET_LEFT,
    titleOffsetRight = OFFSET_RIGHT,
    titleOffsetTop = "35vh",
    titleHeight = "30vh",

    lineTitleOffsetLeft = "8vw",
    titleSubtitleOffsetTop = "0vh",

    subtitleOffsetBottom = OFFSET_BOTTOM,

    titleFontSize = TITLE_SLIDE_TITLE_FONT_SIZE,
    subtitleFontSize = TITLE_SLIDE_SUBTITLE_FONT_SIZE,
  }) => {
    const titleX = titleOffsetLeft;
    const titleW = `calc(100vw - ${titleOffsetLeft} - ${titleOffsetRight})`;
    const titleY = `calc(${titleOffsetTop})`;
    const titleH = titleHeight;

    const topLineX = `calc(${titleX} + ${lineTitleOffsetLeft})`;
    const topLineY = titleY;
    const topLineW = `calc(${titleW} - 2 * ${lineTitleOffsetLeft})`;
    const topLineH = 0; // horizontal line

    const bottomLineX = topLineX;
    const bottomLineY = `calc(${topLineY} + ${titleH})`;
    const bottomLineW = topLineW;
    const bottomLineH = topLineH;

    const subtitleX = titleX;
    const subtitleW = titleW;
    const subtitleY = `calc(${titleY} + ${titleH} + ${titleSubtitleOffsetTop})`;
    const subtitleH = `calc(100vh - ${subtitleOffsetBottom} - ${subtitleY})`;

    return (
      <Master title={name} bkgd={backgroundColor}>
        <ImageWrapper
          {...backgroundImage}
          key="background-image"
          objectFit="cover"
          objectAlign="center"
          objectVAlign="center"
          x="0"
          y="0"
          w="100vw"
          h="100vh"
          colorBlend={{ darkColor, lightColor }}
        />

        <Rect
          key="overlay"
          x="0"
          y="0"
          w="100vw"
          h="100vh"
          fill={overlayColor}
          opacity={overlayOpacity}
        />

        <Line
          key="line-top"
          x={topLineX}
          y={topLineY}
          w={topLineW}
          h={topLineH}
          color={textColor}
        />

        <Placeholder
          key="placeholder-title"
          name={MASTER_PLACEHOLDERS.title}
          type="title"
          fontSize={titleFontSize}
          color={textColor}
          align="center"
          valign="center"
          x={titleX}
          y={titleY}
          w={titleW}
          h={titleH}
        >
          Click to add section header
        </Placeholder>

        <Line
          key="line-bottom"
          x={bottomLineX}
          y={bottomLineY}
          w={bottomLineW}
          h={bottomLineH}
          color={textColor}
        />

        <Placeholder
          key="placeholder-subtitle"
          name={MASTER_PLACEHOLDERS.subtitle}
          fontSize={subtitleFontSize}
          color={textColor}
          align="center"
          valign="top"
          x={subtitleX}
          y={subtitleY}
          w={subtitleW}
          h={subtitleH}
        >
          Click to add description
        </Placeholder>
      </Master>
    );
  }
);

export const Example = observer(({ slideId, title, subtitle }) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    <Text placeholder={MASTER_PLACEHOLDERS.title}>{title}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.subtitle}>{subtitle}</Text>
  </Slide>
));
