import React, { useRef } from "react";
import { observer } from "mobx-react";
import styled from "styled-components/macro";
import debounce from "lodash/debounce";

import { FormTitle } from "./titles.jsx";

const InnerSwatch = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #cccccc;
  cursor: pointer;
  background-color: ${(props) => props.color};
`;

const InvisibibleInput = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  left: 0px;
  width: 100%;
  height: 100%;
`;

const Swatch = ({ color, onChange }) => {
  const input = useRef(null);

  return (
    <InnerSwatch color={color}>
      <InvisibibleInput
        type="color"
        list="palette"
        ref={input}
        value={color}
        onChange={(e) => onChange(e.target.value)}
      />
    </InnerSwatch>
  );
};

const ColorSchemeWrapper = styled.div`
  width: 100%;
`;

const PaletteList = observer(({ palette }) => {
  if (!palette.vibrant) return;
  return (
    <datalist id="palette">
      <option>{palette.vibrant}</option>
      <option>{palette.muted}</option>
      <option>{palette.lightVibrant}</option>
      <option>{palette.darkVibrant}</option>
      <option>{palette.lightMuted}</option>
      <option>{palette.darkMuted}</option>
    </datalist>
  );
});

const ActiveSwatch = observer(({ colorScheme, color }) => {
  return (
    <Swatch
      color={colorScheme[color]}
      onChange={debounce((newColor) => {
        colorScheme.change(color, newColor);
      }, 10)}
    />
  );
});

const SwatchGrid = styled.div`
  display: grid;

  grid-template-columns: repeat(6, 1em);
  grid-template-rows: repeat(2, 1em);

  grid-gap: 0.2em;

  & > :first-child,
  & > :nth-child(2) {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
`;

const ColorInGrid = styled.div`
  border: 1px solid #cccccc;
  background-color: ${(props) => props.color};
  width: 100%;
  height: 100%;
`;

export const PresetsGrid = ({ presets }) => {
  return (
    <SwatchGrid>
      {presets.map((p, idx) => (
        <ColorInGrid key={idx} color={p} />
      ))}
    </SwatchGrid>
  );
};

const CustomPresetsGrid = ({ customScheme }) => {
  return (
    <SwatchGrid>
      <ActiveSwatch colorScheme={customScheme} color="accent1" />
      <ActiveSwatch colorScheme={customScheme} color="accent2" />
      <ActiveSwatch colorScheme={customScheme} color="accent3" />
      <ActiveSwatch colorScheme={customScheme} color="accent4" />
      <ActiveSwatch colorScheme={customScheme} color="accent5" />
      <ActiveSwatch colorScheme={customScheme} color="accent6" />
    </SwatchGrid>
  );
};

const PresetInputWrapper = styled.div`
  font-size: ${(props) => (props.selected ? "16px" : "10px")};

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border: 1px solid #${(props) => (props.selected ? "999" : "ccc")};
  margin-bottom: 10px;
`;

const PresetLabel = styled.label`
  display: flex;
  flex-grow: 1;
  margin-left: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(props) => (props.selected ? "" : "cursor: pointer;")};

  & > :not(:first-child) {
    margin-left: 0.8em;
  }
`;

export const PresetInput = ({
  selected,
  presets,
  presetId,
  children,
  noRadio,
  onSelect,
}) => {
  return (
    <PresetInputWrapper
      selected={selected === presetId}
      onClick={() => onSelect && onSelect(presetId)}
    >
      {!noRadio && (
        <input
          name="preset"
          checked={selected === presetId}
          value={presetId}
          type="radio"
          css="margin: auto;"
          id={`preset ${presetId}`}
          onChange={(e) => {}}
        />
      )}
      <PresetLabel
        selected={selected === presetId}
        htmlFor={`preset ${presetId}`}
      >
        <span css="font-weight: 100;">{children}</span>
        <PresetsGrid presets={presets[presetId]} />
      </PresetLabel>
    </PresetInputWrapper>
  );
};

const CustomPresetInput = observer(
  ({ selected, customScheme, children, onSelect }) => {
    const isSelected = selected === "custom";
    return (
      <PresetInputWrapper
        css="flex-direction: column; align-items: flex-start;"
        selected={selected === "custom"}
        onClick={() => onSelect("custom")}
      >
        <div css="display: flex; align-items: center; width: 100%;">
          <input
            name="preset"
            checked={isSelected}
            value="custom"
            type="radio"
            css="margin: auto;"
            id={`preset custom`}
            onChange={(e) => {}}
          />
          <PresetLabel htmlFor={`preset custom`}>
            <span css="font-weight: 100;">{children}</span>
            {isSelected ? (
              <CustomPresetsGrid customScheme={customScheme} />
            ) : (
              <PresetsGrid presets={customScheme.asPalette} />
            )}
          </PresetLabel>
        </div>
      </PresetInputWrapper>
    );
  }
);

export default observer(({ colorScheme, showLinks, palette }) => {
  return (
    <ColorSchemeWrapper>
      <FormTitle>Color Scheme</FormTitle>
      <PaletteList palette={palette} />
      {["preset1", "preset2", "preset3", "preset4"].map((presetId) => (
        <PresetInput
          key={presetId}
          selected={colorScheme.preset}
          presets={colorScheme.palettePresets}
          presetId={presetId}
          onSelect={colorScheme.applyPreset}
        >
          {colorScheme.presetTitle(presetId)}
        </PresetInput>
      ))}
      <CustomPresetInput
        selected={colorScheme.preset}
        customScheme={colorScheme.customScheme}
        onSelect={colorScheme.applyPreset}
      >
        {colorScheme.presetTitle("custom")}
      </CustomPresetInput>
    </ColorSchemeWrapper>
  );
});
