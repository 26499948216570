import React from "react";

import {
  Master,
  Text,
  Slide,
  Placeholder,
} from "../../slides-engine/smartElements.jsx";

import HeaderPlaceholder, {
  TITLE_PLACEHOLDER_NAME,
} from "../components/HeaderPlaceholder";
import FooterPlaceholder from "../components/FooterPlaceholder";
import {
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  GUTTER_SIZE,
  CONTENT_SLIDE_BODY_FONT_SIZE,
  LIST_INDENT,
  BULLET_TYPE_DEFAULT,
  BULLET_CODE_DEFUALT,
} from "../layoutConfig.js";

export const MASTER_NAME = "Two Content";
export const MASTER_PLACEHOLDERS = {
  title: TITLE_PLACEHOLDER_NAME,
  contentLeft: "content-left",
  contentRight: "content-right",
};

export default ({
  name = MASTER_NAME,
  backgroundColor = "#FFFFFF",

  titleColor = "#000000",

  accent1Color = titleColor,

  contentX = INNER_X,
  contentY = INNER_Y,
  contentW = INNER_W,
  contentH = INNER_H,
  contentColumnsOffsetW = GUTTER_SIZE,

  textColor = "#000000",
  contentFontSize = CONTENT_SLIDE_BODY_FONT_SIZE,

  bulletType = BULLET_TYPE_DEFAULT,
  bulletCode = BULLET_CODE_DEFUALT,
  bulletIndent = LIST_INDENT,
  bulletColor = titleColor,
}) => {
  const contentLeftX = contentX;
  const contentLeftY = contentY;
  const contentLeftW = `calc((${contentW} / 2) - (${contentColumnsOffsetW} / 2))`;
  const contentLeftH = contentH;

  const contentRightX = `calc(${contentLeftX} + ${contentLeftW} + ${contentColumnsOffsetW})`;
  const contentRightY = contentLeftY;
  const contentRightW = contentLeftW;
  const contentRightH = contentLeftH;

  return (
    <Master title={name} bkgd={backgroundColor}>
      <HeaderPlaceholder titleColor={titleColor} />

      <Placeholder
        name={MASTER_PLACEHOLDERS.contentLeft}
        type="obj"
        align="left"
        valign="top"
        color={textColor}
        fontSize={contentFontSize}
        x={contentLeftX}
        y={contentLeftY}
        w={contentLeftW}
        h={contentLeftH}
        bullet={{
          type: bulletType,
          code: bulletCode,
          indent: bulletIndent,
          color: bulletColor,
        }}
      >
        Click to add text
      </Placeholder>

      <Placeholder
        name={MASTER_PLACEHOLDERS.contentRight}
        type="obj"
        align="left"
        valign="top"
        color={textColor}
        fontSize={contentFontSize}
        x={contentRightX}
        y={contentRightY}
        w={contentRightW}
        h={contentRightH}
        bullet={{
          type: bulletType,
          code: bulletCode,
          indent: bulletIndent,
          color: bulletColor,
        }}
      >
        Click to add text
      </Placeholder>

      <FooterPlaceholder accent1Color={accent1Color} />
    </Master>
  );
};

export const Example = ({ title, contentLeft, contentRight, slideId }) => (
  <Slide slideId={slideId} master={MASTER_NAME}>
    <Text placeholder={MASTER_PLACEHOLDERS.title}>{title}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.contentLeft}>{contentLeft}</Text>
    <Text placeholder={MASTER_PLACEHOLDERS.contentRight}>{contentRight}</Text>
  </Slide>
);
