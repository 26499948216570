import React from "react";

import { Text, Slide } from "../../slides-engine/smartElements.jsx";

import {
  CONTENT_LEVEL0_FONT_SIZE,
  CONTENT_LEVEL4_FONT_SIZE,
  INNER_X,
  INNER_W,
  INNER_CY,
} from "../layoutConfig.js";
import Point, {
  DEFAULT_H as POINT_DEFAULT_H,
} from "../components/PointInfo.jsx";

export default ({
  masterName,
  slideId,

  backgroundColor = "#FFFFFF",

  pointsContainerW = INNER_W,

  pointH = POINT_DEFAULT_H,
  pointX1 = INNER_X,
  pointY = `calc(${INNER_CY} - (${pointH} / 2))`,

  dividerColor = "#000000",

  titleColor = "#000000",
  titleSize = CONTENT_LEVEL0_FONT_SIZE,

  descriptionColor = "#000000",
  descriptionSize = CONTENT_LEVEL4_FONT_SIZE,

  title = "",
  points = [],
}) => {
  const pointW = `calc(${pointsContainerW} / ${points.length})`;

  const pointComponents = points.map((point, idx) => {
    const { title, description } = point;
    const pointX = `calc(${pointX1} + (${idx} * ${pointW}))`;
    return (
      <Point
        key={idx.toString()}
        x={pointX}
        y={pointY}
        w={pointW}
        h={pointH}
        lineColor={dividerColor}
        titleColor={titleColor}
        titleFontSize={titleSize}
        descriptionColor={descriptionColor}
        descriptionFontSize={descriptionSize}
        title={title}
        description={description}
        lineHidden={idx === 0}
      />
    );
  });

  return (
    <Slide slideId={slideId} master={masterName} bkgd={backgroundColor}>
      <Text placeholder="title">{title}</Text>
      {pointComponents}
    </Slide>
  );
};
