import React from "react";
import { observer } from "mobx-react";

import { useApp } from "../state/context";
import {
  DialogTitle,
  DialogSection,
  DialogContent,
  OrDivider,
} from "./DialogSections.jsx";

import { Button } from "./Button.jsx";

export default observer(() => {
  const appState = useApp();

  return (
    <>
      <DialogTitle>Interested in this custom template?</DialogTitle>

      <DialogContent>
        <DialogSection>
          <Button color="primary" onClick={appState.downloadSample}>
            DOWNLOAD A FREE SAMPLE
          </Button>
        </DialogSection>
        <OrDivider />
        <DialogSection>
          <Button solid color="primary" onClick={appState.buy}>
            BUY THE TEMPLATE
          </Button>
        </DialogSection>
      </DialogContent>
    </>
  );
});
