import React from "react";
import { darken, lighten } from "polished";
import { observer } from "mobx-react";

import { Text, Slide } from "../../slides-engine/smartElements.jsx";

import ImageWrapper from "../components/ImageWrapper.jsx";
import Bulletrect from "../components/Bulletrect.jsx";
import TextItemsAroundCircle from "../components/TextItemsAroundCircle.jsx";

import {
  cmToVW,
  cmToVH,
  INNER_X,
  INNER_Y,
  INNER_W,
  INNER_H,
  CONTENT_LEVEL3_FONT_SIZE,
  CONTENT_LEVEL4_FONT_SIZE,
  CONTENT_LEVEL8_FONT_SIZE,
  CONTENT_LEVEL9_FONT_SIZE,
  BULLET_TYPE_DEFAULT,
  BULLET_CODE_DEFUALT,
} from "../layoutConfig.js";
import {
  COLOR_DEFAULT_DARK1,
  COLOR_DEFAULT_LIGHT1,
  COLOR_DEFAULT_LIGHT2,
  COLOR_DEFAULT_ACCENT1,
} from "../models/colorScheme";

export default observer(
  ({
    masterName,
    slideId,
    title,

    containerX = INNER_X,
    containerY = INNER_Y,
    containerW = INNER_W,
    containerH = INNER_H,

    backgroundColor = COLOR_DEFAULT_LIGHT1,
    accentColor = COLOR_DEFAULT_ACCENT1,
    lightColor = COLOR_DEFAULT_LIGHT2,
    textColor = COLOR_DEFAULT_DARK1,

    bulletType = BULLET_TYPE_DEFAULT,
    bulletCode = BULLET_CODE_DEFUALT,
    bulletIndent = `${cmToVW(0.3)}vw`,
    bulletColor = accentColor,

    headerColor = darken(0.5, lightColor),
    headerFontSize = CONTENT_LEVEL3_FONT_SIZE,
    headerFontBold = false,
    headerAlign = "center",
    headerValign = "center",
    headerX = containerX,
    headerY = containerY,
    headerW = containerW,
    headerH = `${cmToVH(1)}vh`,

    dividerPosition = 0.5, // 50%

    iconColor = headerColor,

    titleColor = accentColor,
    titleFontSize = CONTENT_LEVEL4_FONT_SIZE,
    titleFontBold = true,

    subtitleColor = textColor,
    subtitleFontSize = CONTENT_LEVEL9_FONT_SIZE,
    subtitleFontBold = false,

    footerColor = subtitleColor,
    footerFontSize = subtitleFontSize,
    footerFontBold = subtitleFontBold,
    footerAlign = "left",
    footerValign = "top",
    footerW = `calc((1 - 0.25) * ${containerW})`, // 20% container offset X
    footerX = `calc(${containerX} + 0.5 * (${containerW} - ${footerW}))`,
    footerH = headerH, //cmToVH(1),
    footerY = `calc(${containerY} + ${containerH} - ${footerH})`,

    circleOffsetTop = `calc(1.25 * ${headerH})`,
    circleOffsetBottom = circleOffsetTop,
    circleH = `calc(${containerH} - ${headerH} - ${footerH} - ${circleOffsetTop} - ${circleOffsetBottom})`,
    circleW = circleH, // square circle
    circleX = `calc(${containerX} + 0.5 * (${dividerPosition} * ${containerW} - ${circleW}))`,
    circleY = `calc(${containerY} + 0.5 * (${containerH} - ${circleH}))`,
    circleCX = `calc(${circleX} + 0.5 * ${circleW})`,
    circleCY = `calc(${circleY} + 0.5 * ${circleH})`,

    iconH = `calc(0.5 * ${circleH})`,
    iconW = iconH, // square circle
    iconX = `calc(${circleCX} - 0.5 * ${iconW})`,
    iconY = `calc(${circleCY} - 0.5 * ${iconH})`,

    bulletrectOffsetLeft = `${cmToVW(2.6)}vw`,
    bulletrectOffsetRight = 0,
    bulletrectOffsetTop = `calc(1.25 * ${headerH})`,
    bulletrectOffsetBottom = bulletrectOffsetTop,
    bulletrectBulletTextFontSize = CONTENT_LEVEL8_FONT_SIZE,

    content = {},
  }) => {
    const bulletConf = {
      type: bulletType,
      code: bulletCode,
      indent: bulletIndent,
      color: bulletColor,
    };

    // HEADER
    const { header } = content;
    const headerEl = (
      <Text
        key="header"
        x={headerX}
        y={headerY}
        w={headerW}
        h={headerH}
        color={headerColor}
        fontSize={headerFontSize}
        bold={headerFontBold}
        align={headerAlign}
        valign={headerValign}
      >
        {header}
      </Text>
    );

    // FOOTER
    const { footer } = content;
    const footerEl = footer ? (
      <Text
        key="footer"
        x={footerX}
        y={footerY}
        w={footerW}
        h={footerH}
        color={footerColor}
        fontSize={footerFontSize}
        bold={footerFontBold}
        align={footerAlign}
        valign={footerValign}
        bullet={Array.isArray(footer) ? bulletConf : undefined}
      >
        {Array.isArray(footer) ? footer.join("\n") : footer}
      </Text>
    ) : null;

    // RIGHT: ITEMS
    const { items } = content;
    const bulletrectX = `calc(${containerX} + (${dividerPosition} * ${containerW}) + ${bulletrectOffsetLeft})`;
    const bulletrectW = `calc(${containerX} + ${containerW} - ${bulletrectOffsetRight} - ${bulletrectX})`;
    const bulletrectY = `calc(${headerY} + ${headerH} + ${bulletrectOffsetTop})`;
    const bulletrectH = `calc(${containerH} - ${headerH} - ${footerH} - ${bulletrectOffsetTop} - ${bulletrectOffsetBottom})`;
    const bulletrectEl = (
      <Bulletrect
        x={bulletrectX}
        y={bulletrectY}
        w={bulletrectW}
        h={bulletrectH}
        bulletFill={lighten(0.25, accentColor)}
        bulletColor={bulletColor}
        bulletTextColor={backgroundColor}
        bulletTextFontSize={bulletrectBulletTextFontSize}
        bulletTextFontBold={subtitleFontBold}
        textColor={subtitleColor}
        textFontSize={subtitleFontSize}
        textFontBold={subtitleFontBold}
        items={items}
      />
    );

    // LEFT: ICON
    const { icon } = content;
    const iconEl = icon ? (
      <ImageWrapper
        {...(icon || {})}
        objectFit="contain"
        x={iconX}
        y={iconY}
        w={iconW}
        h={iconW}
        colorBlend={{ darkColor: iconColor, lightColor: iconColor }}
      />
    ) : null;

    // LEFT: CIRCLE ITEMS
    // https://stackoverflow.com/a/24274611
    const circleEls = (
      <TextItemsAroundCircle
        x={circleX}
        y={circleY}
        w={circleW}
        h={circleH}
        titleColor={titleColor}
        titleFontSize={titleFontSize}
        titleFontBold={titleFontBold}
        subtitleColor={subtitleColor}
        subtitleFontSize={subtitleFontSize}
        subtitleFontBold={subtitleFontBold}
        items={items}
      />
    );

    return (
      <Slide slideId={slideId} master={masterName}>
        <Text placeholder="title">{title}</Text>

        {headerEl}

        {iconEl}

        {circleEls}

        {bulletrectEl}

        {footerEl}
      </Slide>
    );
  }
);
